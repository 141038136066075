import { Page, Text, View } from '@react-pdf/renderer'
import Footer from '../../Parts/Footer'
import TopMark from '../../Parts/TopMark'
import { isToShowProjectField, formateEuroString } from '../../../../helpers/functions'
import parsePrice from 'parse-price'

export default function ProjectPage({ projectID, formData, getProjectsListPagesItem, number, max_number }) {


    const getProjectTypes = projectID => {
        return formData?.filter(field => {
            return (
                field.field_key?.includes('3-1-projecten') &&
                field.field_key?.includes('projecttype') &&
                field.field_key?.includes(projectID)
            )
        })?.[0]?.field_value
    }

    const getProjectThemes = projectID => {
        return formData?.filter(field => {
            return (
                field.field_key?.includes('3-1-projecten') &&
                field.field_key?.includes('projectthemas') &&
                field.field_key?.includes(projectID)
            )
        })?.[0]?.field_value
    }


    const getSingleProjectFieldValue = (projectID, fieldKey) => {
        return formData?.filter(field => {
            return (
                field.field_key?.includes('3-1-projecten') &&
                field.field_key?.includes(fieldKey) &&
                field.field_key?.includes(projectID)
            )
        })?.[0]?.field_value
    }


    const getProjectCosts = projectID => {
        const data = formData?.filter(field => {
            return (
                field.field_key?.includes('3-1-projecten') &&
                field.field_key?.includes('projectkosten') &&
                field.field_key?.includes(projectID)
            )
        })?.[0]?.field_value

        if (data) {
            return data.sort((a, b) => parsePrice(b[1]) - parsePrice(a[1]))
        }

        return false
    }

    const getTotalProjectCosts = projectID => {
        let total = 0

        const data = formData?.filter(field => {
            return (
                field.field_key?.includes('3-1-projecten') &&
                field.field_key?.includes('projectkosten') &&
                field.field_key?.includes(projectID)
            )
        })?.[0]?.field_value

        if (data) {
            data.forEach(item => total += parsePrice(item[1]))
        }

        return total
    }


    const getProjectHours = projectID => {
        return formData?.filter(field => {
            return (
                field.field_key?.includes('3-1-projecten') &&
                field.field_key?.includes('inzet-uren') &&
                field.field_key?.includes(projectID)
            )
        })?.[0]?.field_value
    }

    const getTotalProjectHours = projectID => {
        let total = 0

        const data = formData?.filter(field => {
            return (
                field.field_key?.includes('3-1-projecten') &&
                field.field_key?.includes('inzet-uren') &&
                field.field_key?.includes(projectID)
            )
        })?.[0]?.field_value

        if (data) {
            data.forEach(item => total += parsePrice(item[1]))
        }

        return total
    }








    return (
        <Page
            style={{ padding: '70px 70px 70px 70px', position: 'relative' }}
            size="A4"
        >
            <TopMark />
            <Text render={({ pageNumber }) => getProjectsListPagesItem({ "pageNumber": pageNumber, "pageName": getSingleProjectFieldValue(projectID, 'projectnaam') })} />

            <Text
                style={{
                    fontFamily: 'Roboto',
                    fontSize: '24px',
                    textDecoration: 'underline',
                    color: '#000'
                }}
                hyphenationCallback={e => [e]}
            >
                {`Project: ${getSingleProjectFieldValue(projectID, 'projectnaam')} (${number}/${max_number})`}
            </Text>


            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '25px'
                }}
            >
                <Text
                    style={{
                        fontSize: '13px',
                        lineHeight: '1.4',
                        color: '#0b8bcc',
                        fontFamily: 'ArchivoBold',
                        marginBottom: '5px'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    Projecttype
                </Text>

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >

                    <Text
                        style={{
                            fontSize: '11px',
                            lineHeight: '1.6',
                            color: '#000',
                            fontFamily: 'Archivo',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {getProjectTypes(projectID)}
                    </Text>

                </View>

            </View>

            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '25px'
                }}
            >
                <Text
                    style={{
                        fontSize: '13px',
                        lineHeight: '1.4',
                        color: '#0b8bcc',
                        fontFamily: 'ArchivoBold',
                        marginBottom: '5px'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    Thema's
                </Text>

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >

                    <Text
                        style={{
                            fontSize: '11px',
                            lineHeight: '1.6',
                            color: '#000',
                            fontFamily: 'Archivo',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {getProjectThemes(projectID)}
                    </Text>


                </View>

            </View>



            <ProjectTextareaField
                title={'Projectbeschrijving'}
                projectID={projectID}
                fieldValue={'projectbeschrijving'}
                getSingleProjectFieldValue={getSingleProjectFieldValue}
            />

            <ProjectTextareaField
                title={'Doelstelling'}
                projectID={projectID}
                fieldValue={'doelstelling'}
                getSingleProjectFieldValue={getSingleProjectFieldValue}
            />

            <ProjectTextareaField
                title={'Rol PVO'}
                projectID={projectID}
                fieldValue={'rol-pvo'}
                getSingleProjectFieldValue={getSingleProjectFieldValue}
            />

            <ProjectTextareaField
                title={'Samenwerking'}
                projectID={projectID}
                fieldValue={'samenwerking'}
                getSingleProjectFieldValue={getSingleProjectFieldValue}
            />

            <ProjectTextareaField
                title={'Communicatie'}
                projectID={projectID}
                fieldValue={'communicatie'}
                getSingleProjectFieldValue={getSingleProjectFieldValue}
            />

            {isToShowProjectField({ field_key: "3-1-projecten-9-1-welk-product-levert-het-pvo-op", conditional: true }, formData, projectID) ?
                <ProjectTextareaField
                    title={'Welk product levert het PVO op?'}
                    projectID={projectID}
                    fieldValue={'welk-product-levert-het-pvo-op'}
                    getSingleProjectFieldValue={getSingleProjectFieldValue}
                />
                : null}


            {isToShowProjectField({ field_key: "3-1-projecten-9-3-hoe-draagt-het-bij-aan-de-doelstelling-van-het-lokale-pvo", conditional: true }, formData, projectID) ?
                <ProjectTextareaField
                    title={'Hoe draagt het bij aan de doelstelling van het lokale PVO?'}
                    projectID={projectID}
                    fieldValue={'hoe-draagt-het-bij-aan-de-doelstelling-van-het-lokale-pvo'}
                    getSingleProjectFieldValue={getSingleProjectFieldValue}
                />
                : null}

            {isToShowProjectField({ field_key: "3-1-projecten-9-4-hoeveel-ondernemers-bereikt-het-pvo-met-dit-project", conditional: true }, formData, projectID) ?
                <ProjectTextareaField
                    title={'Hoeveel ondernemers bereikt het PVO met dit project?'}
                    projectID={projectID}
                    fieldValue={'hoeveel-ondernemers-bereikt-het-pvo-met-dit-project'}
                    getSingleProjectFieldValue={getSingleProjectFieldValue}
                />
                : null}

            <ProjectTextareaField
                title={'Wat is het resultaat van dit project?'}
                projectID={projectID}
                fieldValue={'wat-is-het-resultaat-van-dit-project'}
                getSingleProjectFieldValue={getSingleProjectFieldValue}
            />

            <ProjectTextareaField
                title={'Op welke wijze wordt effectmeting toegepast?'}
                projectID={projectID}
                fieldValue={'op-welke-wijze-wordt-effectmeting-toegepast'}
                getSingleProjectFieldValue={getSingleProjectFieldValue}
            />

            {getSingleProjectFieldValue(projectID, 'toelichting') ?
                <ProjectTextareaField
                    title={'Toelichting'}
                    projectID={projectID}
                    fieldValue={'toelichting'}
                    getSingleProjectFieldValue={getSingleProjectFieldValue}
                />
                : null}






            {/* project costs */}
            <View
                wrap={false}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '20px'
                }}
            >

                <Text
                    style={{
                        fontSize: '13px',
                        lineHeight: '1.4',
                        color: '#0b8bcc',
                        fontFamily: 'ArchivoBold',
                        marginBottom: '5px'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    Projectkosten
                </Text>


                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px'
                    }}>

                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Soort post
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Kosten
                    </Text>

                </View>

                {getProjectCosts(projectID).map((row, rowIndex) => {
                    return (
                        <View
                            key={rowIndex}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '5px',
                                borderBottom: rowIndex !== getProjectCosts(projectID).length - 1 ? '1px solid #e6e7e8' : ''
                            }}>

                            <Text
                                style={{
                                    width: '83%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {row[0]}
                            </Text>

                            <Text
                                style={{
                                    width: '17%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {formateEuroString(parsePrice(row[1]))}
                            </Text>

                        </View>
                    )
                })}

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px',
                    }}
                >
                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                            backgroundColor: '#e4f5fd',
                            borderRadius: '3px'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Totaal kosten
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                            marginLeft: 'auto'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {formateEuroString(parsePrice(getTotalProjectCosts(projectID)))}
                    </Text>
                </View>
            </View>




            {/* project hours */}
            <View
                wrap={false}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '20px'
                }}
            >

                <Text
                    style={{
                        fontSize: '13px',
                        lineHeight: '1.4',
                        color: '#0b8bcc',
                        fontFamily: 'ArchivoBold',
                        marginBottom: '5px'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    Inzet uren
                </Text>


                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px'
                    }}>

                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Functie
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Begrote uren
                    </Text>

                </View>

                {getProjectHours(projectID).map((row, rowIndex) => {
                    return (
                        <View
                            key={rowIndex}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '5px',
                                borderBottom: rowIndex !== getProjectHours(projectID).length - 1 ? '1px solid #e6e7e8' : ''
                            }}>

                            <Text
                                style={{
                                    width: '83%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {row[0]}
                            </Text>

                            <Text
                                style={{
                                    width: '17%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {row[1]}
                            </Text>

                        </View>
                    )
                })}

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px',
                    }}
                >
                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                            backgroundColor: '#e4f5fd',
                            borderRadius: '3px'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Totaal begrote projecturen
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                            marginLeft: 'auto'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {getTotalProjectHours(projectID)}
                    </Text>
                </View>
            </View>


            <Footer />
        </Page>
    )
}



function ProjectTextareaField({ title, fieldValue, getSingleProjectFieldValue, projectID }) {
    return (
        <View
            wrap={false}
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '25px'
            }}
        >
            <Text
                style={{
                    fontSize: '13px',
                    lineHeight: '1.4',
                    color: '#0b8bcc',
                    fontFamily: 'ArchivoBold',
                    marginBottom: '5px'
                }}
                hyphenationCallback={e => [e]}
            >
                {title}
            </Text>

            <Text
                style={{
                    fontSize: '11px',
                    lineHeight: '1.6',
                    color: '#000',
                    fontFamily: 'Archivo'
                }}
                hyphenationCallback={e => [e]}
            >
                {getSingleProjectFieldValue(projectID, fieldValue)}
            </Text>

        </View>
    )
}