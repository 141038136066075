import { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import useUserRole from './useUserRole';

const useSaveData = () => {
    const { setDataSaving, setSavingStatus, globalState, setLastUpdated, setLastUpdatedTime } = useContext(GlobalContext)

    const { formData, visitedSections, inputWarnings } = globalState

    const { userCanEdit } = useUserRole()

    const saveData = async () => {

        if (globalState.isDataSaving) {
            return null
        }

        const apiEndpoint = document.querySelector('#apiEndpoint')?.getAttribute('data-url');

        if (!apiEndpoint) {
            return null
        }

        if (!userCanEdit()) {
            return null
        }

        if (!Array.isArray(formData)) {
            return null
        }

        const today = new Date().toLocaleDateString().replaceAll('/', '-');
        const time = new Date();

        var seconds = time.getSeconds()
        var minutes = time.getMinutes()
        if(seconds < 10) {
            seconds = '0' + seconds
        }
        if(minutes < 10) {
            minutes = '0' + minutes
        }

        setDataSaving(true)

        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'X-WP-Nonce': document.querySelector('#toolNonce')?.getAttribute('data-key')
                },
                body: JSON.stringify({
                    pageId: document.querySelector('#pageId')?.getAttribute('data-id'),
                    formData: formData,
                    visitedSections: visitedSections,
                    inputWarnings: inputWarnings,
                    nonce: document.querySelector('#toolNonce')?.getAttribute('data-key'),
                })
            });

            setDataSaving(false)

            if (response.status !== 200) {
                setSavingStatus('error')
            } else {
                setSavingStatus('successful')
                setLastUpdated(today)
                setLastUpdatedTime(time.getHours() + ":" + minutes + ":" + seconds)
            }

            setTimeout(() => {
                setSavingStatus('')
            }, 2147483647);

        } catch (error) {
            setDataSaving(false)
            
            setSavingStatus('error')
            console.log(error)

            setTimeout(() => {
                setSavingStatus('')
            }, 2147483647);
        }

    };

    return { saveData };
};

export default useSaveData;