import formConfig from '../helpers/form-config.json'
import parsePrice from 'parse-price'



export function slugify(string) {
    return string
        .toString()
        .normalize('NFD') // split an accented letter in the base letter and the acent
        .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
        .replace(/\s+/g, '-');
};


export function storeVisitedSections(visitedSections, setVisitedSections, sectionIndex, stepIndex) {

    if (!visitedSections || !Array.isArray(visitedSections)) {
        return null;
    }

    if (!setVisitedSections || !(setVisitedSections instanceof Function)) {
        return null;
    }

    if (sectionIndex === undefined) {
        return null;
    }

    let newVisitedSections = [];

    formConfig.sections.forEach((section, i) => {
        if (i < sectionIndex) {
            section.steps.forEach((_, j) => {
                const sectionKey = `${i}-${j}`

                if (!visitedSections.includes(sectionKey)) {
                    newVisitedSections.push(sectionKey)
                }
            })
        }

        if (i === sectionIndex) {
            section.steps.forEach((_, j) => {
                const sectionKey = `${i}-${j}`

                if (j <= stepIndex) {
                    if (!visitedSections.includes(sectionKey)) {
                        newVisitedSections.push(sectionKey)
                    }
                }

            })
        }
    })




    setVisitedSections([...visitedSections, ...newVisitedSections])
}


export function constructInputWarning(sectionIndex, stepIndex, fieldKey, repeaterIndexes = 'none', extra = '') {
    return `sectionIndex${sectionIndex}:stepIndex${stepIndex}:${fieldKey}:${repeaterIndexes}${extra ? ':' + extra : ''}`
}


export function hasFieldWarning(inputWarnings, inputKey, currentProjectID = false) {

    if (currentProjectID) {
        return inputWarnings?.length > 0 && inputWarnings.filter(i => i.includes(`${inputKey}`) && i.includes(`${currentProjectID}`)).length > 0
    }

    return inputWarnings?.length > 0 && inputWarnings.filter(i => i.includes(inputKey)).length > 0

}

export function getRandomId(length = 6) {
    return Math.random().toString(36).substring(2, length + 2);
};


export function formateEuroString(value) {
    if (isNaN(value)) {
        return 'invalid value';
    }

    return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(value)
}

export function getTotalCostProjects(formData) {

    let totalCosts = 0

    const allProjectsCosts = formData.filter(field => field.field_key.includes('3-1-projecten') && field.field_key.includes('projectkosten'))

    allProjectsCosts.forEach(projectCost => {
        if (Array.isArray(projectCost.field_value)) {
            projectCost.field_value.forEach(costRow => {
                const costValue = costRow[1]

                if (costValue) {
                    totalCosts += parsePrice(costValue)
                }
            })
        }
    })


    return formateEuroString(totalCosts)

}



export function getTotalCostCommunication(formData) {

    let totalCosts = 0

    const allCommunicationCosts = formData.filter(field => field.field_key.includes('projectkosten-communicatie'))

    allCommunicationCosts.forEach(CommunicationCost => {
        if (Array.isArray(CommunicationCost.field_value)) {
            CommunicationCost.field_value.forEach(costRow => {
                const costValue = costRow[1]
                if (costValue) {
                    totalCosts += parsePrice(costValue)
                }
            })
        }
    })


    return formateEuroString(totalCosts)

}


export function getTotalCostSafety(formData) {

    let totalCosts = 0

    const allSafetyCosts = formData.filter(field => field.field_key.includes('projectkosten-safety'))

    allSafetyCosts.forEach(safetyCost => {
        if (Array.isArray(safetyCost.field_value)) {
            safetyCost.field_value.forEach(costRow => {
                const costValue = costRow[1]
                if (costValue) {
                    totalCosts += parsePrice(costValue)
                }
            })
        }
    })


    return formateEuroString(totalCosts)

}


export function getTotalCostPersonnel(formData) {

    let totalCosts = 0

    const dataRowFields = formData.filter(field => field.field_key.includes('4-2-personeelskosten'))


    dataRowFields.forEach(fieldDataRows => {
        if (fieldDataRows?.['field_value'] && fieldDataRows?.['field_value']?.length > 0) {

            fieldDataRows['field_value'].forEach(row => {
                let priceValue = row[1]
                let hoursValue = row[2]
                const employmentTypeValue = row[4]

                priceValue = parsePrice(priceValue)

                if (employmentTypeValue === 'ZZP') {
                    hoursValue = parsePrice(hoursValue)
                    totalCosts += hoursValue * priceValue
                } else {
                    totalCosts += priceValue
                }
            })
        }
    })

    return formateEuroString(totalCosts)

}

export function getTotalCostOther(formData) {

    let totalCosts = 0

    const allSafetyCosts = formData.filter(field => field.field_key.includes('4-3-overige-kostenposten'))

    allSafetyCosts.forEach(safetyCost => {
        if (Array.isArray(safetyCost.field_value)) {
            safetyCost.field_value.forEach(costRow => {
                const costValue = costRow[1]
                if (costValue) {
                    totalCosts += parsePrice(costValue)
                }
            })
        }
    })


    return formateEuroString(totalCosts)

}


export function getTotalIncome(formData) {
    let totalIncome = 0

    const allSafetyCosts = formData.filter(field => field.field_key.includes('4-1-inkomsten'))

    allSafetyCosts.forEach(safetyCost => {
        if (Array.isArray(safetyCost.field_value)) {
            safetyCost.field_value.forEach(costRow => {
                const costValue = costRow[1]
                if (costValue) {
                    totalIncome += parsePrice(costValue)
                }
            })
        }
    })


    return formateEuroString(totalIncome)
}



export function getTotalCosts(formData) {
    let totalCosts = 0
    const projectCosts = parsePrice(getTotalCostProjects(formData))
    const communicationCosts = parsePrice(getTotalCostCommunication(formData))
    const safetyCosts = parsePrice(getTotalCostSafety(formData))
    const personnelCosts = parsePrice(getTotalCostPersonnel(formData))
    const otherCosts = parsePrice(getTotalCostOther(formData))

    totalCosts += projectCosts
    totalCosts += communicationCosts
    totalCosts += safetyCosts
    totalCosts += personnelCosts
    totalCosts += otherCosts

    return formateEuroString(totalCosts)

}


export function getTotalFunctionHoursAvailable(formData) {

    let functionHours = []
    let totalHours = 0
    const dataRowFields = formData.filter(field => field.field_key.includes('4-2-personeelskosten'))


    dataRowFields.forEach(fieldDataRows => {
        if (fieldDataRows?.['field_value'] && fieldDataRows?.['field_value']?.length > 0) {

            fieldDataRows['field_value'].forEach(row => {
                let functionValue = row[0]
                let hoursValue = row[2]

                if (functionValue !== '') {
                    const assigned = functionHours.filter(groupedFunction => groupedFunction.name === functionValue).length > 0

                    if (!assigned) {
                        functionHours.push({
                            name: functionValue,
                            hours: parsePrice(hoursValue)
                        })
                    } else {
                        functionHours.forEach(groupedFunction => {
                            if (groupedFunction.name === functionValue) {
                                groupedFunction.hours += parsePrice(hoursValue)
                            }
                        })
                    }

                }

                if (hoursValue !== '') {
                    totalHours += parsePrice(hoursValue)
                }


            })
        }
    })

    functionHours.sort((a, b) => a.name.localeCompare(b.name))

    return {
        list: functionHours,
        total: totalHours
    }
}



export function getTotalFunctionHoursUsed(formData) {

    let functionHours = []
    let totalHours = 0

    const dataRowFields = formData.filter(field => {
        return (field.field_key.includes('3-1-projecten') && field.field_key.includes('inzet-uren')) ||
            (field.field_key.includes('3-6') && field.field_key.includes('inzet-uren')) ||
            (field.field_key.includes('3-4') && field.field_key.includes('inzet-uren'))

    })
    dataRowFields.forEach(fieldDataRows => {
        if (fieldDataRows?.['field_value'] && fieldDataRows?.['field_value']?.length > 0) {

            fieldDataRows['field_value'].forEach(row => {
                let functionValue = row[0]
                let hoursValue = row[1]

                if (functionValue !== '') {
                    const assigned = functionHours.filter(groupedFunction => groupedFunction.name === functionValue).length > 0

                    if (!assigned) {
                        functionHours.push({
                            name: functionValue,
                            hours: parsePrice(hoursValue)
                        })
                    } else {
                        functionHours.forEach(groupedFunction => {
                            if (groupedFunction.name === functionValue) {
                                groupedFunction.hours += parsePrice(hoursValue)
                            }
                        })
                    }
                }

                if (hoursValue !== '') {
                    totalHours += parsePrice(hoursValue)
                }


            })
        }
    })

    functionHours.sort((a, b) => a.name.localeCompare(b.name))

    return {
        list: functionHours,
        total: totalHours
    }
}


export function isToShowProjectField(projectConfigField, formData, currentProjectID) {
    if (
        projectConfigField?.field_key?.includes('3-1-projecten') &&
        currentProjectID &&
        projectConfigField?.conditional
    ) {

        const projectTypes = formData.filter(field => field.field_key.includes('3-1-projecten') && field.field_key.includes('projecttype') && field.field_key.includes(currentProjectID))[0].field_value

        if (projectConfigField.field_key.includes('welk-product-levert-het-pvo-op')) {
            return projectTypes.includes('Productontwikkeling')
        }

        if (projectConfigField.field_key.includes('hoe-draagt-het-bij-aan-de-doelstelling-van-het-lokale-pvo')) {
            return projectTypes.includes('Onderzoek')
        }

        if (projectConfigField.field_key.includes('hoeveel-ondernemers-bereikt-het-pvo-met-dit-project')) {
            return projectTypes.includes('Campagne') || projectTypes.includes('Training/voorlichting') || projectTypes.includes('Congres')
        }

    }

    return true
}


export function getRemoteFormData() {
    const data = document.querySelector('#formData')

    if (data) {
        const data_json = data.getAttribute('data-json')


        if (data_json === '') {
            return 'empty data'
        }

        if (isJson(data_json)) {
            return JSON.parse(data_json);
        } else {
            return 'error parse'
        }
    }
}

export function getRemoteVisitedSections() {
    const data = document.querySelector('#visitedSections')

    if (data) {
        try {
            return JSON.parse(data.getAttribute('data-json'))
        } catch (er) {
            return null
        }
    }
}

export function getRemoteInputWarnings() {
    const data = document.querySelector('#inputWarnings')

    if (data) {
        try {
            return JSON.parse(data.getAttribute('data-json'))
        } catch (er) {
            return null
        }
    }
}

export function getRemoteLastDownload() {
    const data = document.querySelector('#lastDownloaded')

    if (data) {
        try {
            return JSON.parse(data.getAttribute('data-json'))
        } catch (er) {
            return null
        }
    }
}

export function getRemoteHandleSetInputInstructions() {
    const data = document.querySelector('#inputInstructions')

    if (data) {
        try {
            return JSON.parse(data.getAttribute('data-json'))
        } catch (er) {
            return null
        }
    }
}


export function getRemoteHandleSetUserRole() {
    const data = document.querySelector('#userRole')

    if (data) {
        try {
            return JSON.parse(data.getAttribute('data-json'))
        } catch (er) {
            return null
        }
    }
}


export function escapeStr(str) {

    if (typeof str !== 'string') {
        return
    }

    str = str.replaceAll('"', "'")


    return str
}



export function isJson(str) {

    if (!str) {
        return false;
    }

    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;

}