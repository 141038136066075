import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalState"
import { storeVisitedSections } from "../../helpers/functions"
import { checkInputsValidation } from "../../helpers/form-validator"
import formConfig from '../../helpers/form-config.json'
import useSaveData from "../../helpers/hooks/useSaveData"


export default function SidebarItem({ data, index }) {

    const {
        globalState,
        setCurrentSection,
        setCurrentStep,
        setVisitedSections,
        setInputWarnings,
        setCurrentProjectID,
        setCurrentProjectStep,
        setMenuOpen
    } = useContext(GlobalContext)

    const { currentSection, currentStep, visitedSections, inputWarnings } = globalState
    const { section_title, show_step_titles_sidebar, steps } = data

    const { saveData } = useSaveData()

    // State to track the timer ID
    const [saveDataTimer, setSaveDataTimer] = useState(null);

    // Function to save data and restart the timer
    const saveDataAndSetTimer = () => {
        saveData();
        if (saveDataTimer) {
            clearInterval(saveDataTimer);
        }
        const newTimer = setInterval(() => {
            saveData();
        }, 60000); // 60000 milliseconds = 1 minute
        setSaveDataTimer(newTimer);
    };

    // Use useEffect to start the timer when the component mounts
    useEffect(() => {
        saveDataAndSetTimer();

        // Clean up the timer when the compon ent unmounts
        return () => {
            if (saveDataTimer) {
                clearInterval(saveDataTimer);
            }
        };
    }, []);

    const hasSectionWarning = (index) => {
        return inputWarnings?.length > 0 && inputWarnings.filter(i => i.includes(`sectionIndex${index}`)).length > 0
    }

    const hasStepWarning = (sectionIndex, stepIndex) => {
        return inputWarnings?.length > 0 && inputWarnings.filter(i => i.includes(`sectionIndex${sectionIndex}:stepIndex${stepIndex}`)).length > 0
    }

    const hasAlreadyVisited = (sectionIndex, stepIndex) => {
        return visitedSections.filter(section => section === `${sectionIndex}-${stepIndex}`).length > 0
    }


    const getPrevIndexes = (sectionIndex, stepIndex) => {

        if (stepIndex <= 0 && sectionIndex <= 0) {
            return false
        }

        if (stepIndex - 1 >= 0) {
            return [sectionIndex, stepIndex - 1]
        }

        if (sectionIndex - 1 >= 0) {
            const maxSteps = formConfig.sections[sectionIndex - 1]?.steps.length
            return [sectionIndex - 1, maxSteps - 1]
        }
    }


    const handleSectionClick = (sectionIndex, stepIndex) => {

        if (sectionIndex < currentSection || (sectionIndex === currentSection && stepIndex <= currentStep)) {
            checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings, false, true)
        } else if (sectionIndex !== 0) {
            checkInputsValidation(getPrevIndexes(sectionIndex, stepIndex)?.[0], getPrevIndexes(sectionIndex, stepIndex)?.[1], globalState, setInputWarnings, false, true)
        }

        if (sectionIndex < currentSection || (sectionIndex === currentSection && stepIndex <= currentStep)) {
            storeVisitedSections(visitedSections, setVisitedSections, currentSection, currentStep)
        } else {
            storeVisitedSections(visitedSections, setVisitedSections, getPrevIndexes(sectionIndex, stepIndex)?.[0], getPrevIndexes(sectionIndex, stepIndex)?.[1])
        }

        setCurrentProjectID(false)
        setCurrentProjectStep(0)
        setCurrentSection(index)
        setCurrentStep(stepIndex)
    }



    return (
        <div className={`SidebarItem`}>
            <div
                className={`sidebar-item-main ${!show_step_titles_sidebar && currentSection === index ? 'current' : ''}`}
                onClick={() => {
                    saveData()
                    handleSectionClick(index, 0)
                    setMenuOpen(false)
                }}
            >
                <div className={`item-icon ${hasSectionWarning(index) ? 'warning' : (hasAlreadyVisited(index, 0) ? 'visited' : '')}`}></div>
                <div className="item-title">
                    {section_title}
                </div>
            </div>

            {show_step_titles_sidebar && currentSection === index ?

                <div className="sidebar-inner">
                    {steps?.length > 0 && steps.map((step, stepIndex) => {

                        if (!step['step_title']) {
                            return null
                        }

                        return (
                            <div
                                key={stepIndex}
                                className={`step-item ${currentStep === stepIndex ? 'current' : ''}`}
                                onClick={() => {
                                    saveData()
                                    handleSectionClick(index, stepIndex)
                                    setMenuOpen(false)
                                }}
                            >
                                <div className={`step-item-icon ${hasStepWarning(index, stepIndex) ? 'warning' : (hasAlreadyVisited(index, stepIndex) ? 'visited' : '')}`}></div>
                                <div className="step-item-title">
                                    {step['step_title']}
                                </div>
                            </div>
                        )

                    })}
                </div>

                : null}
        </div>
    )
}
