import { Text, View, Image } from '@react-pdf/renderer'

export default function RegionTeam({ formData }) {

    const getValues = () => {
        return formData.filter(field => field.field_key === '1-2-kernteam')?.[0]?.field_value
    }

    return (
        <View
            wrap={false}
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '20px'
            }}
        >
            <Text
                style={{
                    fontSize: '13px',
                    lineHeight: '1.4',
                    color: '#0b8bcc',
                    fontFamily: 'ArchivoBold'
                }}
                hyphenationCallback={e => [e]}
            >
                1.2 Kernteam
            </Text>


            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >


                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px',
                        marginTop: '3px'
                    }}
                >
                    <Text
                        style={{
                            width: '50%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Naam
                    </Text>

                    <Text
                        style={{
                            width: '50%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Functie
                    </Text>
                </View>


                {getValues().map((item, itemIndex) => {
                    return (
                        <View
                            key={itemIndex}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '5px',
                                borderBottom: '1px solid #e6e7e8'
                            }}>

                            <Text style={{
                                width: '50%',
                                fontSize: '11px',
                                color: '#000',
                                fontFamily: 'Archivo',
                            }}
                            hyphenationCallback={e => [e]}
                            >
                                {item[0]}
                            </Text>

                            <Text style={{
                                width: '50%',
                                fontSize: '11px',
                                color: '#000',
                                fontFamily: 'Archivo',
                            }}
                            hyphenationCallback={e => [e]}
                            >
                                {item[1]}
                            </Text>
                        </View>
                    )
                })}

            </View>

        </View>
    )
}
