export default function AppReducer(state, action) {
    switch (action.type) {

        case 'SET_FORM_DATA':
            return {
                ...state,
                formData: action.payload
            }

        case 'SET_CURRENT_SECTION':
            return {
                ...state,
                currentSection: action.payload
            }

        case 'SET_CURRENT_STEP':
            return {
                ...state,
                currentStep: action.payload
            }

        case 'SET_VISITED_SECTIONS':
            return {
                ...state,
                visitedSections: action.payload
            }

        case 'SET_INPUT_WARNINGS':
            return {
                ...state,
                inputWarnings: action.payload
            }

        case 'SET_CURRENT_PROJECT_ID':
            return {
                ...state,
                currentProjectID: action.payload
            }

        case 'SET_CURRENT_PROJECT_STEP':
            return {
                ...state,
                currentProjectStep: action.payload
            }

        case 'SET_DATA_SAVING':
            return {
                ...state,
                isDataSaving: action.payload
            }

        case 'SET_SAVING_STATUS':
            return {
                ...state,
                savingStatus: action.payload
            }

        case 'SET_LATEST_DOWNLOAD':
            return {
                ...state,
                lastDownloaded: action.payload
            }

        case 'SET_MENU_OPEN':
            return {
                ...state,
                isMenuOpen: action.payload
            }

        case 'SET_INPUT_INSTRUCTIONS':
            return {
                ...state,
                inputInstructions: action.payload
            }

        case 'SET_USER_ROLE':
            return {
                ...state,
                userRole: action.payload
            }

        case 'SET_LAST_UPDATED':
            return {
                ...state,
                lastUpdated: action.payload
            }
        case 'SET_LAST_UPDATED_TIME':
            return {
                ...state,
                lastUpdatedTime: action.payload
            }

        default: return state
    }
}