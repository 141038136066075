import { useState } from 'react'
import { Document } from '@react-pdf/renderer'
import Fonts from './Parts/Fonts'
import TitlePage from './Pages/TitlePage'
import SignPage from './Pages/SignPage';
import TableOfContents from './Pages/TableOfContents'
import AboutRegion from './Pages/AboutRegion/AboutRegion'
import PrioritiesPage from './Pages/PrioritiesPage';
import ProjectsOverview from './Pages/Projects/ProjectsOverview';
import ProjectsList from './Pages/Projects/ProjectsList';
import FinancialMain from './Pages/FinancialOverview/FinancialMain';
import ProjectCommunication from './Pages/ProjectCommunication';
import ProjectSafety from './Pages/ProjectSafety';


export default function PDFDocument({ formData }) {

    const [signPageNumber, setSignPageNumber] = useState(null)
    const [aboutRegionPageNumber, setAboutRegionPageNumber] = useState(null)
    const [prioritiesPageNumber, setPrioritiesPageNumber] = useState(null)
    const [projectsPageNumber, setProjectsPageNumber] = useState(null)
    const [projectsListPages, setProjectsListPages] = useState([])
    const [financialOverviewPageNumber, setFinancialOverviewPageNumber] = useState(null)
    const [projectCommunicationPageNumber, setProjectCommunicationPageNumber] = useState(null)
    const [projectSafetyPageNumber, setProjectSafetyPageNumber] = useState(null)

    const getSignPageNumber = pageNum => setSignPageNumber(pageNum)
    const getAboutRegionPageNumber = pageNum => setAboutRegionPageNumber(pageNum)
    const getPrioritiesPageNumber = pageNum => setPrioritiesPageNumber(pageNum)
    const getProjectsPageNumber = pageNum => setProjectsPageNumber(pageNum)
    const getFinancialOverviewPageNumber = pageNum => setFinancialOverviewPageNumber(pageNum)
    const getProjectCommunicationPageNumber = pageNum => setProjectCommunicationPageNumber(pageNum)
    const getProjectSafetyPageNumber = pageNum => setProjectSafetyPageNumber(pageNum)

    const getProjectsListPagesItem = pageData => {
        const { pageNumber } = pageData

        if (projectsListPages.filter(page => page.pageNumber === pageNumber)?.length === 0) {
            setProjectsListPages([...projectsListPages, pageData].sort((a, b) => a.pageNumber - b.pageNumber))
        }
    }


    if (!formData) {
        return null
    }

    return (
        <Document>
            <Fonts />
            <TitlePage formData={formData} />
            <SignPage getSignPageNumber={getSignPageNumber} formData={formData} />
            <TableOfContents
                signPageNumber={signPageNumber}
                aboutRegionPageNumber={aboutRegionPageNumber}
                prioritiesPageNumber={prioritiesPageNumber}
                projectsPageNumber={projectsPageNumber}
                projectsListPages={projectsListPages}
                financialOverviewPageNumber={financialOverviewPageNumber}
                projectCommunicationPageNumber={projectCommunicationPageNumber}
                projectSafetyPageNumber={projectSafetyPageNumber}
            />
            <AboutRegion formData={formData} getAboutRegionPageNumber={getAboutRegionPageNumber} />
            <PrioritiesPage formData={formData} getPrioritiesPageNumber={getPrioritiesPageNumber} />
            <ProjectsOverview formData={formData} getProjectsPageNumber={getProjectsPageNumber} />
            <ProjectsList formData={formData} getProjectsListPagesItem={getProjectsListPagesItem} />
            <ProjectCommunication formData={formData} getProjectCommunicationPageNumber={getProjectCommunicationPageNumber} />
            <ProjectSafety formData={formData} getProjectSafetyPageNumber={getProjectSafetyPageNumber} />
            <FinancialMain formData={formData} getFinancialOverviewPageNumber={getFinancialOverviewPageNumber} />
        </Document>
    )
}
