import FormNavNext from "./FormNavNext";
import FormNavPrev from "./FormNavPrev";
import { useContext } from "react"
import { GlobalContext } from "../../../context/GlobalState"
import useSaveData from "../../../helpers/hooks/useSaveData";
import useUserRole from "../../../helpers/hooks/useUserRole";


export default function FormNav() {

    const {
        globalState
    } = useContext(GlobalContext)

    const { isDataSaving } = globalState

    const { saveData } = useSaveData()

    const { userCanEdit } = useUserRole()

    return (
        <div className="FormNav">
            <FormNavPrev />
            <div
                className={`btn-sec save-btn save-data ${isDataSaving ? 'save-data-loading' : ''} ${!userCanEdit() ? 'disabled' : ''}`}
                onClick={() => saveData()}
            >
                {isDataSaving ? '' : 'Opslaan'}
            </div>
            <FormNavNext />

        </div>
    )
}
