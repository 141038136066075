import formConfig from '../helpers/form-config.json'
import { constructInputWarning, isToShowProjectField } from './functions'

export function checkInputsValidation(sectionIndex, stepIndex, globalState, setInputWarnings, specificInput = false, loopAll = false) {
    if (sectionIndex === undefined) {
        return null;
    }

    if (stepIndex === undefined) {
        return null;
    }

    if (!formConfig?.sections?.length > 0) {
        return false;
    }

    if (!globalState) {
        return false;
    }

    let newInputWarningItems = []
    let currentInputWarningItems = [...globalState.inputWarnings]
    const currentProjectID = globalState.currentProjectID
    const currentProjectStep = globalState.currentProjectStep


    formConfig.sections.forEach((section, i) => {
        if (!section.steps?.length > 0) {
            return
        }

        if ((i === sectionIndex) || (loopAll && i <= sectionIndex)) {

            let stepBreak = false

            if (loopAll && i === sectionIndex) {
                stepBreak = stepIndex
            }

            section.steps.forEach((step, j) => {
                if (!step.fields?.length > 0) {
                    return
                }

                if (stepBreak !== false && j > stepBreak) {
                    return
                }

                if (j === stepIndex || loopAll) {

                    step.fields.forEach(configField => {

                        const { field_type, inner_steps, optional } = configField

                        if (optional) {
                            return
                        }


                        if (globalState?.formData?.length > 0) {

                            // validate repeater_single_items field type
                            if (field_type === 'repeater_single_items') {
                                _validate_repeater_single_items(i, j, configField)
                            }

                            // validate repeater_multiple_items field type
                            if (field_type === 'repeater_multiple_items') {
                                _validate_repeater_multiple_items(i, j, configField)
                            }

                            if (field_type === 'repeater_employee_costs') {
                                _validate_repeater_multiple_items(i, j, configField)
                            }

                            if (
                                field_type === 'single_textarea' ||
                                field_type === 'text_input' ||
                                field_type === 'multiselect' ||
                                field_type === 'signature'
                            ) {
                                _validate_text_input(i, j, configField)
                            }

                            if (field_type === 'projects') {

                                inner_steps?.forEach((step, stepIndex) => {

                                    if (stepIndex <= currentProjectStep || loopAll) {
                                        const projectsFields = step.fields

                                        projectsFields?.forEach(projectConfigField => {

                                            if (projectConfigField.optional) {
                                                return
                                            }

                                            if (!isToShowProjectField(projectConfigField, globalState.formData, currentProjectID)) {
                                                _removeProjectConditionalWarning(projectConfigField)
                                                return
                                            }


                                            // validate repeater_single_items field type
                                            if (projectConfigField.field_type === 'repeater_single_items') {
                                                _validate_repeater_single_items(i, j, projectConfigField, true)
                                            }

                                            // validate repeater_multiple_items field type
                                            if (projectConfigField.field_type === 'repeater_multiple_items') {
                                                _validate_repeater_multiple_items(i, j, projectConfigField, true)
                                            }

                                            if (
                                                projectConfigField.field_type === 'single_textarea' ||
                                                projectConfigField.field_type === 'text_input' ||
                                                projectConfigField.field_type === 'multiselect'
                                            ) {
                                                _validate_text_input(i, j, projectConfigField, true)
                                            }

                                        })
                                    }

                                })


                                _validate_projects_amount(i, j)

                            }
                        }

                    })
                }

            })
        }

    })

    function _removeProjectConditionalWarning(projectConfigField) {
        currentInputWarningItems = currentInputWarningItems.filter(item => !(item.includes(projectConfigField?.field_key) && item.includes(currentProjectID)))
    }

    function _validate_projects_amount(i, j) {
        const sortedProjects = []
        const projectFields = globalState?.formData?.filter(field => field.field_key?.includes('3-1-projecten'))
        const projectWarningItem = constructInputWarning(i, j, '3-1-projecten-project-amount', 'none', '')

        projectFields.forEach(field => {

            const projectID = field?.field_key?.split('-').slice(-1)[0]

            if (sortedProjects.filter(group => group.projectID === projectID).length === 0) {
                const initObj = {
                    "projectID": projectID
                }
                sortedProjects.push(initObj)
            }
        })

        const valid = sortedProjects.length >= 5


        if (!valid && !currentInputWarningItems.includes(projectWarningItem)) {
            newInputWarningItems.push(projectWarningItem)
        } else if (valid && currentInputWarningItems.includes(projectWarningItem)) {
            currentInputWarningItems = currentInputWarningItems.filter(item => item !== projectWarningItem)
        }
    }

    function _validate_repeater_single_items(i, j, configField, isProject = false) {

        const fieldData = globalState.formData.filter(formDataField => formDataField.field_key === configField.field_key)?.[0]

        if (fieldData) {
            const { field_value, field_value_custom, field_value_nvt } = fieldData

            // validate default values
            field_value?.length > 0 && field_value.forEach((field, fieldIndex) => {

                const inputWarningItem = constructInputWarning(i, j, configField.field_key, fieldIndex, 'defaultValue')


                if (specificInput) {

                    if (specificInput === inputWarningItem) {

                        if (!field_value_nvt.includes(fieldIndex)) {
                            if (field === '' && !currentInputWarningItems.includes(specificInput)) {
                                newInputWarningItems.push(specificInput)
                            } else if (field !== '' && currentInputWarningItems.includes(specificInput)) {
                                currentInputWarningItems = currentInputWarningItems.filter(item => item !== specificInput)
                            }
                        } else if (currentInputWarningItems.includes(specificInput)) {
                            currentInputWarningItems = currentInputWarningItems.filter(item => item !== specificInput)
                        }

                    }


                } else {

                    if (!field_value_nvt.includes(fieldIndex)) {
                        if (field === '' && !currentInputWarningItems.includes(inputWarningItem)) {
                            newInputWarningItems.push(inputWarningItem)
                        } else if (field !== '' && currentInputWarningItems.includes(inputWarningItem)) {
                            currentInputWarningItems = currentInputWarningItems.filter(item => item !== inputWarningItem)
                        }
                    } else if (currentInputWarningItems.includes(inputWarningItem)) {
                        currentInputWarningItems = currentInputWarningItems.filter(item => item !== inputWarningItem)
                    }
                }


            })


            // validate custom values
            field_value_custom?.length > 0 && field_value_custom.forEach((field) => {

                const inputWarningItem = constructInputWarning(i, j, configField.field_key, field.label, 'customValue')

                if (specificInput) {

                    if (specificInput === inputWarningItem) {
                        if (field.value === '' && !currentInputWarningItems.includes(specificInput)) {
                            newInputWarningItems.push(specificInput)
                        } else if (field.value !== '' && currentInputWarningItems.includes(specificInput)) {
                            currentInputWarningItems = currentInputWarningItems.filter(item => item !== specificInput)
                        }
                    }

                } else {
                    if (field.value === '' && !currentInputWarningItems.includes(inputWarningItem)) {
                        newInputWarningItems.push(inputWarningItem)
                    } else if (field.value !== '' && currentInputWarningItems.includes(inputWarningItem)) {
                        currentInputWarningItems = currentInputWarningItems.filter(item => item !== inputWarningItem)
                    }
                }

            })


        }
    }


    function _validate_repeater_multiple_items(i, j, configField, isProject = false) {

        let fieldData = null
        if (isProject) {
            fieldData = globalState.formData.filter(formDataField => formDataField.field_key === `${configField.field_key}-${currentProjectID}`)?.[0]
        } else {
            fieldData = globalState.formData.filter(formDataField => formDataField.field_key === configField.field_key)?.[0]
        }

        if (fieldData) {
            const { field_value } = fieldData

            if (field_value?.length > 0) {
                field_value.forEach((row, rowIndex) => {

                    if (row?.length > 0) {
                        row.forEach((itemValue, itemValueIndex) => {
                            const inputWarningItem = constructInputWarning(i, j, configField.field_key, `${rowIndex}-${itemValueIndex}`, isProject ? `projectID-${currentProjectID}` : '')

                            if (specificInput) {

                                if (specificInput === inputWarningItem) {
                                    if (itemValue === '' && !currentInputWarningItems.includes(specificInput)) {
                                        newInputWarningItems.push(specificInput)
                                    } else if (itemValue !== '' && currentInputWarningItems.includes(specificInput)) {
                                        currentInputWarningItems = currentInputWarningItems.filter(item => item !== specificInput)
                                    }
                                }

                            } else {
                                if (itemValue === '' && !currentInputWarningItems.includes(inputWarningItem)) {
                                    newInputWarningItems.push(inputWarningItem)
                                } else if (itemValue !== '' && currentInputWarningItems.includes(inputWarningItem)) {
                                    currentInputWarningItems = currentInputWarningItems.filter(item => item !== inputWarningItem)
                                }
                            }


                        })
                    }

                })
            } else {
                currentInputWarningItems = currentInputWarningItems.filter(item => !item.includes(`${configField.field_key}`))
            }



        }
    }


    function _validate_text_input(i, j, configField, isProject = false) {

        let fieldData = null
        if (isProject) {
            fieldData = globalState.formData.filter(formDataField => formDataField.field_key === `${configField.field_key}-${currentProjectID}`)?.[0]
        } else {
            fieldData = globalState.formData.filter(formDataField => formDataField.field_key === configField.field_key)?.[0]
        }

        if (fieldData) {
            const { field_value } = fieldData

            const inputWarningItem = constructInputWarning(i, j, configField.field_key, 'none', isProject ? `projectID-${currentProjectID}` : '')

            if (specificInput) {

                if (specificInput === inputWarningItem) {
                    if (field_value === '' && !currentInputWarningItems.includes(specificInput)) {
                        newInputWarningItems.push(specificInput)
                    } else if (field_value !== '' && currentInputWarningItems.includes(specificInput)) {
                        currentInputWarningItems = currentInputWarningItems.filter(item => item !== specificInput)
                    }
                }

            } else {
                if (field_value === '' && !currentInputWarningItems.includes(inputWarningItem)) {
                    newInputWarningItems.push(inputWarningItem)
                } else if (field_value !== '' && currentInputWarningItems.includes(inputWarningItem)) {
                    currentInputWarningItems = currentInputWarningItems.filter(item => item !== inputWarningItem)
                }
            }



        }
    }


    setInputWarnings([...currentInputWarningItems, ...newInputWarningItems])

}