import formConfig from '../helpers/form-config.json'


export function generateFormData() {
    if (!formConfig?.sections?.length > 0) {
        return false;
    }

    let fields = [];


    formConfig.sections.forEach(section => {
        if (!section.steps?.length > 0) {
            return
        }

        section.steps.forEach(step => {
            if (!step.fields?.length > 0) {
                return
            }

            step.fields.forEach(field => {

                const { field_type } = field

                if (field_type !== 'content') {
                    const initFieldData = generateInitialFieldData(field)

                    if (initFieldData) {
                        fields.push(initFieldData)
                    }
                }


            })
        })
    })

    return fields;
}


export function generateInitialFieldData(field, projectId = false) {

    const { field_type, default_inputs, inputs, allow_empty, predefined_inputs } = field

    let field_key = field['field_key']

    if (projectId) {
        field_key = `${field['field_key']}-${projectId}`
    }

    let field_value = '';

    if (field_type === 'repeater_single_items') {

        if (default_inputs?.length > 0) {
            field_value = Array(default_inputs.length).fill('')
        }

        return {
            "field_value": field_value,
            "field_value_custom": [],
            "field_value_nvt": [],
            "field_key": field_key ? field_key : false,
        }
    }

    if (field_type === 'repeater_multiple_items') {

        if (allow_empty) {
            return {
                "field_value": [],
                "field_key": field_key ? field_key : false,
            }
        }

        if (inputs?.length > 0) {
            field_value = Array(1).fill(inputs.map((input, i) => {

                let value = ''

                if (input.input_type === 'radio' && input.choices?.length > 0) {
                    value = input.choices[0]
                }

                predefined_inputs && predefined_inputs.forEach(predefined_input => {

                    if (
                        predefined_input.row === 0 &&
                        predefined_input.cell === i
                    ) {
                        value = predefined_input.value
                    }
                })

                return value
            }))
        }


        return {
            "field_value": field_value,
            "field_key": field_key ? field_key : false,
        }
    }

    if (
        field_type === 'single_textarea' ||
        field_type === 'text_input' ||
        field_type === 'multiselect' ||
        field_type === 'signature'
    ) {
        return {
            "field_value": '',
            "field_key": field_key ? field_key : false,
        }
    }

    if (field_type === 'repeater_employee_costs') {
        return {
            "field_value": [['', '', '', false, 'Loondienst']],
            "field_key": field_key ? field_key : false,
        }
    }

    return false
}