import { Text, Image, View } from '@react-pdf/renderer'
import PVOLogo from './PVOLogo'

export default function Footer() {
    return (
        <View
            fixed={true}
            style={{
                position: 'absolute',
                left: '70px',
                bottom: '34px',
                width: '100%',
            }}
        >
            <Image
                style={{
                    height: '25px',
                    width: '61px',
                    position: 'absolute',
                    bottom: '0',
                    left: '0'
                }}
                src={PVOLogo()}
            />
            <Text
                style={{
                    position: 'absolute',
                    bottom: '0',
                    right: '0',
                    fontSize: '11px',
                    color: '#000'
                }}
                render={({ pageNumber }) => pageNumber}
            />
        </View>
    )
}
