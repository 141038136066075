import { Page, Text, View } from '@react-pdf/renderer'
import Footer from '../Parts/Footer'
import TopMark from '../Parts/TopMark'
import PageNumberGetter from '../Parts/PageNumberGetter'
import parsePrice from 'parse-price'
import { formateEuroString } from '../../../helpers/functions'

export default function ProjectCommunication({ formData, getProjectCommunicationPageNumber }) {


    const getProjectInzet = () => {
        return formData?.filter(field => field.field_key.includes('3-2-inzet-pvo'))?.[0]?.field_value
    }

    const getProjectCosts = () => {
        const data = [...formData?.filter(field => field.field_key.includes('3-3-projectkosten-communicatie'))?.[0]?.field_value]

        if (data) {
            return data.sort((a, b) => parsePrice(b[1]) - parsePrice(a[1]))
        }

        return false
    }

    const getTotalProjectCosts = () => {
        let total = 0
        const data = formData?.filter(field => field.field_key.includes('3-3-projectkosten-communicatie'))?.[0]?.field_value

        if (data) {
            data.forEach(item => total += parsePrice(item[1]))
        }

        return total
    }

    const getProjectHours = () => {
        return formData?.filter(field => field.field_key.includes('3-4-inzet-uren-communicatie'))?.[0]?.field_value
    }

    const getTotalProjectHours = () => {
        let total = 0
        const data = formData?.filter(field => field.field_key.includes('3-4-inzet-uren-communicatie'))?.[0]?.field_value

        if (data) {
            data.forEach(item => total += parsePrice(item[1]))
        }

        return total
    }

    return (
        <Page
            style={{ padding: '70px 70px 70px 70px', position: 'relative' }}
            size="A4"
        >
            <TopMark />
            <PageNumberGetter getterFunction={getProjectCommunicationPageNumber} />

            <Text
                style={{
                    fontFamily: 'Roboto',
                    fontSize: '24px',
                    textDecoration: 'underline',
                    color: '#000'
                }}
                hyphenationCallback={e => [e]}
            >
                Project: Algemene communicatie
            </Text>


            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '25px'
                }}
            >
                <Text
                    style={{
                        fontSize: '13px',
                        lineHeight: '1.4',
                        color: '#0b8bcc',
                        fontFamily: 'ArchivoBold',
                        marginBottom: '5px'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    Inzet PVO
                </Text>

                <Text
                    style={{
                        fontSize: '11px',
                        lineHeight: '1.6',
                        color: '#000',
                        fontFamily: 'Archivo'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    {getProjectInzet()}
                </Text>

            </View>


            {/* project costs */}
            <View
                wrap={false}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '20px'
                }}
            >

                <Text
                    style={{
                        fontSize: '13px',
                        lineHeight: '1.4',
                        color: '#0b8bcc',
                        fontFamily: 'ArchivoBold',
                        marginBottom: '5px'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    Projectkosten
                </Text>


                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px'
                    }}>

                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Soort post
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Kosten
                    </Text>

                </View>

                {getProjectCosts().map((row, rowIndex) => {
                    return (
                        <View
                            key={rowIndex}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '5px',
                                borderBottom: rowIndex !== getProjectCosts().length - 1 ? '1px solid #e6e7e8' : ''
                            }}>

                            <Text
                                style={{
                                    width: '83%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {row[0]}
                            </Text>

                            <Text
                                style={{
                                    width: '17%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {formateEuroString(parsePrice(row[1]))}
                            </Text>

                        </View>
                    )
                })}

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px',
                    }}
                >
                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                            backgroundColor: '#e4f5fd',
                            borderRadius: '3px'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Totaal kosten
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                            marginLeft: 'auto'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {formateEuroString(parsePrice(getTotalProjectCosts()))}
                    </Text>
                </View>
            </View>



            {/* project hours */}
            <View
                wrap={false}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '20px'
                }}
            >

                <Text
                    style={{
                        fontSize: '13px',
                        lineHeight: '1.4',
                        color: '#0b8bcc',
                        fontFamily: 'ArchivoBold',
                        marginBottom: '5px'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    Inzet uren
                </Text>


                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px'
                    }}>

                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Functie
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Begrote uren
                    </Text>

                </View>

                {getProjectHours().map((row, rowIndex) => {
                    return (
                        <View
                            key={rowIndex}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '5px',
                                borderBottom: rowIndex !== getProjectHours().length - 1 ? '1px solid #e6e7e8' : ''
                            }}>

                            <Text
                                style={{
                                    width: '83%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {row[0]}
                            </Text>

                            <Text
                                style={{
                                    width: '17%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {row[1]}
                            </Text>

                        </View>
                    )
                })}

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px',
                    }}
                >
                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                            backgroundColor: '#e4f5fd',
                            borderRadius: '3px'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Totaal begrote projecturen
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                            marginLeft: 'auto'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {getTotalProjectHours()}
                    </Text>
                </View>
            </View>


            <Footer />
        </Page>
    )
}
