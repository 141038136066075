import { Text, View } from '@react-pdf/renderer'
import parsePrice from 'parse-price'
import { formateEuroString, getTotalCostPersonnel } from '../../../../helpers/functions'

export default function FinancialCosts({ formData }) {

    const getCostsData = () => {
        return formData.filter(field => field.field_key === '4-2-personeelskosten')?.[0]?.field_value
    }

    const costsDataZZP = getCostsData()?.filter(row => row?.[4] === 'ZZP')
    const costsDataLoondienst = getCostsData()?.filter(row => row?.[4] === 'Loondienst')


    const calculateTotalSumRow = (priceValue, hoursValue, employmentTypeValue) => {

        priceValue = parsePrice(priceValue)

        if (employmentTypeValue === 'ZZP') {
            hoursValue = parsePrice(hoursValue)
            return formateEuroString(hoursValue * priceValue)
        }

        return formateEuroString(priceValue)
    }

    const getCostsExtraText = () => {
        return formData.filter(field => field.field_key === '4-2-1-voorwoord')?.[0]?.field_value
    }

    return (
        <View
            wrap={false}
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '15px',
                marginTop: '25px'
            }}
        >

            <Text
                style={{
                    fontSize: '13px',
                    lineHeight: '1.4',
                    color: '#0b8bcc',
                    fontFamily: 'ArchivoBold'
                }}
                hyphenationCallback={e => [e]}
            >
                4.2 Uitgaven
            </Text>

            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '5px'
                }}
            >
                <Text
                    style={{
                        fontSize: '11px',
                        lineHeight: '1.4',
                        color: '#0b8bcc',
                        fontFamily: 'ArchivoBold',
                        marginBottom: '2px'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    Personeelskosten
                </Text>

            </View>



            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '15px'
                }}
            >

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px'
                    }}>

                    <Text
                        style={{
                            width: '39%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoMedium',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Functie
                    </Text>

                    <Text
                        style={{
                            width: '18%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoMedium',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Kosten
                    </Text>

                    <Text
                        style={{
                            width: '8%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoMedium',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Uren
                    </Text>

                    <Text
                        style={{
                            width: '18%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoMedium',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Totaal
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoMedium',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Dienstverband
                    </Text>

                </View>

                {costsDataLoondienst.map((costRow, costRowIndex) => {
                    return (
                        <View
                            key={costRowIndex}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '5px',
                                borderBottom: costRowIndex !== getCostsData().length - 1 ? '1px solid #e6e7e8' : ''
                            }}>

                            <Text
                                style={{
                                    width: '39%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {costRow[0]}
                            </Text>

                            <Text
                                style={{
                                    width: '18%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {formateEuroString(parsePrice(costRow[1]))}
                            </Text>

                            <Text
                                style={{
                                    width: '8%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {costRow[2]}
                            </Text>

                            <Text
                                style={{
                                    width: '18%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {calculateTotalSumRow(costRow[1], costRow[2], costRow[4])}
                            </Text>

                            <Text
                                style={{
                                    width: '17%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {costRow[4]}
                            </Text>

                        </View>
                    )
                })}

            </View>


            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '15px'
                }}
            >
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px'
                    }}>

                    <Text
                        style={{
                            width: '39%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoMedium',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Functie
                    </Text>

                    <Text
                        style={{
                            width: '18%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoMedium',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Uurtarief
                    </Text>

                    <Text
                        style={{
                            width: '8%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoMedium',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Uren
                    </Text>

                    <Text
                        style={{
                            width: '18%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoMedium',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Totaal
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoMedium',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Dienstverband
                    </Text>

                </View>


                {costsDataZZP.map((costRow, costRowIndex) => {
                    return (
                        <View
                            key={costRowIndex}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '5px',
                                borderBottom: costRowIndex !== getCostsData().length - 1 ? '1px solid #e6e7e8' : ''
                            }}>

                            <Text
                                style={{
                                    width: '39%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {costRow[0]}
                            </Text>

                            <Text
                                style={{
                                    width: '18%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {formateEuroString(parsePrice(costRow[1]))}
                            </Text>

                            <Text
                                style={{
                                    width: '8%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {costRow[2]}
                            </Text>

                            <Text
                                style={{
                                    width: '18%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {calculateTotalSumRow(costRow[1], costRow[2], costRow[4])}
                            </Text>

                            <Text
                                style={{
                                    width: '17%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {costRow[4]}
                            </Text>

                        </View>
                    )
                })}

            </View>



            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '5px',
                    backgroundColor: '#e4f5fd',
                    borderRadius: '3px',
                }}
            >
                <Text
                    style={{
                        width: '85%',
                        fontSize: '11px',
                        color: '#000',
                        fontFamily: 'ArchivoBold',
                    }}
                    hyphenationCallback={e => [e]}
                >
                    Totaal personeelskosten
                </Text>

                <Text
                    style={{
                        width: '17%',
                        fontSize: '11px',
                        color: '#000',
                        fontFamily: 'ArchivoBold',
                        marginLeft: 'auto'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    {getTotalCostPersonnel(formData)}
                </Text>
            </View>

            {getCostsExtraText() ?

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '15px'
                    }}
                >
                    <Text
                        style={{
                            fontSize: '11px',
                            lineHeight: '1.4',
                            color: '#0b8bcc',
                            fontFamily: 'ArchivoBold',
                            marginBottom: '2px'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Toelichting personeelskosten
                    </Text>

                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >

                        <Text
                            style={{
                                fontSize: '11px',
                                lineHeight: '1.6',
                                color: '#000',
                                fontFamily: 'Archivo',
                            }}
                            hyphenationCallback={e => [e]}
                        >
                            {getCostsExtraText()}
                        </Text>


                    </View>

                </View>
                : null}

        </View>
    )
}
