import { useState } from 'react'
import { Page, Text, View } from '@react-pdf/renderer'
import Footer from '../Parts/Footer'
import TopMark from '../Parts/TopMark'
import PageNumberGetter from '../Parts/PageNumberGetter'


export default function TableOfContents(props) {

    const {
        signPageNumber,
        aboutRegionPageNumber,
        prioritiesPageNumber,
        projectsPageNumber,
        projectsListPages,
        financialOverviewPageNumber,
        projectCommunicationPageNumber,
        projectSafetyPageNumber
    } = props


    const [currentPageNumber, setCurrentPageNumber] = useState(null)


    const ItemRow = ({ pageName, pageNumber }) => (
        <View
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '6px',
                position: 'relative'
            }}
        >
            <View
                style={{
                    position: 'absolute',
                    top: '8px',
                    left: '0',
                    width: '100%',
                    borderBottom: '1px dotted #008acf',
                }}
            />
            <Text
                style={{
                    fontSize: '11px',
                    lineHeight: '1.4',
                    color: '#0b8bcc',
                    fontFamily: 'ArchivoBold',
                    zIndex: '0',
                    backgroundColor: '#fff',
                    paddingRight: '3px'
                }}
                hyphenationCallback={e => [e]}
            >
                {pageName}
            </Text>
            <Text
                style={{
                    fontSize: '11px',
                    lineHeight: '1.4',
                    color: '#0b8bcc',
                    fontFamily: 'ArchivoBold',
                    marginLeft: 'auto',
                    zIndex: '0',
                    backgroundColor: '#fff',
                    paddingLeft: '3px'
                }}
                hyphenationCallback={e => [e]}
            >
                {pageNumber}
            </Text>
        </View>
    )


    const ItemProjectRow = ({ pageName, pageNumber }) => (
        <View
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '6px',
                position: 'relative'
            }}
        >
            <View
                style={{
                    position: 'absolute',
                    top: '8px',
                    left: '0',
                    width: '100%',
                    borderBottom: '1px dotted #000',
                }}
            />
            <Text
                style={{
                    fontSize: '11px',
                    lineHeight: '1.4',
                    color: '#000',
                    fontFamily: 'ArchivoBold',
                    paddingLeft: '20px',
                    backgroundColor: '#fff',
                    paddingRight: '3px'
                }}
                hyphenationCallback={e => [e]}
            >
                {`${pageName}`}
            </Text>
            <Text
                style={{
                    fontSize: '11px',
                    lineHeight: '1.4',
                    color: '#000',
                    fontFamily: 'ArchivoBold',
                    marginLeft: 'auto',
                    backgroundColor: '#fff',
                    paddingLeft: '3px'
                }}
                hyphenationCallback={e => [e]}
            >
                {pageNumber}
            </Text>
        </View>
    )


    return (
        <Page
            style={{ padding: '70px', position: 'relative' }}
            size="A4"
        >

            <TopMark />
            <PageNumberGetter getterFunction={setCurrentPageNumber} />

            <Text
                style={{
                    fontFamily: 'Roboto',
                    fontSize: '24px',
                    textDecoration: 'underline',
                    color: '#000',
                }}
                hyphenationCallback={e => [e]}
            >
                Inhoudsopgave
            </Text>

            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0 40px',
                    marginTop: '20px'
                }}
            >

                <ItemRow
                    pageName={'Voorwoord'}
                    pageNumber={signPageNumber}
                />

                <ItemRow
                    pageName={'Inhoudsopgave'}
                    pageNumber={currentPageNumber}
                />

                <ItemRow
                    pageName={'1. Over de regio'}
                    pageNumber={aboutRegionPageNumber}
                />

                <ItemRow
                    pageName={'2. Prioriteiten & ambities'}
                    pageNumber={prioritiesPageNumber}
                />

                <ItemRow
                    pageName={'3. Projecten overzicht'}
                    pageNumber={projectsPageNumber}
                />


                {projectsListPages.map((project, i) => {
                    return (
                        <ItemProjectRow
                            key={i}
                            pageName={project['pageName'] !== '' ? project['pageName'] : 'Geen project naam'}
                            pageNumber={project['pageNumber']}
                        />
                    )
                })}


                <ItemProjectRow
                    pageName={'Algemene communicatie'}
                    pageNumber={projectCommunicationPageNumber}
                />
                <ItemProjectRow
                    pageName={'Week van de Veiligheid'}
                    pageNumber={projectSafetyPageNumber}
                />


                <ItemRow
                    pageName={'4. Begroting'}
                    pageNumber={financialOverviewPageNumber}
                />


            </View>

            <Footer />
        </Page>
    )
}
