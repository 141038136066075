import ProjectPage from "./ProjectPage"

export default function ProjectsList({ getProjectsListPagesItem, formData }) {

    const getProjects = () => {
        const sortedProjects = []
        const projectFields = formData?.filter(field => field.field_key?.includes('3-1-projecten'))

        projectFields.forEach(field => {

            const projectID = field?.field_key?.split('-').slice(-1)[0]

            if (sortedProjects.filter(group => group.projectID === projectID).length === 0) {
                const initObj = {
                    "projectID": projectID
                }
                sortedProjects.push(initObj)
            }
        })


        return sortedProjects;

    }

    return (
        <>
            {getProjects().map((project, i) => {
                return (
                    <ProjectPage
                        key={i}
                        number={i + 1}
                        max_number={getProjects().length}
                        projectID={project['projectID']}
                        formData={formData}
                        getProjectsListPagesItem={getProjectsListPagesItem}
                    />
                )
            })}
        </>
    )
}
