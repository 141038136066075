import formConfig from '../../helpers/form-config.json'
import SidebarItem from './SidebarItem'
import { useContext } from "react"
import { GlobalContext } from "../../context/GlobalState"


export default function Sidebar() {

    const { globalState } = useContext(GlobalContext)

    const { isDataSaving, savingStatus, isMenuOpen, lastUpdated, lastUpdatedTime } = globalState


    return (
        <div className={`Sidebar ${isMenuOpen ? 'active' : ''}`}>

            <div className="sidebar-main-stick">
                <div className="sidebar-main">
                    {formConfig.sections.map((section, i) => {
                        return (
                            <SidebarItem key={i} index={i} data={section} />
                        )
                    })}
                </div>

                {isDataSaving ?
                    <div className="saving-status">
                        <div className="error-message progress">
                            <div className="status">
                                <p className="title">Gegevens opslaan</p>
                                <div className="saving-icon progress"></div>
                            </div>
                            <p className="message">De gegevens worden bijgewerkt.</p>
                        </div>
                    </div>
                   
                : null}


                {!isDataSaving && savingStatus === 'successful' ?
                    <div className="saving-status">
                        <div className="error-message success">
                            <div className="status">
                                <p className="title">Gegevens opgeslagen </p>
                                <div className="saving-icon success"></div>
                            </div>
                            <p className="message">Laatst bijgewerkt op: { lastUpdated } om { lastUpdatedTime }.</p>
                        </div>
                    </div>
                : null}


                {!isDataSaving && savingStatus === 'error' ?
                    <div className="saving-status">
                        <div className="error-message error">
                            <div className="status">
                                <p className="title">Fout bij het opslaan</p>
                                <div className="saving-icon error"></div>
                            </div>
                            <p className="message">Het (automatisch) opslaan van het jaarplan is mislukt. Stop met invullen om dataverlies te voorkomen. Neem contact op met Sabine Voors.</p>
                        </div>
                    </div>
                : null}
            </div>


        </div>
    )
}
