import { Page, Text, Image, View } from '@react-pdf/renderer'
import TitleImage from '../Parts/TitleImage'
import PVOLogo from '../Parts/PVOLogo'

export default function TitlePage({ formData }) {

    const getAuthor = () => {
        return formData.filter(field => field.field_key === '5-2-auteurs')?.[0]?.field_value
    }

    const getRegion = () => {
        return formData.filter(field => field.field_key === '6-3-pvo-region')?.[0]?.field_value
    }

    const getYear = () => {
        return formData.filter(field => field.field_key === '6-4-year')?.[0]?.field_value
    }


    const breakName = name => {
        return [name]
    }

    return (
        <Page
            style={{ padding: '70px 70px 30 70px' }}
            size="A4"
        >
            <Image style={{ width: '455px', height: '335px' }} src={TitleImage()} />
            <View style={{
                width: '455px',
                height: '328px',
                backgroundColor: '#0b8bcc',
                padding: '40px',
                position: 'relative'
            }}>
                <View
                    style={{
                        width: '55px',
                        height: '125px',
                        backgroundColor: '#fff',
                        bottom: '-20px',
                        right: '-28px',
                        position: 'absolute',
                        transform: 'rotate(25deg)'
                    }}
                />

                <Text
                    style={{
                        fontSize: '41px',
                        lineHeight: 1.3,
                        color: '#fff',
                        textDecoration: 'underline',
                        fontFamily: 'Roboto'
                    }}
                    hyphenationCallback={e => [e]}
                    >
                    {`Jaarplan ${getYear()}`}
                </Text>
                <Text
                    style={{
                        fontSize: '41px',
                        lineHeight: 1.3,
                        color: '#fff',
                        textDecoration: 'underline',
                        fontFamily: 'Roboto',
                        flex: '1'
                    }}
                    hyphenationCallback={e => breakName(e)}
                >
                    {`PVO ${getRegion()}`}
                </Text>


                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute',
                        bottom: '55px',
                        left: '40px'
                    }}
                >
                    <Text
                        style={{
                            fontFamily: 'ArchivoBold',
                            fontSize: '16px',
                            lineHeight: 1.2,
                            color: '#fff',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {`Auteur: `}
                    </Text>
                    <Text
                        style={{
                            fontSize: '16px',
                            lineHeight: 1.2,
                            color: '#fff',
                            fontFamily: 'Archivo',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {getAuthor()}
                    </Text>

                </View>

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute',
                        bottom: '30px',
                        left: '40px'
                    }}
                >
                    <Text
                        style={{
                            fontFamily: 'ArchivoBold',
                            fontSize: '12px',
                            lineHeight: 1.2,
                            color: '#fff',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {`Referentie: `}
                    </Text>
                    <Text
                        style={{
                            fontSize: '12px',
                            lineHeight: 1.2,
                            color: '#fff',
                            fontFamily: 'Archivo',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {new Date().toISOString().replace(/T.*/, '').split('-').join('')}
                    </Text>

                </View>

            </View>

            <Text
                style={{
                    fontSize: '13px',
                    lineHeight: 1.2,
                    color: '#000',
                    fontFamily: 'Archivo',
                    marginTop: '40px'
                }}
                hyphenationCallback={e => [e]}
                >
                Veilig ondernemen.
            </Text>
            <Text
                style={{
                    fontSize: '13px',
                    lineHeight: 1.2,
                    color: '#000',
                    fontFamily: 'ArchivoBold',
                }}
                hyphenationCallback={e => [e]}
                >
                Daar maken we ons sterk voor.
            </Text>


            <View
                style={{ position: 'absolute', bottom: '40px', right: '70px' }}
            >
                <Image style={{ height: '32px', width: '81px' }} src={PVOLogo()} />
            </View>



        </Page>
    )
}
