import '../src/styles/main.scss'
import { GlobalProvider } from "./context/GlobalState"
import Main from "./components/Main";
import Header from './components/Header'
import Footer from './components/Footer'
// import PDFMain from './components/PDFMain';

function App() {
  return (
    <GlobalProvider>
      <div className="main-root">
        <Header />
        <Main />
        <Footer />

        {/* <PDFMain /> */}

      </div>
    </GlobalProvider>
  )
}

export default App;
