import useSubtitleData from "../../../helpers/hooks/useSubtitleData"

export default function FieldContent({ field }) {

    const { title_blue, field_key } = field

    const { getSubtitleData } = useSubtitleData()

    return (
        <div className="FieldContent form-field">

            {getSubtitleData(`${field_key}-title`) ?
                <div className={`field-title ${title_blue ? 'title-blue' : ''}`}>{getSubtitleData(`${field_key}-title`)}</div>
                : null}

            {getSubtitleData(`${field_key}-subtitle`) ?
                <div dangerouslySetInnerHTML={{ __html: getSubtitleData(`${field_key}-subtitle`) }} className="field-content"></div>
                : null}

        </div>
    )
}
