import { Page, Text } from '@react-pdf/renderer'
import Footer from '../../Parts/Footer'
import TopMark from '../../Parts/TopMark'
import PageNumberGetter from '../../Parts/PageNumberGetter'
import RegionStats from './RegionStats'
import RegionTeam from './RegionTeam'
import RegionManagement from './RegionManagement'
import RegionSupervisors from './RegionSupervisors'

export default function AboutRegion({ formData, getAboutRegionPageNumber }) {

    return (
        <Page
            style={{ padding: '70px', position: 'relative' }}
            size="A4"
        >
            <TopMark />
            <PageNumberGetter getterFunction={getAboutRegionPageNumber} />

            <Text
                style={{
                    fontFamily: 'Roboto',
                    fontSize: '24px',
                    textDecoration: 'underline',
                    color: '#000'
                }}
                hyphenationCallback={e => [e]}
            >
                Over de regio
            </Text>

            <RegionStats formData={formData} />
            <RegionTeam formData={formData} />
            <RegionManagement formData={formData} />
            <RegionSupervisors formData={formData} />

            <Footer />
        </Page>
    )
}
