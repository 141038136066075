import { useContext, useState } from "react"
import { GlobalContext } from "../../../../context/GlobalState"
import { getRandomId } from '../../../../helpers/functions'
import { generateInitialFieldData } from '../../../../helpers/form-data-generator'
import useSubtitleData from "../../../../helpers/hooks/useSubtitleData"
import useUserRole from "../../../../helpers/hooks/useUserRole"

export default function FieldProjectsList({ field }) {

    const [projectIDToRemove, setProjectIDToRemove] = useState(false)
    const [projectNameToRemove, setProjectNameToRemove] = useState(false)
    const [isPopupOpen, setIsPopupOpen] = useState(false)

    const { getSubtitleData } = useSubtitleData()
    const { userCanEdit } = useUserRole()

    const { globalState, setFormData, setCurrentProjectID, setInputWarnings } = useContext(GlobalContext)
    const { formData, inputWarnings } = globalState

    const {
        field_title,
        field_key,
        title_blue,
        inner_steps
    } = field


    const getProjects = () => {
        const sortedProjects = []
        const projectFields = formData?.filter(field => field.field_key?.includes('3-1-projecten'))

        projectFields.forEach(field => {

            const projectID = field?.field_key?.split('-').slice(-1)[0]

            if (sortedProjects.filter(group => group.projectID === projectID).length === 0) {
                const initObj = {
                    "projectID": projectID,
                    "fields": [field]
                }

                sortedProjects.push(initObj)
            } else {
                const groupIndex = sortedProjects.findIndex(group => group.projectID === projectID)
                sortedProjects[groupIndex]['fields'].push(field)
            }

        })


        return sortedProjects;

    }

    const addNewProject = () => {
        if (inner_steps?.length > 0) {

            const projectFields = []
            const projectId = getRandomId()

            inner_steps.forEach((step, i) => {

                if (step?.fields?.length > 0) {
                    step.fields.forEach(field => {
                        const projectInitData = generateInitialFieldData(field, projectId)
                        projectFields.push(projectInitData)
                    })
                }
            })

            setFormData([...formData, ...projectFields])
            setCurrentProjectID(projectId)
        }
    }


    const hasProjectWarning = projectID => {
        return inputWarnings.filter(i => i.includes(`${projectID}`)).length > 0
    }

    const hasProjectAmountWarning = () => {
        return inputWarnings.filter(i => i.includes('3-1-projecten-project-amount')).length > 0
    }


    const removeProject = () => {
        const projectFields = projectIDToRemove && formData?.filter(field => !(field.field_key.includes('3-1-projecten') && field.field_key.includes(projectIDToRemove)))

        const projectWarnings = projectIDToRemove && inputWarnings?.filter(warning => !(warning.includes('3-1-projecten') && warning.includes(projectIDToRemove)))

        setInputWarnings(projectWarnings)
        setFormData(projectFields)
    }

    const handleRemoveClick = (projectID, projectName) => {
        setProjectNameToRemove(projectName)
        setProjectIDToRemove(projectID)
        setIsPopupOpen(true)
    }

    const isAddingMoreAvailable = () => {
        const sortedProjects = []
        const projectFields = globalState?.formData?.filter(field => field.field_key?.includes('3-1-projecten'))

        projectFields.forEach(field => {

            const projectID = field?.field_key?.split('-').slice(-1)[0]

            if (sortedProjects.filter(group => group.projectID === projectID).length === 0) {
                const initObj = {
                    "projectID": projectID
                }
                sortedProjects.push(initObj)
            }
        })

        return sortedProjects.length < 20
    }

    return (
        <div className="FieldProjects form-field">
            {field_title ?
                <div className={`field-title ${title_blue ? 'title-blue' : ''}`}>{field_title}</div>
                : null}

            {getSubtitleData(field_key) ?
                <div className="field-subtitle">{getSubtitleData(field_key)}</div>
                : null}

            {hasProjectAmountWarning() ?

                <div className="field-warning">
                    Er geldt een minimum van 5 projecten
                </div>

                : null}

            <div className="projects-list">
                {getProjects().length > 0 && getProjects().map((group, groupIndex) => {

                    const projectName = group.fields.filter(field => field.field_key.includes('projectnaam'))[0]['field_value']
                    const projectThemas = group.fields.filter(field => field.field_key.includes('projectthemas'))[0]['field_value']

                    return (
                        <div
                            key={groupIndex}
                            className={`project-item ${hasProjectWarning(group.projectID) ? 'warning' : 'valid'}`}
                            onClick={e => {
                                if (!e.target.classList.contains('project-remove')) {
                                    setCurrentProjectID(group.projectID)
                                }
                            }}
                        >
                            <div className="project-themas">
                                {projectThemas ? projectThemas : "Thema's ontbreken"}
                            </div>
                            <div className="project-name">
                                {projectName !== '' ? projectName : 'Nieuw project'}
                            </div>

                            {userCanEdit() ?
                                <div
                                    className="project-remove"
                                    onClick={() => handleRemoveClick(group.projectID, projectName)}
                                ></div>
                                : null}
                        </div>
                    )
                })}



                {getProjects().length === 0 ?

                    <div className="projects-list-empty">
                        <div className="empty-title">
                            Er zijn momenteel nog geen projecten aangemaakt.
                        </div>

                        <div className="empty-subtitle">
                            Maak een nieuw project aan door op de knop “project toevoegen” te klikken. Voeg minimaal vijf en maximaal twintig projecten toe.
                        </div>
                    </div>

                    : null}
            </div>


            {isPopupOpen ?
                <div className="popup-main">
                    <form className="popup-form">

                        <div className="popup-title">
                            {`Weet je zeker dat je project '${projectNameToRemove}' wilt verwijderen?`}
                        </div>

                        <div className="popup-subtitle">
                            Hiermee verwijder je de ingevulde informatie. Deze actie kan je niet ongedaan maken.
                        </div>


                        <div className="button-row right">
                            <button
                                className="btn-sec popup-btn"
                                onClick={e => {
                                    e.preventDefault()
                                    setIsPopupOpen(false)
                                    setProjectIDToRemove(false)
                                    setProjectNameToRemove(false)
                                }}
                            >Annuleren</button>
                            <button
                                className="btn-main popup-btn"
                                onClick={e => {
                                    e.preventDefault()
                                    removeProject()
                                    setIsPopupOpen(false)
                                    setProjectIDToRemove(false)
                                    setProjectNameToRemove(false)
                                }}
                            >Verwijderen</button>
                        </div>

                    </form>
                </div>
                : null}

            {isAddingMoreAvailable() && userCanEdit() ?
                <div
                    className="btn-main blue plus add-project"
                    onClick={() => addNewProject()}
                >Project toevoegen</div>
                : null}
        </div>
    )
}
