import { useContext } from "react"
import { GlobalContext } from "../../../context/GlobalState"
import formConfig from '../../../helpers/form-config.json'
import { storeVisitedSections } from '../../../helpers/functions'
import { checkInputsValidation } from '../../../helpers/form-validator'
import useSaveData from "../../../helpers/hooks/useSaveData"

export default function FormNavPrev() {

    const {
        globalState,
        setCurrentSection,
        setCurrentStep,
        setVisitedSections,
        setInputWarnings,
        setCurrentProjectStep,
        setCurrentProjectID
    } = useContext(GlobalContext)

    const {
        currentSection,
        currentStep,
        visitedSections,
        currentProjectID,
        currentProjectStep
    } = globalState

    const { saveData } = useSaveData()



    const isPrevAvailable = formConfig.sections[currentSection]?.steps[currentStep - 1] || formConfig.sections[currentSection - 1]


    const setPrevView = () => {

        const maxSteps = formConfig.sections[currentSection - 1]?.steps.length

        if (currentStep <= 0 && currentSection <= 0) {
            return false
        }

        if (currentStep - 1 >= 0) {
            checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings)
            storeVisitedSections(visitedSections, setVisitedSections, currentSection, currentStep)
            setCurrentStep(currentStep - 1)
            return
        }

        if (currentSection - 1 >= 0) {
            checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings)
            storeVisitedSections(visitedSections, setVisitedSections, currentSection, currentStep)
            setCurrentSection(currentSection - 1)
            setCurrentStep(maxSteps - 1)
        }
    }


    const setPrevProjectStep = () => {
        checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings)

        if (currentProjectStep - 1 >= 0) {
            setCurrentProjectStep(currentProjectStep - 1)
        } else {
            setCurrentProjectID(false)
            setCurrentProjectStep(0)
        }
    }

    if (currentProjectID) {
        return (
            <div
                className="btn-main arrow-back"
                onClick={() => {
                    saveData()
                    setPrevProjectStep()
                }}
            >Vorige</div>
        )
    }

    return (
        <div
            className={`btn-main arrow-back ${!isPrevAvailable ? 'disabled' : ''}`}
            onClick={() => {
                saveData()
                if (isPrevAvailable) {
                    setPrevView()
                }
            }}
        >Vorige</div>
    )
}
