export default function regionRowIcons(name) {

    switch (name) {
        case 'Gemeentes':
            return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABCUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAI7h9vIAAAAVdFJOUwAQIDBAUGBwf4CPkJ+gr7C/z9Df7zDnVtEAAACHSURBVBgZBcGBAYIwDACwtMhQBBTb/f+qCQAAAJDrugAAxj27Zw0A2PsV5GvuADx7AZbeAHIOgNEBGAUR0Bvg/CC7F5wH4DywzbnhPADvL+K8Et834NEBkHMFqB3gWQDGfAA5BwBXJWQdAIi6g/hVAEDWHXFVAgBZ968SACCrEgCADAAAAOAPFisErkMrth8AAAAASUVORK5CYII='

        case 'Inwoners':
            return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA/UExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAALMlgi8AAAAUdFJOUwAQIDBAUGB/gI+Qn6CvsL/P0N/v0T9zEQAAAIBJREFUGBmtwUFywkAMRcGHLAszspEz8+9/1lQqxcCKDXTzFeYGi/MqJV0pdWOK4azykIppvwPVTBpMewG1udSZXLf1Jgtp4ylOldPHxnuXaEdVW8FbGH+sjZFta8cYaSGlAf3H+Re9OKUOLuNhke2SFlw5HbJdkmP56hKZufCZX+hwBs3d8XiUAAAAAElFTkSuQmCC'

        case 'Ondernemers':
            return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADISURBVHgB1VTtDcIgEH1tOkBHwA06QldwA53AVXQC3cARZBMZwQ0qF84U8YBK8YcveSEcj3d8XA6ojCazPjB9aEuDAsPe8m75EOIbIZ6Fspx4TMXe0AWZh2AzOKYSMYJB8AyKM6/hSEYtG46coSmkeZ24RWVUN+wWaOgDem+usdLwivlHDVwNLjKkTVNEt+fxnNB8GFLlbwUNmRzgrp3SiIZaEJ8wv2FMI54whiO+wM/KxsBd64YyKHD38dvXDokukoGxvOAv8ATVDzCFbL9tdAAAAABJRU5ErkJggg=='

        case 'Bedrijventerreinen':
            return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAzUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAKMFRskAAAAQdFJOUwAQIEBQYHCAn6CvsL/P3+92YHeQAAAAeklEQVQY05XQwQ7DIAwD0DShOKMB/P9fu0OpYBqX+viEExSRdzkwsqKyRutR+YsqHxebeKgaTa+iaSI4MzHul5eeK25month8da80N3Tje3BoHvHph4Dq51MFsUyzSr+vkRyqbOJNC71LfaoD0aM7QYAyCIZAGBvbv4F0BcHuwooJ9IAAAAASUVORK5CYII='

        case 'Winkelgebieden':
            return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADpSURBVHgBrZPdDcIwDISPnwE6QkboI48doRvQDSgTABuUCYAJChMUNmADugFsUBzVCFTixIic9KmRfHEcNwYia8LfnJgRCdEighriTtzwp0Yf64QTXvGbHsQSfLPpILAlVsQGOhliThwgtMpW2REFdKqhaNMefU9DyvnwNGQ0bMwCPlvZDko18FdZ8KFmGJgKG+zPqT1JU/a02oQ5my9C/ERUUMpA10OnXBWWxJnXGfyybzc4CDahHcNOgeZ5xZedlopJBM8C/Rs0UGiN95VK4cBX/DgMjh0bWmHt8kjxL2Xwz6iBYoaj6Am4NDsisiG3MgAAAABJRU5ErkJggg=='

        case 'Buitengebieden':
            return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADlSURBVHgBrZTBEYIwEEW/2EBKyNGjHcjRIx1AB2oF0IElQAdqBWoHdAAd6NEbboZ1JoMxCTFv5g9D8vPD7oQAkVl6eARpS1qRXqQn/mBHemgaSCUCKTkk08YKHtsjgO7HwopDZ3HEWN7aMCcxs/Sav6CyeAqMFdRwUPDuEm4kb5zqg8nEpEq8kHq46dmb2wIFAhpuC2wxHhMBPzaku82gglSzT7D3UbCng0e/JRsHfpqk5q6msIUlONUW5Oxt+L1lBdPA48wpEkQmeqCphym+y/sco+ldeCCdXYFqcQY/bvD7q8J5A88bNDX0FnuPAAAAAElFTkSuQmCC'

        case 'Vakantieparken':
            return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAClSURBVHgB3ZONCYNADIUfpQN0hBuhI1wnakewEzhC3aDtBOoGbqAb6AaawyeIvxFF0A8+CJcQ7nIEOAuGbsJTzOkLK7iJvliykcfYZ24RRkzpfeTcQEnzxO/ITYwYQDkCV1BCNytPUxuIH+jp1V+wMbs3tKjn1NauadjwoLNcoSNS1h3gU4aebMWQ8dCmuF0uGLu1/E01dMmsc/Zvxe9OLhYTHIoK5QAjVeXyGGgAAAAASUVORK5CYII='

        case '(Jacht)havens':
            return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFESURBVHgBtVSxUQMxEFwbAkKFZCgkdAd8B7gDng4ckmEqMBkhTwd0gDuwqeCfCmQyMnPnX4F8fj0w2Duzo5fubnV3f6MB+uHIFA3+gUK4Niz6AobYM47N3gtvk73riJkK62R/h6QNA+NcCS+Ec/wOBX2vkckQ1uEHVPbg4D200B6Wwkvun9BW0OQChhmRifBFGND+pFfhG79r2krhGXoyic5rrvfonjk9q4zvBGYiRjTmRHIYUXxn4FU9/FEsYkzBDWIPV8IljRHaAt8hoGdTIzjv8Nv0oWa2M3z3xxmx2LsZz3RfInOzOgY6jbnGpscLl4ktMMYjg2eW40zmOiYLY/Nof+Ij9gCXMxwlDqdcdRSumO052gfkhCyED8Ib+r2bCz6icIntR1RL1FIrcx4S28LYVOPr+Uqf+hWZwnNtsFv6VtwnNApbLYzknfEAAAAASUVORK5CYII='

        default:
            return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    }
}
