import { Text, View } from '@react-pdf/renderer'
import parsePrice from 'parse-price'
import { formateEuroString, getTotalCostOther } from '../../../../helpers/functions'

export default function FinancialOtherCosts({ formData }) {

    const getCostsData = () => {
        return formData.filter(field => field.field_key === '4-3-overige-kostenposten')?.[0]?.field_value
    }

    return (
        <View
            wrap={false}
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '15px'
            }}
        >

            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '5px'
                }}
            >
                <Text
                    style={{
                        fontSize: '11px',
                        lineHeight: '1.4',
                        color: '#0b8bcc',
                        fontFamily: 'ArchivoBold',
                        marginBottom: '2px'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    Overige kostenposten
                </Text>

            </View>


            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '5px',
                    backgroundColor: '#e4f5fd',
                    borderRadius: '3px'
                }}>

                <Text
                    style={{
                        width: '83%',
                        fontSize: '11px',
                        color: '#000',
                        fontFamily: 'ArchivoBold',
                    }}
                    hyphenationCallback={e => [e]}
                >
                    Kostenpost
                </Text>

                <Text
                    style={{
                        width: '17%',
                        fontSize: '11px',
                        color: '#000',
                        fontFamily: 'ArchivoBold',
                    }}
                    hyphenationCallback={e => [e]}
                >
                    Bedrag
                </Text>

            </View>


            {getCostsData().map((costRow, costRowIndex) => {
                return (
                    <View
                        key={costRowIndex}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '5px',
                            borderBottom: costRowIndex !== getCostsData().length - 1 ? '1px solid #e6e7e8' : ''
                        }}>

                        <Text
                            style={{
                                width: '83%',
                                fontSize: '11px',
                                color: '#000',
                                fontFamily: 'Archivo',
                            }}
                            hyphenationCallback={e => [e]}
                        >
                            {costRow[0]}
                        </Text>

                        <Text
                            style={{
                                width: '17%',
                                fontSize: '11px',
                                color: '#000',
                                fontFamily: 'Archivo',
                            }}
                            hyphenationCallback={e => [e]}
                        >
                            {formateEuroString(parsePrice(costRow[1]))}
                        </Text>

                    </View>
                )
            })}

            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '5px',
                    backgroundColor: '#e4f5fd',
                    borderRadius: '3px',
                }}
            >
                <Text
                    style={{
                        width: '83%',
                        fontSize: '11px',
                        color: '#000',
                        fontFamily: 'ArchivoBold',
                    }}
                    hyphenationCallback={e => [e]}
                >
                    Totaal Overige kostenposten
                </Text>

                <Text
                    style={{
                        width: '17%',
                        fontSize: '11px',
                        color: '#000',
                        fontFamily: 'ArchivoBold',
                        marginLeft: 'auto'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    {getTotalCostOther(formData)}
                </Text>
            </View>

        </View>
    )
}
