import { Page, Text, Image, View } from '@react-pdf/renderer'
import Footer from '../Parts/Footer'
import TopMark from '../Parts/TopMark'
import PageNumberGetter from '../Parts/PageNumberGetter'

export default function SignPage({ formData, getSignPageNumber }) {

    const getManagerName = () => {
        return formData.filter(field => field.field_key === '6-2-sign-person')?.[0]?.field_value
    }

    const getSignatureData = () => {
        const signature = formData.filter(field => field.field_key === '6-1-signature')?.[0]?.field_value

        if (signature && signature !== '') {
            return signature
        }

        return 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>'
    }

    const getCurrentDate = () => {
        return new Date().toISOString().replace(/T.*/, '').split('-').reverse().join('-')
    }

    const getIntroText = () => {
        return formData.filter(field => field.field_key === '5-1-voorwoord')?.[0]?.field_value
    }

    return (
        <Page
            style={{ padding: '70px', position: 'relative' }}
            size="A4"
        >
            <TopMark />
            <PageNumberGetter getterFunction={getSignPageNumber} />

            <Text
                style={{
                    fontFamily: 'Roboto',
                    fontSize: '24px',
                    textDecoration: 'underline',
                    color: '#000',
                }}
                hyphenationCallback={e => [e]}
            >
                Voorwoord
            </Text>

            <Text
                style={{
                    fontSize: '12px',
                    lineHeight: '1.4',
                    color: '#000',
                    marginTop: '35px',
                    fontFamily: 'Archivo',
                    marginBottom: '30px'
                }}
                hyphenationCallback={e => [e]}
            >
                {getIntroText()}
            </Text>


            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 'auto',
                    marginBottom: '15px'
                }}
                wrap={false}
            >
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%'
                    }}
                >
                    <Text
                        style={{
                            fontSize: '13px',
                            lineHeight: '1.4',
                            color: '#0b8bcc',
                            fontFamily: 'ArchivoBold'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Handtekening voorzitter bestuur/stuurgroep
                    </Text>


                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '20px'
                        }}
                    >

                        <Text
                            style={{
                                fontSize: '12px',
                                lineHeight: '1.4',
                                color: '#000',
                                fontFamily: 'ArchivoBold'
                            }}
                            hyphenationCallback={e => [e]}
                        >
                            {'Naam:'}
                        </Text>
                    </View>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '8px'
                        }}
                    >

                        <Text
                            style={{
                                fontSize: '12px',
                                lineHeight: '1.4',
                                color: '#000',
                                fontFamily: 'ArchivoBold',
                                width: '55px'
                            }}
                            hyphenationCallback={e => [e]}
                        >
                            {'Datum:'}
                        </Text>
                    </View>

                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '8px'
                        }}
                    >

                        <Text
                            style={{
                                fontSize: '12px',
                                lineHeight: '1.4',
                                color: '#000',
                                fontWeight: '700',
                                fontFamily: 'ArchivoBold'
                            }}
                            hyphenationCallback={e => [e]}
                        >
                            Handtekening
                        </Text>
                    </View>

                </View>

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%'
                    }}
                >
                    <Text
                        style={{
                            fontSize: '13px',
                            lineHeight: '1.4',
                            color: '#0b8bcc',
                            fontFamily: 'ArchivoBold',
                            marginBottom: '18px'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Handtekening PVO-manager
                    </Text>


                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '20px'
                        }}
                    >

                        <Text
                            style={{
                                fontSize: '12px',
                                lineHeight: '1.4',
                                color: '#000',
                                fontFamily: 'ArchivoBold',
                                width: '55px'
                            }}
                            hyphenationCallback={e => [e]}
                        >
                            {'Naam:'}
                        </Text>
                        <Text
                            style={{
                                fontSize: '12px',
                                lineHeight: '1.4',
                                color: '#000',
                                fontFamily: 'Archivo'
                            }}
                            hyphenationCallback={e => [e]}
                        >
                            {getManagerName()}
                        </Text>
                    </View>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '8px'
                        }}
                    >

                        <Text
                            style={{
                                fontSize: '12px',
                                lineHeight: '1.4',
                                color: '#000',
                                fontFamily: 'ArchivoBold',
                                width: '55px'
                            }}
                            hyphenationCallback={e => [e]}
                        >
                            {'Datum:'}
                        </Text>
                        <Text
                            style={{
                                fontSize: '12px',
                                lineHeight: '1.4',
                                color: '#000',
                                fontFamily: 'Archivo'
                            }}
                            hyphenationCallback={e => [e]}
                        >
                            {getCurrentDate()}
                        </Text>
                    </View>

                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '8px'
                        }}
                    >

                        <Text
                            style={{
                                fontSize: '12px',
                                lineHeight: '1.4',
                                color: '#000',
                                fontWeight: '700',
                                fontFamily: 'ArchivoBold'
                            }}
                            hyphenationCallback={e => [e]}
                        >
                            Handtekening
                        </Text>
                    </View>

                    <Image
                        style={{
                            height: '80px',
                            width: '160px',
                            marginTop: '14px'
                        }}
                        src={getSignatureData()}
                    />

                </View>
            </View>


            <Footer />
        </Page>
    )
}
