import { useContext, useState } from "react"
import { GlobalContext } from "../../../context/GlobalState"
import { hasFieldWarning, constructInputWarning, escapeStr } from '../../../helpers/functions'
import { checkInputsValidation } from '../../../helpers/form-validator'
import useSubtitleData from "../../../helpers/hooks/useSubtitleData"
import useUserRole from "../../../helpers/hooks/useUserRole"

export default function FieldRepeaterSingleItems({ field }) {

    const {
        field_title,
        default_inputs,
        popup_title,
        popup_input_label,
        popup_input_placeholder,
        popup_button_label,
        title_blue,
        field_key
    } = field

    const { globalState, setFormData, setInputWarnings } = useContext(GlobalContext)
    const { formData, inputWarnings, currentStep, currentSection } = globalState

    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [isRemovePopupOpen, setRemoveIsPopupOpen] = useState(false)
    const [positionToRemove, setPositionToRemove] = useState(false)
    const [popupInputValue, setPopupInputValue] = useState('')
    const [popupInputError, setPopupInputError] = useState(false)

    const currentFieldIndex = formData.map(i => i.field_key).indexOf(field_key)

    const { getSubtitleData } = useSubtitleData()

    const { userCanEdit } = useUserRole()


    const submitInputValueCustom = () => {

        if (popupInputValue === '') {
            return setPopupInputError('vul a.u.b. de gegevens in')
        }

        if (default_inputs && default_inputs.filter(input => input?.input_label && input.input_label.toLowerCase() === popupInputValue.toLowerCase()).length > 0) {
            return setPopupInputError('Deze naam bestaat al, voer a.u.b. een andere naam in')
        }


        const customInputs = getCustomInputList()
        if (customInputs && customInputs.filter(input => input?.label && input.label.toLowerCase() === popupInputValue.toLowerCase()).length > 0) {
            return setPopupInputError('Deze naam bestaat al, voer a.u.b. een andere naam in')
        }


        const currentFormData = formData
        let customInputValues = currentFormData?.[currentFieldIndex]?.['field_value_custom']

        if (Array.isArray(customInputValues)) {

            customInputValues.push({
                'label': popupInputValue,
                'value': ''
            })

            currentFormData[currentFieldIndex]['field_value_custom'] = customInputValues
            setFormData(currentFormData)
        }

        setIsPopupOpen(false)
        document.body.classList.remove("no-scroll")
        setPopupInputValue('')

    }



    const setInputValueDefault = (index, value) => {
        const currentFormData = formData
        let inputValues = currentFormData?.[currentFieldIndex]?.['field_value']

        if (Array.isArray(inputValues)) {

            inputValues[index] = value
            currentFormData[currentFieldIndex]['field_value'] = inputValues
            setFormData(currentFormData)

        }
    }

    const setNVTValue = (index) => {
        const currentFormData = formData
        let nvtValues = currentFormData?.[currentFieldIndex]?.['field_value_nvt']

        if (Array.isArray(nvtValues)) {

            if (nvtValues.includes(index)) {
                nvtValues = nvtValues.filter(e => e !== index)
            } else {
                nvtValues.push(index)
            }

            currentFormData[currentFieldIndex]['field_value_nvt'] = nvtValues

            return setFormData(currentFormData)
        }
    }


    const setCustomInputValue = (index, value) => {
        const currentFormData = formData
        let customInputValues = currentFormData?.[currentFieldIndex]?.['field_value_custom']

        if (Array.isArray(customInputValues) && customInputValues.length >= index) {
            customInputValues[index]['value'] = value
            currentFormData[currentFieldIndex]['field_value_custom'] = customInputValues
        }


        return setFormData(currentFormData)

    }

    const getNVTValue = (index) => {
        let nvtValues = formData?.[currentFieldIndex]?.['field_value_nvt']

        if (Array.isArray(nvtValues)) {

            return nvtValues.includes(index)

        }

        return null
    }

    const getInputValueDefault = (index) => {
        let inputValues = formData?.[currentFieldIndex]?.['field_value']

        if (Array.isArray(inputValues)) {
            return inputValues[index]
        }

        return null
    }


    const getCustomInputList = () => {

        let customInputs = formData?.[currentFieldIndex]?.['field_value_custom']

        if (Array.isArray(customInputs)) {
            return customInputs
        }

        return null

    }


    const getCustomInputValue = (index) => {
        let customInputValues = formData?.[currentFieldIndex]?.['field_value_custom']

        if (Array.isArray(customInputValues) && customInputValues.length >= index) {
            return customInputValues[index]?.['value']
        }

        return ''
    }

    const hasInputWarning = (index, type) => {
        return inputWarnings.filter(i => i.includes(`${field_key}:${index}:${type}`)).length > 0
    }


    const removeItem = () => {
        const currentFormData = formData
        let customInputValues = currentFormData?.[currentFieldIndex]?.['field_value_custom']

        if (Array.isArray(customInputValues) && customInputValues.length >= positionToRemove) {
            customInputValues.splice(positionToRemove, 1)
            currentFormData[currentFieldIndex]['field_value_custom'] = customInputValues
        }

        return setFormData(currentFormData)
    }

    return (
        <div className="FieldRepeaterSingleItems form-field">
            {field_title ?
                <div className={`field-title ${title_blue ? 'title-blue' : ''}`}>{field_title}</div>
                : null}

            {getSubtitleData(field_key) ?
                <div className="field-subtitle">{getSubtitleData(field_key)}</div>
                : null}


            {hasFieldWarning(inputWarnings, field_key) ?

                <div className="field-warning">
                    Vul a.u.b alle verplichte velden in
                </div>

                : null}

            <div className="form-inputs">

                {default_inputs ? default_inputs.map((input, i) => {

                    const { input_icon, input_label, input_placeholder, nvt } = input

                    const inputWarningKey = constructInputWarning(currentSection, currentStep, field_key, i, 'defaultValue')

                    return (
                        <div key={i} className="input-item">
                            <div className="input-label">
                                <div className={`icon icon-${input_icon}`}></div>
                                <div className="label-text">{input_label}</div>

                                {nvt ?
                                    <div
                                        className={`nvt ${getNVTValue(i) ? 'nvt-true' : ''} ${!userCanEdit() ? 'disabled' : ''}`}
                                        onClick={() => {
                                            if (!userCanEdit()) {
                                                return false;
                                            }

                                            setNVTValue(i)
                                            checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings, inputWarningKey)
                                        }}

                                    ></div>
                                    : null}

                            </div>

                            <input
                                disabled={getNVTValue(i) || !userCanEdit()}
                                className={`field-input ${hasInputWarning(i, 'defaultValue') ? 'warning' : ''}`}
                                placeholder={input_placeholder}
                                value={getNVTValue(i) ? '' : getInputValueDefault(i)}
                                onChange={e => {
                                    setInputValueDefault(i, escapeStr(e.target.value))
                                    checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings, inputWarningKey)
                                }}
                            />
                        </div>
                    )
                }) : null}


                {(() => {
                    const customInputs = getCustomInputList()

                    return (
                        customInputs && customInputs.length > 0 ?

                            customInputs.map((input, i) => {

                                const inputWarningKey = constructInputWarning(currentSection, currentStep, field_key, input.label, 'customValue')

                                return (
                                    <div key={i} className="input-item">
                                        <div className="input-label">
                                            <div className="label-text">{input?.label}</div>
                                        </div>

                                        <div className="input-item-main">
                                            <input
                                                className={`field-input ${hasInputWarning(input?.label, 'customValue') ? 'warning' : ''}`}
                                                placeholder="Vul uw gegevens in"
                                                value={getCustomInputValue(i)}
                                                onChange={e => {
                                                    if (!userCanEdit()) {
                                                        return
                                                    }
                                                    setCustomInputValue(i, escapeStr(e.target.value))
                                                    checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings, inputWarningKey)
                                                }}
                                                disabled={!userCanEdit()}
                                            />

                                            {userCanEdit() ?
                                                <div
                                                    className="remove-row"
                                                    onClick={() => {
                                                        setRemoveIsPopupOpen(true)
                                                        setPositionToRemove(i)
                                                        document.body.classList.add("no-scroll")
                                                    }}
                                                ></div>
                                                : null}

                                        </div>
                                    </div>
                                )


                            }) : null
                    )

                })()}


            </div>

            {userCanEdit() ?
                <div
                    className="btn-main blue form-add-input plus"
                    onClick={() => {
                        setIsPopupOpen(true)
                        document.body.classList.add("no-scroll")
                        setPopupInputValue('')
                        setPopupInputError(false)
                    }}
                >Rij toevoegen</div>
                : null}


            {isPopupOpen ?
                <div className="popup-main">
                    <form className="popup-form">
                        <div
                            className="close-popup"
                            onClick={() => {
                                setIsPopupOpen(false)
                                document.body.classList.remove("no-scroll")
                            }}

                        ></div>
                        <div className="popup-title">{popup_title}</div>

                        <div className="input-item popup-input">
                            <div className="input-label">
                                <div className="label-text">{popup_input_label}</div>
                            </div>

                            <input
                                type="text"
                                required={true}
                                className="field-input"
                                placeholder={popup_input_placeholder}
                                value={popupInputValue}
                                onChange={e => {
                                    setPopupInputValue(escapeStr(e.target.value))
                                    setPopupInputError(false)
                                }}
                            />

                            {popupInputError ?
                                <div className="input-error">{popupInputError}</div>
                                : null}
                        </div>
                        <button
                            className="btn-main popup-btn"
                            onClick={e => {
                                e.preventDefault()
                                submitInputValueCustom()
                            }}
                        >{popup_button_label}</button>
                    </form>
                </div>
                : null}


            {isRemovePopupOpen ?
                <div className="popup-main">
                    <form className="popup-form">

                        <div className="popup-title">
                            Weet je zeker dat je Raad van toezicht wilt verwijderen?
                        </div>

                        <div className="popup-subtitle">
                            Dit zal de ingevulde informatie verwijderen. Je kunt deze actie niet
                            ongedaan maken.
                        </div>


                        <div className="button-row right">
                            <button
                                className="btn-sec popup-btn"
                                onClick={e => {
                                    e.preventDefault()
                                    setRemoveIsPopupOpen(false)
                                    setPositionToRemove(null)
                                    document.body.classList.remove("no-scroll")
                                }}
                            >Annuleren</button>
                            <button
                                className="btn-main popup-btn"
                                onClick={e => {
                                    e.preventDefault()
                                    removeItem()
                                    setPositionToRemove(null)
                                    setRemoveIsPopupOpen(false)
                                    document.body.classList.remove("no-scroll")
                                }}
                            >Verwijderen</button>
                        </div>

                    </form>
                </div>
                : null}
        </div>
    )
}
