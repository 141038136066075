import { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';

const useSaveDownloadDate = () => {
    const { setLastDownloaded } = useContext(GlobalContext)

    const saveDownloadDate = async () => {

        const apiEndpoint = document.querySelector('#apiEndpoint')?.getAttribute('data-url');

        if (!apiEndpoint) {
            return null
        }

        const today = new Date().toLocaleDateString().replaceAll('/', '-');

        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'X-WP-Nonce': document.querySelector('#toolNonce')?.getAttribute('data-key')
                },
                body: JSON.stringify({
                    pageId: document.querySelector('#pageId')?.getAttribute('data-id'),
                    lastDownloaded: today,
                    nonce: document.querySelector('#toolNonce')?.getAttribute('data-key'),
                })
            });

            if (response.ok) {
                setLastDownloaded(today)
            }


        } catch (error) {
            console.log(error);
        }

    };

    return { saveDownloadDate };
};

export default useSaveDownloadDate;