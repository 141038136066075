import { Text, View } from '@react-pdf/renderer'
import { getTotalIncome, getTotalCosts, getTotalFunctionHoursUsed, getTotalFunctionHoursAvailable } from '../../../../helpers/functions'

export default function FinancialTotalOverview({ formData }) {

    const availableFunctions = getTotalFunctionHoursAvailable(formData)?.list
    const availableFunctionsTotal = getTotalFunctionHoursAvailable(formData)?.total

    const usedFunctions = getTotalFunctionHoursUsed(formData)?.list
    const usedFunctionsTotal = getTotalFunctionHoursUsed(formData)?.total
    return (
        <View
            wrap={false}
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '15px',
                marginTop: '25px'
            }}
        >

            <Text
                style={{
                    fontSize: '13px',
                    lineHeight: '1.4',
                    color: '#0b8bcc',
                    fontFamily: 'ArchivoBold'
                }}
                hyphenationCallback={e => [e]}
            >
                4.3 Totaal
            </Text>

            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <View
                    wrap={false}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px',
                        marginTop: '5px'
                    }}
                >
                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Totaal inkomsten
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                            marginLeft: 'auto'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {getTotalIncome(formData)}
                    </Text>
                </View>
                <View
                    wrap={false}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px',
                        marginTop: '5px'
                    }}
                >
                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Totaal uitgaven
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                            marginLeft: 'auto'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {getTotalCosts(formData)}
                    </Text>
                </View>
            </View>


            {/* available function hours */}
            <View
                wrap={false}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '20px'
                }}
            >

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '5px'
                    }}
                >
                    <Text
                        style={{
                            fontSize: '11px',
                            lineHeight: '1.4',
                            color: '#0b8bcc',
                            fontFamily: 'ArchivoBold',
                            marginBottom: '2px'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Totaal beschikbare uren per functie
                    </Text>

                </View>


                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px'
                    }}>

                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Functie
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Begrote uren
                    </Text>

                </View>

                {availableFunctions.map((row, rowIndex) => {
                    return (
                        <View
                            key={rowIndex}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '5px',
                                borderBottom: rowIndex !== availableFunctions.length - 1 ? '1px solid #e6e7e8' : ''
                            }}>

                            <Text
                                style={{
                                    width: '83%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {row['name']}
                            </Text>

                            <Text
                                style={{
                                    width: '17%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {row['hours']}
                            </Text>

                        </View>
                    )
                })}

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px',
                    }}
                >
                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                            backgroundColor: '#e4f5fd',
                            borderRadius: '3px'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Totaal beschikbare uren
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                            marginLeft: 'auto'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {`${availableFunctionsTotal} uren`}
                    </Text>
                </View>
            </View>



            {/* used function hours */}
            <View
                wrap={false}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '20px'
                }}
            >

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '5px'
                    }}
                >
                    <Text
                        style={{
                            fontSize: '11px',
                            lineHeight: '1.4',
                            color: '#0b8bcc',
                            fontFamily: 'ArchivoBold',
                            marginBottom: '2px'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Totaal inzet uren per functie
                    </Text>

                </View>


                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px',
                    }}>

                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Functie
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Begrote uren
                    </Text>

                </View>

                {usedFunctions.map((row, rowIndex) => {
                    return (
                        <View
                            key={rowIndex}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '5px',
                                borderBottom: rowIndex !== usedFunctions.length - 1 ? '1px solid #e6e7e8' : ''
                            }}>

                            <Text
                                style={{
                                    width: '83%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {row['name']}
                            </Text>

                            <Text
                                style={{
                                    width: '17%',
                                    fontSize: '11px',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {row['hours']}
                            </Text>

                        </View>
                    )
                })}

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px',
                    }}
                >
                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Totaal beschikbare uren
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                            marginLeft: 'auto'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {`${usedFunctionsTotal} uren`}
                    </Text>
                </View>
            </View>

        </View>
    )
}
