import { useContext } from "react"
import { GlobalContext } from "../../context/GlobalState"
import FormFields from "./FormFields"


export default function FieldProjectStepView({ projectSteps }) {
    const { globalState } = useContext(GlobalContext)
    const { formData, currentProjectStep, currentProjectID } = globalState
    const totalSteps = projectSteps.length

    const getProjectName = () => {
        const projectName = formData.filter(field => field.field_key.includes(`1-projectnaam-${currentProjectID}`))[0]?.field_value

        return projectName ? projectName : 'Nieuw'
    }


    return (
        <div className="FormStepView FieldProjectStepView">
            <div className="step-view-main">
                <div className="step-title">
                    {`Projecten: ${getProjectName()}  (${currentProjectStep + 1}/${totalSteps})`}
                </div>

                <FormFields fields={projectSteps[currentProjectStep]?.fields} />
            </div>

            <div className="step-bottom-title">
                {`Projecten: ${getProjectName()} (${currentProjectStep + 1}/${totalSteps})`}
            </div>
        </div>
    )
}
