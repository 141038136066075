import { useContext } from "react"
import { GlobalContext } from "../../../context/GlobalState"
import { getTotalCostProjects, getTotalCostCommunication, getTotalCostSafety } from '../../../helpers/functions'


export default function FieldCostsSummary() {

    const { globalState } = useContext(GlobalContext)
    const { formData } = globalState
    return (
        <div className='FieldCostsSummary form-field'>

            <div className="form-text-row">
                <div className="row-label">
                    Totaal kosten projecten
                </div>
                <div className="row-value">
                    {getTotalCostProjects(formData)}
                </div>
            </div>

            <div className="form-text-row">
                <div className="row-label">
                    Totaal kosten Algemene communicatie
                </div>
                <div className="row-value">
                    {getTotalCostCommunication(formData)}
                </div>
            </div>

            <div className="form-text-row">
                <div className="row-label">
                    Totaal kosten Week van de Veiligheid
                </div>
                <div className="row-value">
                    {getTotalCostSafety(formData)}
                </div>
            </div>

        </div>
    )
}