import { Text, View } from '@react-pdf/renderer'
import { getTotalCostProjects, getTotalCostCommunication, getTotalCostSafety } from '../../../../helpers/functions'

export default function FinancialTotalProjects({ formData }) {


    return (
        <>
            <View
                wrap={false}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '5px'
                }}
            >
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px',
                    }}
                >
                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Totaal kosten projecten
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                            marginLeft: 'auto'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {getTotalCostProjects(formData)}
                    </Text>
                </View>
            </View>
            <View
                wrap={false}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '5px'
                }}
            >
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px',
                    }}
                >
                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Totaal kosten Algemene communicatie
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                            marginLeft: 'auto'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {getTotalCostCommunication(formData)}
                    </Text>
                </View>
            </View>
            <View
                wrap={false}
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px',
                    }}
                >
                    <Text
                        style={{
                            width: '83%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Totaal kosten Week van de Veiligheid
                    </Text>

                    <Text
                        style={{
                            width: '17%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                            marginLeft: 'auto'
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        {getTotalCostSafety(formData)}
                    </Text>
                </View>
            </View>
        </>
    )
}
