import { useContext } from "react"
import { GlobalContext } from "../../../context/GlobalState"
import formConfig from '../../../helpers/form-config.json'
import { storeVisitedSections } from '../../../helpers/functions'
import { checkInputsValidation } from '../../../helpers/form-validator'
import useSaveData from "../../../helpers/hooks/useSaveData"


export default function FormNavNext() {

    const {
        globalState,
        setCurrentSection,
        setCurrentStep,
        setVisitedSections,
        setInputWarnings,
        setCurrentProjectStep,
        setCurrentProjectID
    } = useContext(GlobalContext)

    const {
        currentSection,
        currentStep,
        visitedSections,
        currentProjectID,
        currentProjectStep
    } = globalState


    const { saveData } = useSaveData()


    const nextAvailable = formConfig.sections[currentSection]?.steps[currentStep + 1] || formConfig.sections[currentSection + 1];

    const maxProjectSteps = formConfig.sections[currentSection]?.steps[currentStep]?.fields.filter(field => field.field_key === '3-1-projecten')[0]?.inner_steps?.length


    const setNextView = () => {
        const maxSections = formConfig.sections.length
        const maxSteps = formConfig.sections[currentSection]?.steps.length

        if (currentStep >= maxSteps && currentSection >= maxSections) {
            return
        }

        if (currentStep + 1 < maxSteps) {
            checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings)
            storeVisitedSections(visitedSections, setVisitedSections, currentSection, currentStep)
            setCurrentStep(currentStep + 1)
            return
        }


        if (currentSection + 1 < maxSections) {
            checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings)
            storeVisitedSections(visitedSections, setVisitedSections, currentSection, currentStep)
            setCurrentSection(currentSection + 1)
            setCurrentStep(0)
            return
        }
    }


    const setNextProjectStep = () => {
        checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings)

        if (currentProjectStep + 1 < maxProjectSteps) {
            setCurrentProjectStep(currentProjectStep + 1)
        } else {
            setCurrentProjectID(false)
            setCurrentProjectStep(0)
        }
    }



    if (currentProjectID) {
        return (
            <div
                className="btn-main arrow-forward"
                onClick={() => {
                    saveData()
                    setNextProjectStep()
                }}
            >Volgende</div>
        )
    }

    return (
        <div
            className={`btn-main arrow-forward ${!nextAvailable ? 'disabled' : ''}`}
            onClick={() => {
                saveData()

                if (nextAvailable) {
                    setNextView()
                }

            }}
        >Volgende</div>
    )
}
