import { useContext, useState } from "react"
import { GlobalContext } from "../../../context/GlobalState"
import { getTotalIncome, getTotalCosts, getTotalFunctionHoursAvailable, getTotalFunctionHoursUsed } from '../../../helpers/functions'
import parsePrice from 'parse-price'


export default function FieldBalanceOverview() {

    const { globalState } = useContext(GlobalContext)
    const { formData } = globalState

    const [isHoursAvailableOpen, setIsHoursAvailableOpen] = useState(false)
    const [isHoursUsedOpen, setIsHoursUsedOpen] = useState(false)

    const availableFunctions = getTotalFunctionHoursAvailable(formData)?.list
    const availableFunctionsTotal = getTotalFunctionHoursAvailable(formData)?.total

    const usedFunctions = getTotalFunctionHoursUsed(formData)?.list
    const usedFunctionsTotal = getTotalFunctionHoursUsed(formData)?.total

    const checkBalanceWarning = () => {

        const totalIncome = parsePrice(getTotalIncome(formData))
        const totalCosts = parsePrice(getTotalCosts(formData))

        return totalCosts > totalIncome
    }
    
    return (
        <div className='FieldBalanceOverview form-field'>

            <div className="field-heading">4.3 Totaal</div>

            <div className="form-text-row">
                <div className="row-label">
                    Totaal inkomsten
                </div>
                <div className="row-value">
                    {getTotalIncome(formData)}
                </div>
            </div>

            {checkBalanceWarning() ?
                <div className="field-warning">
                    Waarschuwing: De uitgaven kunnen niet hoger zijn dan de inkomsten.
                </div>
                : null}


            <div className="form-text-row">
                <div className="row-label">
                    Totaal uitgaven
                </div>
                <div className="row-value">
                    {getTotalCosts(formData)}
                </div>
            </div>

            <div
                className={`form-text-row openable ${isHoursAvailableOpen ? 'open' : ''}`}
                onClick={() => setIsHoursAvailableOpen(!isHoursAvailableOpen)}
            >
                <div className="row-label">
                    Totaal beschikbare uren per functie
                </div>
            </div>

            {isHoursAvailableOpen ?

                <>
                    <div className="form-inputs">
                        {availableFunctions.map((row, i) => {

                            return (

                                <div key={i} className="inputs-row">

                                    <div className="row-number">{i + 1}</div>

                                    <div className="input-item spread">

                                        {i === 0 ?
                                            <div className="input-label">
                                                <div className="label-text">Functie</div>
                                            </div>
                                            : null}

                                        <input
                                            type="text"
                                            className="field-input"
                                            value={row['name']}
                                            disabled={true}
                                        />
                                    </div>

                                    <div className="input-item spread">

                                        {i === 0 ?
                                            <div className="input-label">
                                                <div className="label-text">Begrote uren</div>
                                            </div>
                                            : null}

                                        <input
                                            type="text"
                                            className="field-input"
                                            value={row['hours']}
                                            disabled={true}
                                        />
                                    </div>


                                </div>
                            )
                        })}
                    </div>

                    <div className="form-text-row">
                        <div className="row-label">
                            Totaal beschikbare uren
                        </div>
                        <div className="row-value">
                            {`${availableFunctionsTotal} uren`}
                        </div>
                    </div>
                </>

                : null}



            <div
                className={`form-text-row openable ${isHoursUsedOpen ? 'open' : ''}`}
                onClick={() => setIsHoursUsedOpen(!isHoursUsedOpen)}
            >
                <div className="row-label">
                    Totaal inzet uren per functie
                </div>
            </div>


            {isHoursUsedOpen ?

                <>
                    <div className="form-inputs">
                        {usedFunctions.map((row, i) => {

                            return (

                                <div key={i} className="inputs-row">

                                    <div className="row-number">{i + 1}</div>

                                    <div className="input-item spread">

                                        {i === 0 ?
                                            <div className="input-label">
                                                <div className="label-text">Functie</div>
                                            </div>
                                            : null}

                                        <input
                                            type="text"
                                            className="field-input"
                                            value={row['name']}
                                            disabled={true}
                                        />
                                    </div>

                                    <div className="input-item spread">

                                        {i === 0 ?
                                            <div className="input-label">
                                                <div className="label-text">Begrote uren</div>
                                            </div>
                                            : null}

                                        <input
                                            type="text"
                                            className="field-input"
                                            value={row['hours']}
                                            disabled={true}
                                        />
                                    </div>


                                </div>
                            )
                        })}
                    </div>

                    <div className="form-text-row">
                        <div className="row-label">
                            Totaal beschikbare uren
                        </div>
                        <div className="row-value">
                            {`${usedFunctionsTotal} uren`}
                        </div>
                    </div>
                </>

                : null}


        </div>
    )
}
