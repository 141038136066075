import { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';

const useSubtitleData = () => {
    const { globalState } = useContext(GlobalContext)
    const { inputInstructions } = globalState

    const getSubtitleData = field_key => {
        if (!field_key || !inputInstructions) {
            return false
        }

        if (inputInstructions?.[field_key] && inputInstructions?.[field_key] !== '') {
            return inputInstructions?.[field_key]
        }

        return false
    };

    return { getSubtitleData };
};

export default useSubtitleData;