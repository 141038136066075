import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class Autocomplete extends Component {
    static propTypes = {
        suggestions: PropTypes.instanceOf(Array)
    };

    static defaultProps = {
        suggestions: []
    };

    constructor(props) {
        super(props);

        this.state = {
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            userInput: "",
            opacity: false
        };
    }

    onChange = e => {
        const { suggestions, setInputValue } = this.props;
        const userInput = e.currentTarget.value;

        // Filter our suggestions that don't contain the user's input
        const filteredSuggestions = suggestions.filter(
            suggestion =>
                suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        this.setState({
            opacity: false,
            filteredSuggestions,
            showSuggestions: true,
        });

        setInputValue(e.target.value.replaceAll('"', "'"))
    };

    onClick = e => {
        const { setInputValue } = this.props
        setInputValue(e.target.innerText)
    };

    onFocus = e => {
        const { suggestions } = this.props;
        const userInput = e.currentTarget.value;

        // Filter our suggestions that don't contain the user's input
        const filteredSuggestions = suggestions.filter(
            suggestion =>
                suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        this.setState({
            opacity: false,
            filteredSuggestions,
            showSuggestions: true,
        });
    }



    render() {
        const {
            onChange,
            onClick,
            onFocus,
            onKeyDown,
            state: {
                filteredSuggestions,
                showSuggestions
            }
        } = this;

        const { value, skipLabels, disabled } = this.props

        let suggestionsListComponent;

        if (showSuggestions) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className={`suggestions ${skipLabels ? 'no-labels' : ''}`} style={{ "opacity": this.state.opacity ? '0' : '1' }}>
                        {filteredSuggestions.map(suggestion => {
                            let className;

                            return (
                                <li className={className} key={suggestion} onClick={onClick}>
                                    {suggestion}
                                </li>
                            );
                        })}
                    </ul>
                );
            }
        }

        return (
            <Fragment>
                <input
                    autoComplete="off"
                    className={this.props.className ? this.props.className : ''}
                    placeholder={this.props.placeholder ? this.props.placeholder : ''}
                    type="text"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onBlur={() => {
                        this.setState({
                            opacity: true,
                        });
                        setTimeout(() => {
                            this.setState({
                                showSuggestions: false,
                            });
                        }, 300)
                    }}
                    onFocus={onFocus}
                    value={value}
                    disabled={disabled}
                />
                {suggestionsListComponent}
            </Fragment>
        );
    }
}

export default Autocomplete;
