import { Page, Text } from '@react-pdf/renderer'
import Footer from '../../Parts/Footer'
import TopMark from '../../Parts/TopMark'
import PageNumberGetter from '../../Parts/PageNumberGetter'
import FinancialIncome from './FinancialIncome'
import FinancialCosts from './FinancialCosts'
import FinancialTotalProjects from './FinancialTotalProjects'
import FinancialOtherCosts from './FinancialOtherCosts'
import FinancialTotalOverview from './FinancialTotalOverview'

export default function FinancialOverview({ getFinancialOverviewPageNumber, formData }) {
    return (
        <Page
            style={{ padding: '70px 70px 70px 70px', position: 'relative' }}
            size="A4"
        >
            <TopMark />

            <PageNumberGetter getterFunction={getFinancialOverviewPageNumber} />

            <Text
                style={{
                    fontFamily: 'Roboto',
                    fontSize: '24px',
                    textDecoration: 'underline',
                    color: '#000'
                }}
                hyphenationCallback={e => [e]}
            >
                Begroting
            </Text>


            <FinancialIncome formData={formData} />
            <FinancialCosts formData={formData} />
            <FinancialTotalProjects formData={formData} />
            <FinancialOtherCosts formData={formData} />
            <FinancialTotalOverview formData={formData} />

            <Footer />
        </Page>
    )
}
