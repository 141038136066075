import { createContext, useReducer } from 'react'
import AppReducer from './AppReducer'


let initState = {
    formData: null,
    currentSection: 0,
    currentStep: 0,
    visitedSections: [],
    inputWarnings: [],
    currentProjectID: false,
    currentProjectStep: 0,
    isDataSaving: false,
    savingStatus: '',
    lastDownloaded: false,
    isMenuOpen: false,
    inputInstructions: null,
    userRole: '',
    lastUpdated: '',
    lastUpdatedTime: ''
}


// create context
export const GlobalContext = createContext(initState)


// create provider
export function GlobalProvider({ children }) {
    const [globalState, dispatch] = useReducer(AppReducer, initState)

    function setFormData(value) {
        dispatch({
            type: 'SET_FORM_DATA',
            payload: value
        })
    }

    function setCurrentSection(value) {
        dispatch({
            type: 'SET_CURRENT_SECTION',
            payload: value
        })
    }

    function setCurrentStep(value) {
        dispatch({
            type: 'SET_CURRENT_STEP',
            payload: value
        })
    }

    function setVisitedSections(value) {
        dispatch({
            type: 'SET_VISITED_SECTIONS',
            payload: value
        })
    }

    function setInputWarnings(value) {
        dispatch({
            type: 'SET_INPUT_WARNINGS',
            payload: value
        })
    }

    function setCurrentProjectID(value) {
        dispatch({
            type: 'SET_CURRENT_PROJECT_ID',
            payload: value
        })
    }

    function setCurrentProjectStep(value) {
        dispatch({
            type: 'SET_CURRENT_PROJECT_STEP',
            payload: value
        })
    }

    function setDataSaving(value) {
        dispatch({
            type: 'SET_DATA_SAVING',
            payload: value
        })
    }

    function setSavingStatus(value) {
        dispatch({
            type: 'SET_SAVING_STATUS',
            payload: value
        })
    }

    function setLastDownloaded(value) {
        dispatch({
            type: 'SET_LATEST_DOWNLOAD',
            payload: value
        })
    }

    function setMenuOpen(value) {
        dispatch({
            type: 'SET_MENU_OPEN',
            payload: value
        })
    }

    function setInputInstructions(value) {
        dispatch({
            type: 'SET_INPUT_INSTRUCTIONS',
            payload: value
        })
    }

    function setUserRole(value) {
        dispatch({
            type: 'SET_USER_ROLE',
            payload: value
        })
    }

    function setLastUpdated(value) {
        dispatch({
            type: 'SET_LAST_UPDATED',
            payload: value
        })
    }

    function setLastUpdatedTime(value) {
        dispatch({
            type: 'SET_LAST_UPDATED_TIME',
            payload: value
        })
    }

    return (
        <GlobalContext.Provider value={{
            globalState,
            setFormData,
            setCurrentSection,
            setCurrentStep,
            setVisitedSections,
            setInputWarnings,
            setCurrentProjectID,
            setCurrentProjectStep,
            setDataSaving,
            setSavingStatus,
            setLastDownloaded,
            setMenuOpen,
            setInputInstructions,
            setUserRole,
            setLastUpdated,
            setLastUpdatedTime
        }}>
            {children}
        </GlobalContext.Provider>
    )
}