import { Page, Text, View } from '@react-pdf/renderer'
import Footer from '../../Parts/Footer'
import TopMark from '../../Parts/TopMark'
import PageNumberGetter from '../../Parts/PageNumberGetter'

export default function Projects({ getProjectsPageNumber, formData }) {

    const getProjects = () => {
        const sortedProjects = []
        const projectFields = formData?.filter(field => field.field_key?.includes('3-1-projecten'))

        projectFields.forEach(field => {

            const projectID = field?.field_key?.split('-').slice(-1)[0]

            if (sortedProjects.filter(group => group.projectID === projectID).length === 0) {
                const initObj = {
                    "projectID": projectID
                }
                sortedProjects.push(initObj)
            }
        })


        return sortedProjects;

    }


    const getProjectName = projectID => {
        return formData?.filter(field => {
            return (
                field.field_key?.includes('3-1-projecten') &&
                field.field_key?.includes('projectnaam') &&
                field.field_key?.includes(projectID)
            )
        })?.[0]?.field_value
    }

    const getProjectTypes = projectID => {
        return formData?.filter(field => {
            return (
                field.field_key?.includes('3-1-projecten') &&
                field.field_key?.includes('projecttype') &&
                field.field_key?.includes(projectID)
            )
        })?.[0]?.field_value?.split(',')
    }

    const getProjectThemes = projectID => {
        return formData?.filter(field => {
            return (
                field.field_key?.includes('3-1-projecten') &&
                field.field_key?.includes('projectthemas') &&
                field.field_key?.includes(projectID)
            )
        })?.[0]?.field_value?.split(',')
    }


    const ProjectRow = ({ projectID }) => {
        return (
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '5px',
                    borderBottom: '1px solid #e6e7e8'
                }}
            >
                <Text
                    style={{
                        width: '40%',
                        fontSize: '11px',
                        lineHeight: '1.4',
                        color: '#000',
                        fontFamily: 'Archivo',
                    }}
                    hyphenationCallback={e => [e]}
                >
                    {getProjectName(projectID)}
                </Text>

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '30%',
                    }}
                >
                    {getProjectTypes(projectID).map((type, typeIndex) => {
                        return (
                            <Text
                                key={typeIndex}
                                style={{
                                    fontSize: '11px',
                                    lineHeight: '1.4',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {type.trim()}
                            </Text>
                        )
                    })}
                </View>

                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '30%',
                    }}
                >
                    {getProjectThemes(projectID).map((type, typeIndex) => {
                        return (
                            <Text
                                key={typeIndex}
                                style={{
                                    fontSize: '11px',
                                    lineHeight: '1.4',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                }}
                                hyphenationCallback={e => [e]}
                            >
                                {type.trim()}
                            </Text>
                        )
                    })}
                </View>
            </View>
        )
    }


    return (
        <Page
            style={{ padding: '70px 70px 70px 70px', position: 'relative' }}
            size="A4"
        >
            <TopMark />

            <PageNumberGetter getterFunction={getProjectsPageNumber} />

            <Text
                style={{
                    fontFamily: 'Roboto',
                    fontSize: '24px',
                    textDecoration: 'underline',
                    color: '#000'
                }}
                hyphenationCallback={e => [e]}
            >
                Projecten: overzicht
            </Text>


            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '15px'
                }}
            >


                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px',
                        marginTop: '3px'
                    }}
                >
                    <Text
                        style={{
                            width: '40%',
                            fontSize: '11px',
                            lineHeight: '1.4',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Projectnaam
                    </Text>

                    <Text
                        style={{
                            width: '30%',
                            fontSize: '11px',
                            lineHeight: '1.4',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Type
                    </Text>
                    <Text
                        style={{
                            width: '30%',
                            fontSize: '11px',
                            lineHeight: '1.4',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Thema
                    </Text>
                </View>

                {getProjects().map((project, i) => {
                    return (
                        <ProjectRow key={i} projectID={project['projectID']} />
                    )
                })}



            </View>


            <Footer />
        </Page>
    )
}
