import { Font } from '@react-pdf/renderer'

export default function Fonts() {

    Font.register({
        family: 'Archivo',
        fontWeight: 400,
        src: 'https://fonts.gstatic.com/s/archivo/v18/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTNDNZ9xdvBU7iVNRQ.woff',
    })

    Font.register({
        family: 'ArchivoMedium',
        fontWeight: 600,
        src: 'https://fonts.gstatic.com/s/archivo/v18/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTT6jRZ9xdvBU7iVNRQ.woff',
    })

    Font.register({
        family: 'ArchivoBold',
        fontWeight: 700,
        src: 'https://fonts.gstatic.com/s/archivo/v18/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTT0zRZ9xdvBU7iVNRQ.woff',
    })

    Font.register({
        family: 'Roboto',
        fontWeight: 700,
        src: 'https://fonts.cdnfonts.com/s/12166/RobotoCondensed-Bold.woff'
    })

    return null
}
