export default function Footer() {
    return (
        <div className='Footer'>
            <div className="container">
                {`Copyright © ${new Date().getFullYear()} Platform Veilig Ondernemen`}
                <span>{`V${process.env.REACT_APP_VERSION}`}</span>
            </div>
        </div>
    )
}
