import { useContext, useState, useEffect } from "react"
import { GlobalContext } from "../../../context/GlobalState"
import { constructInputWarning, hasFieldWarning } from '../../../helpers/functions'
import { checkInputsValidation } from '../../../helpers/form-validator'
import SignatureCanvas from 'react-signature-canvas'
import useSubtitleData from "../../../helpers/hooks/useSubtitleData"
import useUserRole from "../../../helpers/hooks/useUserRole"


export default function FieldSignature({ field }) {

    const {
        field_title,
        title_blue,
        field_key
    } = field

    const [sigCanvas, setSigCanvas] = useState(null)

    const { globalState, setFormData, setInputWarnings } = useContext(GlobalContext)
    const { formData, inputWarnings, currentSection, currentStep, currentProjectID } = globalState

    const { getSubtitleData } = useSubtitleData()
    const { userCanEdit } = useUserRole()

    const currentFieldIndex = formData.map(i => i.field_key).indexOf(`${field_key}${currentProjectID ? '-' + currentProjectID : ''}`)


    useEffect(() => {
        if (sigCanvas) {
            sigCanvas.fromDataURL(
                getSignatureImageValue(),
                {
                    width: 400,
                    height: 150
                }
            )
        }

        // eslint-disable-next-line
    }, [sigCanvas])


    const getSignatureImageValue = () => {
        const currentFormData = formData
        let currentValue = currentFormData?.[currentFieldIndex]?.['field_value']

        return currentValue ? currentValue : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII=';
    }

    const setSignatureImageValue = (value) => {
        const currentFormData = formData
        let currentValue = [...currentFormData?.[currentFieldIndex]?.['field_value']]

        if (currentValue !== undefined) {
            currentValue = value
            currentFormData[currentFieldIndex]['field_value'] = currentValue
        }

        return setFormData(currentFormData)
    }


    const hasInputWarning = () => {
        return inputWarnings.filter(i => i.includes(`${field_key}`)).length > 0
    }

    const inputWarningKey = constructInputWarning(currentSection, currentStep, field_key, 'none', currentProjectID ? `projectID-${currentProjectID}` : '')

    return (

        <div className="FieldSignature form-field">
            {field_title ?
                <div className={`field-title ${title_blue ? 'title-blue' : ''}`}>{field_title}</div>
                : null}

            {getSubtitleData(field_key) ?
                <div className="field-subtitle">{getSubtitleData(field_key)}</div>
                : null}

            {hasFieldWarning(inputWarnings, field_key) ?

                <div className="field-warning">
                    Vul a.u.b alle verplichte velden in
                </div>

                : null}

            <div className="form-inputs">

                <div className={`input-item signature-input narrow ${hasInputWarning() ? 'warning' : ''} ${!userCanEdit() ? 'disabled' : ''}`}>

                    <SignatureCanvas
                        penColor='black'
                        canvasProps={{
                            width: 400,
                            height: 150
                        }}
                        ref={ref => setSigCanvas(ref)}
                        onEnd={() => {

                            if (!userCanEdit()) {
                                return
                            }

                            setSignatureImageValue(sigCanvas.toDataURL())
                            checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings, inputWarningKey)
                        }}
                    />

                    {userCanEdit() ?

                        <div
                            className="signature-clear"
                            onClick={() => {
                                sigCanvas.clear()
                                setSignatureImageValue('')
                            }}
                        >
                            reset
                        </div>
                        : null}

                </div>

            </div>



        </div>
    )
}
