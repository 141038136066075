import FormContent from '../Form/FormContent'
import FormNav from './FormNav/FormNav'

export default function FormMain() {
    return (
        <div className="FormMain">
            <FormContent />
            <FormNav />
        </div>
    )
}
