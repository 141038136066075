import { Text, View, Image } from '@react-pdf/renderer'
import regionRowIcons from './RegionRowIcons'

export default function RegionStats({ formData }) {

    const getDefaultValue = index => {
        return formData.filter(field => field.field_key === '1-1-omvang-regio')?.[0]?.field_value?.[index]
    }

    const getCustomValues = () => {
        return formData.filter(field => field.field_key === '1-1-omvang-regio')?.[0]?.field_value_custom
    }

    const getNVTValues = () => {
        return formData.filter(field => field.field_key === '1-1-omvang-regio')?.[0]?.field_value_nvt
    }

    const getDefaultList = () => {
        return ['Gemeentes', 'Inwoners', 'Ondernemers', 'Bedrijventerreinen', 'Winkelgebieden', 'Buitengebieden', 'Vakantieparken', '(Jacht)havens']
    }

    return (
        <View
            wrap={false}
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '20px',
                marginTop: '25px'
            }}
        >
            <Text
                style={{
                    fontSize: '13px',
                    lineHeight: '1.4',
                    color: '#0b8bcc',
                    fontFamily: 'ArchivoBold'
                }}
                hyphenationCallback={e => [e]}
            >
                1.1 Omgang van de regio
            </Text>


            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >


                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '5px',
                        backgroundColor: '#e4f5fd',
                        borderRadius: '3px',
                        marginTop: '3px'
                    }}
                >
                    <Text
                        style={{
                            width: '50%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Onderwerp
                    </Text>
                    <Text
                        style={{
                            width: '50%',
                            fontSize: '11px',
                            color: '#000',
                            fontFamily: 'ArchivoBold',
                        }}
                        hyphenationCallback={e => [e]}
                    >
                        Omvang in cijfers
                    </Text>
                </View>


                {getDefaultList().map((item, itemIndex) => {

                    if (getNVTValues().includes(itemIndex)) {
                        return null
                    }

                    return (
                        <View
                            key={itemIndex}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '3px',
                                borderBottom: '1px solid #e6e7e8'
                            }}>
                            <View
                                style={{
                                    width: '50%',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <Image
                                    src={regionRowIcons(item)}
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        objectFit: 'contain',
                                        objectPosition: 'center'
                                    }}
                                />
                                <Text style={{
                                    fontSize: '11px',
                                    lineHeight: '1.4',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                    marginLeft: '4px'
                                }}
                                hyphenationCallback={e => [e]}
                                >
                                    {item}
                                </Text>
                            </View>
                            <Text style={{
                                width: '50%',
                                fontSize: '11px',
                                lineHeight: '1.4',
                                color: '#000',
                                fontFamily: 'Archivo',
                            }}
                            hyphenationCallback={e => [e]}
                            >
                                {getDefaultValue(itemIndex)}
                            </Text>
                        </View>
                    )
                })}


                {getCustomValues().map((item, itemIndex) => {
                    return (
                        <View
                            key={itemIndex}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '3px',
                                borderBottom: '1px solid #e6e7e8'
                            }}>
                            <View
                                style={{
                                    width: '50%',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <Image
                                    src={regionRowIcons('')}
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        objectFit: 'contain',
                                        objectPosition: 'center'
                                    }}
                                />
                                <Text style={{
                                    fontSize: '11px',
                                    lineHeight: '1.4',
                                    color: '#000',
                                    fontFamily: 'Archivo',
                                    marginTop: '1px',
                                    marginLeft: '4px'
                                }}
                                hyphenationCallback={e => [e]}
                                >
                                    {item.label}
                                </Text>
                            </View>
                            <Text style={{
                                width: '50%',
                                fontSize: '11px',
                                lineHeight: '1.4',
                                color: '#000',
                                fontFamily: 'Archivo',
                                marginTop: '1px'
                            }}
                            hyphenationCallback={e => [e]}
                            >
                                {item.value}
                            </Text>
                        </View>
                    )
                })}

            </View>

        </View>
    )
}
