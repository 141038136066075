import FormFields from "./FormFields"

export default function FormStepView({ step, section, stepIndex }) {


    const getStepTitle = () => {

        const totalSteps = section.steps.length
        let title = `${section.section_title}`

        if (step.step_title) {
            title += `: ${step.step_title}`
        }

        if (totalSteps > 1) {
            title += ` (${stepIndex + 1}/${totalSteps})`
        }

        return title
    }

    return (
        <div className='FormStepView'>

            <div className="step-view-main">
                <div className="step-title">
                    {getStepTitle()}
                </div>

                {section.section_description ?
                    <div className="step-description">
                        {section.section_description}
                    </div>
                    : null}

                {section.section_subtitle ?
                    <div className="step-subtitle">
                        {section.section_subtitle}
                    </div>
                    : null}


                <FormFields fields={step.fields} />
            </div>

            <div className="step-bottom-title">
                {getStepTitle()}
            </div>
        </div>
    )
}
