import { useContext } from "react"
import { GlobalContext } from "../../../context/GlobalState"
import { constructInputWarning, hasFieldWarning, escapeStr } from '../../../helpers/functions'
import { checkInputsValidation } from '../../../helpers/form-validator'
import useSubtitleData from "../../../helpers/hooks/useSubtitleData"
import useUserRole from "../../../helpers/hooks/useUserRole"

export default function FieldSingleTextarea({ field }) {

    const {
        field_title,
        title_blue,
        field_key,
        placeholder,
        max_characters
    } = field

    const { globalState, setFormData, setInputWarnings } = useContext(GlobalContext)
    const { formData, inputWarnings, currentSection, currentStep, currentProjectID } = globalState

    const { getSubtitleData } = useSubtitleData()
    const { userCanEdit } = useUserRole()

    const currentFieldIndex = formData.map(i => i.field_key).indexOf(`${field_key}${currentProjectID ? '-' + currentProjectID : ''}`)
    const maxChar = max_characters ? max_characters : 1500

    const getFieldValue = () => {
        const currentFormData = formData
        let currentValue = currentFormData?.[currentFieldIndex]?.['field_value']

        return currentValue ? currentValue : '';
    }


    const setFieldValue = (value) => {
        const currentFormData = formData
        let currentValue = [...currentFormData?.[currentFieldIndex]?.['field_value']]

        if (currentValue !== undefined) {
            currentValue = value
            currentFormData[currentFieldIndex]['field_value'] = currentValue
        }

        return setFormData(currentFormData)
    }

    const hasInputWarning = () => {
        if (currentProjectID) {
            return inputWarnings.filter(i => i.includes(`${field_key}`) && i.includes(`${currentProjectID}`)).length > 0
        }

        return inputWarnings.filter(i => i.includes(`${field_key}`)).length > 0
    }

    const inputWarningKey = constructInputWarning(currentSection, currentStep, field_key, 'none', currentProjectID ? `projectID-${currentProjectID}` : '')

    return (

        <div className="FieldSingleTextarea form-field">
            {field_title ?
                <div className={`field-title ${title_blue ? 'title-blue' : ''}`}>{field_title}</div>
                : null}

            {getSubtitleData(field_key) ?
                <div className="field-subtitle">{getSubtitleData(field_key)}</div>
                : null}

            {hasFieldWarning(inputWarnings, field_key, currentProjectID) ?

                <div className="field-warning">
                    Vul a.u.b alle verplichte velden in
                </div>

                : null}

            <div className="form-inputs">

                <div className="input-item">
                    <textarea
                        type="text"
                        disabled={!userCanEdit()}
                        className={`field-textarea ${hasInputWarning() ? 'warning' : ''}`}
                        placeholder={placeholder}
                        value={getFieldValue()}
                        onChange={e => {
                            if (!userCanEdit()) {
                                return
                            }

                            setFieldValue(escapeStr(e.target.value).slice(0, maxChar))
                            checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings, inputWarningKey)
                        }}
                    />

                    {userCanEdit() ?
                        <div className={`input-counter ${getFieldValue().length === maxChar ? 'limmit' : ''}`}>
                            {`${getFieldValue().length}/${maxChar}`}
                        </div>
                        : null}
                </div>



            </div>



        </div>
    )
}
