import { useContext, useState } from "react"
import { GlobalContext } from "../../../context/GlobalState"
import { hasFieldWarning, constructInputWarning, formateEuroString, getTotalCostPersonnel, escapeStr } from '../../../helpers/functions'
import { checkInputsValidation } from '../../../helpers/form-validator'
import parsePrice from 'parse-price'
import ClickAwayListener from '@mui/base/ClickAwayListener';
import useSubtitleData from "../../../helpers/hooks/useSubtitleData"
import useUserRole from "../../../helpers/hooks/useUserRole"


export default function FieldRepeaterMultipleItems({ field }) {

    const {
        skip_labels,
        field_key
    } = field

    const { globalState, setFormData, setInputWarnings } = useContext(GlobalContext)
    const { formData, inputWarnings, currentSection, currentStep } = globalState

    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [positionToRemove, setPositionToRemove] = useState(null)
    const [removeAll, setRemoveAll] = useState(false)

    const { getSubtitleData } = useSubtitleData()
    const { userCanEdit } = useUserRole()

    const currentFieldIndex = formData.map(i => i.field_key).indexOf(field_key)

    const getInputList = () => {
        let inputs = formData?.[currentFieldIndex]?.['field_value']

        if (Array.isArray(inputs)) {
            return inputs
        }

        return null
    }


    const getInputValue = (row, index) => {
        let value = formData?.[currentFieldIndex]?.['field_value']?.[row]?.[index]

        if (value) {
            return value
        }

        return ''
    }


    const setInputValue = (row, index, value) => {
        const currentFormData = formData
        let currentRowValues = [...currentFormData?.[currentFieldIndex]?.['field_value']?.[row]]

        if (currentRowValues?.[index] !== undefined) {
            currentRowValues[index] = value
            currentFormData[currentFieldIndex]['field_value'][row] = currentRowValues
        }

        return setFormData(currentFormData)
    }


    const addNewRow = () => {
        const currentFormData = formData
        let currentValues = [...currentFormData?.[currentFieldIndex]?.['field_value']]

        if (Array.isArray(currentValues)) {
            currentValues.push(['', '', '', false, 'Loondienst'])
            currentFormData[currentFieldIndex]['field_value'] = currentValues
        }

        return setFormData(currentFormData)
    }


    const removeRow = () => {
        const currentFormData = formData
        let currentValues = [...currentFormData?.[currentFieldIndex]?.['field_value']]

        if (Array.isArray(currentValues)) {
            currentValues.splice(positionToRemove, 1)
            currentFormData[currentFieldIndex]['field_value'] = currentValues
        }

        return setFormData(currentFormData)
    }


    const clearCurrentValues = () => {
        const currentFormData = formData
        let currentValues = [...currentFormData?.[currentFieldIndex]?.['field_value']]

        if (Array.isArray(currentValues) && currentValues.length > 0) {
            currentValues = []
            currentFormData[currentFieldIndex]['field_value'] = currentValues
        }

        return setFormData(currentFormData)
    }


    const removeOldWarnings = () => {
        const currentFormData = formData
        let currentValues = [...currentFormData?.[currentFieldIndex]?.['field_value']]
        let currentInputWarnings = inputWarnings

        const currentFieldWarnings = currentInputWarnings.filter(warning => warning.includes(field_key))

        currentFieldWarnings.forEach(warning => {
            const warningRowIndex = warning.split(':')[warning.split(':').length - 1]?.split('-')?.[0]
            if (+warningRowIndex + 1 > currentValues.length) {
                currentInputWarnings = currentInputWarnings.filter(warningItem => warningItem !== warning)
            }
        })

        setInputWarnings(currentInputWarnings)
    }


    const hasInputWarning = (row, cell) => {
        return inputWarnings.filter(i => i.includes(`${field_key}:${row}-${cell}`)).length > 0
    }


    const getPriceLabel = (rowIndex) => {
        let value = formData?.[currentFieldIndex]?.['field_value']?.[rowIndex]?.[4]

        if (value && value === 'ZZP') {
            return 'Uurtarief'
        }

        return 'Kosten'
    }


    const calculateTotalSumRow = (rowIndex) => {
        let priceValue = formData?.[currentFieldIndex]?.['field_value']?.[rowIndex]?.[1]
        let hoursValue = formData?.[currentFieldIndex]?.['field_value']?.[rowIndex]?.[2]
        const employmentTypeValue = formData?.[currentFieldIndex]?.['field_value']?.[rowIndex]?.[4]

        priceValue = parsePrice(priceValue)

        if (employmentTypeValue === 'ZZP') {
            hoursValue = parsePrice(hoursValue)
            return formateEuroString(hoursValue * priceValue)
        }

        return formateEuroString(priceValue)
    }

    return (
        <div className='FieldRepeaterMultipleItems form-field'>
            <div className="field-heading">4.2 Uitgaven</div>

            <div className="field-subheading">
                In de rijen hieronder kun je zelf nieuwe kostenposten toevoegen. Kosten voor de Week van de Veiligheid, algemene communicatie en de Thema’s worden automatisch ingevuld.
            </div>

            <div className="field-title title-blue">
                Personeelskosten
            </div>

            {getSubtitleData(field_key) ?
                <div className="field-subtitle">
                    {getSubtitleData(field_key)}
                </div>
                : null}

            {hasFieldWarning(inputWarnings, field_key) ?

                <div className="field-warning">
                    Vul a.u.b alle verplichte velden in
                </div>

                : null}


            <div className="form-inputs">

                {(() => {
                    const inputsList = getInputList()

                    return (
                        inputsList && inputsList.length > 0 ?

                            inputsList.map((_, rowIndex) => {
                                return (
                                    <div key={rowIndex} className="inputs-row">

                                        <div className="row-number">{rowIndex + 1}</div>

                                        {[...Array(5)].map((_, cellIndex) => {

                                            const inputWarningKey = constructInputWarning(currentSection, currentStep, field_key, `${rowIndex}-${cellIndex}`)

                                            if (cellIndex === 0) {

                                                const options = [
                                                    "PVO-manager",
                                                    "PVO-adviseur",
                                                    "Projectleider",
                                                    "Communicatieadviseur",
                                                    "Ondersteuner"
                                                ]

                                                return (
                                                    <SelectInputType
                                                        key={`${rowIndex}-${cellIndex}`}
                                                        skipLabels={skip_labels}
                                                        row={rowIndex}
                                                        index={cellIndex}
                                                        inputLabel="Functie"
                                                        inputPlaceholder="Selecteer"
                                                        inputValue={getInputValue(rowIndex, cellIndex)}
                                                        setInputValue={value => {
                                                            setInputValue(rowIndex, cellIndex, value)
                                                            checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings, inputWarningKey)
                                                        }}
                                                        hasInputWarning={hasInputWarning(rowIndex, cellIndex)}
                                                        options={options}
                                                        disabled={!userCanEdit()}
                                                    />
                                                )
                                            }


                                            if (cellIndex === 1) {

                                                return (
                                                    <PriceInputType
                                                        key={`${rowIndex}-${cellIndex}`}
                                                        inputLabel={getPriceLabel(rowIndex)}
                                                        inputValue={getInputValue(rowIndex, cellIndex)}
                                                        setInputValue={value => {

                                                            value = value.split('').filter(char => {
                                                                return (
                                                                    char === '€' ||
                                                                    char === ',' ||
                                                                    char === '.' ||
                                                                    !isNaN(parseInt(char))
                                                                )
                                                            }).join('')

                                                            setInputValue(rowIndex, cellIndex, value)
                                                            checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings, inputWarningKey)
                                                        }}
                                                        hasInputWarning={hasInputWarning(rowIndex, cellIndex)}
                                                        disabled={!userCanEdit()}
                                                    />
                                                )
                                            }

                                            if (cellIndex === 2) {

                                                return (
                                                    <HoursInputType
                                                        key={`${rowIndex}-${cellIndex}`}
                                                        inputValue={getInputValue(rowIndex, cellIndex)}
                                                        setInputValue={value => {

                                                            value = parseFloat(value.replace(/\D/g, ''));
                                                            value = isNaN(value) ? '' : `${value}`;

                                                            setInputValue(rowIndex, cellIndex, value)
                                                            checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings, inputWarningKey)
                                                        }}
                                                        hasInputWarning={hasInputWarning(rowIndex, cellIndex)}
                                                        disabled={!userCanEdit()}
                                                    />
                                                )
                                            }

                                            if (cellIndex === 3) {

                                                return (
                                                    <TotalInputType
                                                        key={`${rowIndex}-${cellIndex}`}
                                                        inputValue={calculateTotalSumRow(rowIndex)}
                                                    />
                                                )
                                            }

                                            if (cellIndex === 4) {

                                                const input_value = getInputValue(rowIndex, cellIndex)
                                                const input_choices = ['Loondienst', 'ZZP']

                                                return (
                                                    <RadioInputType
                                                        key={`${rowIndex}-${cellIndex}`}
                                                        inputValue={input_value}
                                                        radioChoices={input_choices}
                                                        setInputValue={value => setInputValue(rowIndex, cellIndex, value)}
                                                        disabled={!userCanEdit()}
                                                    />
                                                )
                                            }

                                            return null;

                                        })}

                                        {userCanEdit() ?
                                            <div
                                                className={`remove-row ${rowIndex === 0 ? 'hidden' : ''}`}
                                                onClick={() => {
                                                    if (rowIndex > 0) {
                                                        setIsPopupOpen(true)
                                                        setPositionToRemove(rowIndex)
                                                        document.body.classList.remove("no-scroll")
                                                    }
                                                }}
                                            ></div>
                                            : null}
                                    </div>
                                )

                            })
                            : null
                    )

                })()}


            </div>


            {userCanEdit() ?
                <div
                    className="btn-main blue plus add-row"
                    onClick={() => addNewRow()}
                >Rij toevoegen</div>
                : null}

            <div className="form-text-row">
                <div className="row-label">
                    Totaal personeelskosten
                </div>
                <div className="row-value">
                    {getTotalCostPersonnel(formData)}
                </div>
            </div>


            {
                isPopupOpen ?
                    <div className="popup-main">
                        <form className="popup-form">

                            <div className="popup-title">
                                Weet je zeker dat je Raad van toezicht wilt verwijderen?
                            </div>

                            <div className="popup-subtitle">
                                Hiermee verwijder je de ingevulde informatie. Deze actie kan je niet ongedaan maken.
                            </div>


                            <div className="button-row right">
                                <button
                                    className="btn-sec popup-btn"
                                    onClick={e => {
                                        e.preventDefault()
                                        setIsPopupOpen(false)
                                        setPositionToRemove(null)
                                        setRemoveAll(false)
                                    }}
                                >Annuleren</button>
                                <button
                                    className="btn-main popup-btn"
                                    onClick={e => {
                                        e.preventDefault()

                                        if (removeAll) {
                                            clearCurrentValues()
                                        } else {
                                            removeRow()
                                        }

                                        setPositionToRemove(null)
                                        setRemoveAll(false)
                                        setIsPopupOpen(false)
                                        removeOldWarnings()
                                    }}
                                >Verwijderen</button>
                            </div>

                        </form>
                    </div>
                    : null
            }

        </div>
    )
}


function TotalInputType({ inputValue }) {

    return (
        <div className={`input-item spread total-field`}>

            <div className="input-label">
                <div className="label-text">Totaal</div>
            </div>

            <input
                type="text"
                className={`field-input`}
                value={inputValue ? inputValue : ''}
                onChange={() => ''}
                disabled={true}
            />
        </div>
    )
}

function HoursInputType({ setInputValue, inputValue, hasInputWarning, disabled }) {

    return (
        <div className={`input-item spread hours-field`}>

            <div className="input-label">
                <div className="label-text">Uren</div>
            </div>

            <input
                type="text"
                className={`field-input ${hasInputWarning ? 'warning' : ''}`}
                placeholder='0'
                value={inputValue ? inputValue : ''}
                onChange={e => {
                    if (disabled) {
                        return
                    }
                    setInputValue(escapeStr(e.target.value))
                }}
                disabled={disabled}
            />
        </div>
    )
}


function PriceInputType({ setInputValue, inputValue, hasInputWarning, inputLabel, disabled }) {

    const _formateValuePrice = (value) => {
        if (
            value !== '' &&
            !value.startsWith('€')
        ) {
            return `€${value}`
        }

        return value ? value : ''
    }

    return (
        <div className={`input-item spread number-field`}>

            <div className="input-label">
                <div className="label-text">{inputLabel}</div>
            </div>

            <input
                type="text"
                className={`field-input ${hasInputWarning ? 'warning' : ''}`}
                placeholder='€ 0,-'
                value={_formateValuePrice(inputValue)}
                onChange={e => {
                    if (disabled) {
                        return
                    }
                    setInputValue(escapeStr(e.target.value))
                }}
                disabled={disabled}
            />
        </div>
    )
}

function RadioInputType({ setInputValue, inputValue, radioChoices, disabled }) {
    return (
        <div className="input-item radio">

            <div
                className='radio-input-list'
            >

                {radioChoices?.length > 0 && radioChoices.map((choice, i) => {
                    return (
                        <div key={i} className="radio-row">
                            <input
                                className='radio-input'
                                type="radio"
                                checked={choice === inputValue}
                                value={choice}
                                onChange={e => {
                                    if (disabled) {
                                        return
                                    }
                                    setInputValue(escapeStr(e.target.value))
                                }}
                                disabled={disabled}
                            />
                            {choice}
                        </div>
                    )
                })}

            </div>
        </div>
    )
}


function SelectInputType({ skipLabels, inputLabel, inputPlaceholder, row, setInputValue, inputValue, hasInputWarning, options, disabled }) {

    const [showOptions, setShowOptions] = useState(false)
    const [optionsOpacity, setOptionsOpacity] = useState(true)


    return (
        <div className="input-item spread">

            {skipLabels && row > 0 ? null :
                <div className="input-label">
                    <div className="label-text">{inputLabel}</div>
                </div>
            }

            <ClickAwayListener
                onClickAway={() => {
                    setOptionsOpacity(true)
                    setTimeout(() => {
                        setShowOptions(false)
                    }, 300)
                }}
            >
                <div className="input-item">
                    <input
                        type="text"
                        className={`field-input multiselect ${hasInputWarning ? 'warning' : ''}`}
                        placeholder={inputPlaceholder}
                        value={inputValue ? inputValue : ''}
                        onClick={() => {
                            if (disabled) {
                                return
                            }

                            setShowOptions(true)
                            setOptionsOpacity(false)
                        }}
                        onChange={() => ''}
                        disabled={disabled}
                    />

                    {showOptions && options?.length > 0 ?
                        <ul
                            className="suggestions no-labels multiselect"
                            style={{ "opacity": optionsOpacity ? '0' : '1' }}
                        >
                            {options.map(option => {
                                return (
                                    <li
                                        className={`${option === inputValue ? 'selected' : ''}`}
                                        key={option}
                                        onClick={e => {
                                            setInputValue(e.currentTarget.innerText)
                                            setOptionsOpacity(true)
                                            setTimeout(() => {
                                                setShowOptions(false)
                                            }, 300)
                                        }}
                                    >
                                        {option}
                                    </li>
                                );
                            })}
                        </ul>
                        : null}
                </div>
            </ClickAwayListener>
        </div>
    )
}