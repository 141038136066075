import { useContext } from "react"
import { GlobalContext } from "../../context/GlobalState"
import FieldRepeaterSingleItems from "./FormFields/FieldRepeaterSingleItems";
import FieldContent from "./FormFields/FieldContent";
import FieldRepeaterMultipleItems from "./FormFields/FieldRepeaterMultipleItems";
import FieldSingleTextarea from "./FormFields/FieldSingleTextarea";
import FieldProjectsList from "./FormFields/FIeldProjects/FieldProjectsList";
import FieldTextInput from "./FormFields/FieldTextInput";
import FieldMultiSelect from './FormFields/FieldMultiSelect'
import FieldRepeaterEmployeeCosts from './FormFields/FieldRepeaterEmployeeCosts'
import FieldCostsSummary from "./FormFields/FieldCostsSummary";
import FieldBalanceOverview from "./FormFields/FieldBalanceOverview";
import FieldSignature from "./FormFields/FieldSignature";
import FieldDownload from "./FormFields/FieldDownload";
import { isToShowProjectField } from '../../helpers/functions'


export default function FormFields({ fields }) {

    const { globalState } = useContext(GlobalContext)
    const { formData, currentProjectID } = globalState

    if (!fields) {
        return false;
    }

    return (
        <div className="FormFields">
            {fields.filter(item => isToShowProjectField(item, formData, currentProjectID)).map((field, i) => {

                switch (field.field_type) {
                    case 'content':
                        return <FieldContent key={i} field={field} />

                    case 'repeater_single_items':
                        return <FieldRepeaterSingleItems key={i} field={field} />

                    case 'repeater_multiple_items':
                        return <FieldRepeaterMultipleItems key={i} field={field} />

                    case 'single_textarea':
                        return <FieldSingleTextarea key={i} field={field} />

                    case 'projects':
                        return <FieldProjectsList key={i} field={field} />

                    case 'text_input':
                        return <FieldTextInput key={i} field={field} />

                    case 'multiselect':
                        return <FieldMultiSelect key={i} field={field} />

                    case 'repeater_employee_costs':
                        return <FieldRepeaterEmployeeCosts key={i} field={field} />

                    case 'costs_summary':
                        return <FieldCostsSummary key={i} />

                    case 'balance_overview':
                        return <FieldBalanceOverview key={i} />

                    case 'signature':
                        return <FieldSignature field={field} key={i} />

                    case 'download_document':
                        return <FieldDownload field={field} key={i} />

                    default:
                        return null;
                }
            })}
        </div>
    )
}

