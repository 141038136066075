import { useContext, useState } from "react"
import { GlobalContext } from "../../../context/GlobalState"
import { constructInputWarning, hasFieldWarning } from '../../../helpers/functions'
import { checkInputsValidation } from '../../../helpers/form-validator'
import ClickAwayListener from '@mui/base/ClickAwayListener';
import useSubtitleData from "../../../helpers/hooks/useSubtitleData";
import useUserRole from "../../../helpers/hooks/useUserRole";

export default function FieldMultiSelect({ field }) {

    const {
        field_title,
        title_blue,
        field_key,
        placeholder,
        options,
        max_options
    } = field

    const [showOptions, setShowOptions] = useState(false)
    const [optionsOpacity, setOptionsOpacity] = useState(true)

    const { globalState, setFormData, setInputWarnings } = useContext(GlobalContext)
    const { formData, inputWarnings, currentSection, currentStep, currentProjectID } = globalState

    const { getSubtitleData } = useSubtitleData()
    const { userCanEdit } = useUserRole()

    const currentFieldIndex = formData.map(i => i.field_key).indexOf(`${field_key}${currentProjectID ? '-' + currentProjectID : ''}`)


    const getFieldValue = () => {
        const currentFormData = formData
        let currentValue = currentFormData?.[currentFieldIndex]?.['field_value']

        return currentValue ? currentValue : '';
    }


    const setSelectValue = (value) => {
        const currentFormData = formData
        const currentValue = currentFormData?.[currentFieldIndex]?.['field_value']

        if (currentValue !== undefined) {

            if (currentValue === '') {
                currentFormData[currentFieldIndex]['field_value'] = value
            } else {
                let currentValuesList = currentValue.split(',').map(i => i.trim())

                if (currentValuesList.indexOf(value) >= 0) {
                    currentValuesList = currentValuesList.filter(item => item !== value)
                } else {
                    if (max_options) {
                        if (max_options === 1) {
                            currentValuesList = [value]
                        } else if (currentValuesList.length < max_options) {
                            currentValuesList.push(value)
                        }
                    } else {
                        currentValuesList.push(value)
                    }
                }

                currentFormData[currentFieldIndex]['field_value'] = currentValuesList.join(', ')

            }
        }

        return setFormData(currentFormData)
    }

    const hasInputWarning = () => {

        if (currentProjectID) {
            return inputWarnings.filter(i => i.includes(`${field_key}`) && i.includes(`${currentProjectID}`)).length > 0
        }

        return inputWarnings.filter(i => i.includes(`${field_key}`)).length > 0
    }

    const isOptionSelected = (optionValue) => {
        return getFieldValue()?.includes(optionValue)
    }

    const inputWarningKey = constructInputWarning(currentSection, currentStep, field_key, 'none', currentProjectID ? `projectID-${currentProjectID}` : '')

    return (

        <div className="FieldTextInput form-field">
            {field_title ?
                <div className={`field-title ${title_blue ? 'title-blue' : ''}`}>{field_title}</div>
                : null}

            {getSubtitleData(field_key) ?
                <div className="field-subtitle">{getSubtitleData(field_key)}</div>
                : null}

            {hasFieldWarning(inputWarnings, field_key, currentProjectID) ?

                <div className="field-warning">
                    Vul a.u.b alle verplichte velden in
                </div>

                : null}

            <div className="form-inputs">

                <ClickAwayListener
                    onClickAway={() => {
                        setOptionsOpacity(true)
                        setTimeout(() => {
                            setShowOptions(false)
                        }, 300)
                    }}
                >
                    <div className="input-item">
                        <input
                            type="text"
                            className={`field-input multiselect ${hasInputWarning() ? 'warning' : ''}`}
                            placeholder={placeholder}
                            value={getFieldValue()}
                            onClick={() => {
                                if (!userCanEdit()) {
                                    return
                                }
                                setShowOptions(true)
                                setOptionsOpacity(false)
                            }}
                            onChange={() => ''}
                            disabled={!userCanEdit()}
                        />

                        {showOptions && options?.length > 0 ?
                            <ul
                                className="suggestions no-labels multiselect"
                                style={{ "opacity": optionsOpacity ? '0' : '1' }}
                            >
                                {options.map(option => {
                                    return (
                                        <li
                                            className={`${isOptionSelected(option) ? 'selected' : ''}`}
                                            key={option}
                                            onClick={e => {
                                                setSelectValue(e.currentTarget.innerText)
                                                checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings, inputWarningKey)
                                            }}
                                        >
                                            {option}
                                        </li>
                                    );
                                })}
                            </ul>
                            : null}
                    </div>
                </ClickAwayListener>



            </div>



        </div>
    )
}
