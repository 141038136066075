import { Page, Text, View } from '@react-pdf/renderer'
import Footer from '../Parts/Footer'
import TopMark from '../Parts/TopMark'
import PageNumberGetter from '../Parts/PageNumberGetter'

export default function PrioritiesPage({ getPrioritiesPageNumber, formData }) {

    const getPriorityFieldValue = () => {
        return formData.filter(field => field.field_key === '2-1-lokale-prioriteiten')?.[0]?.field_value
    }

    const getYearPlanFieldValue = () => {
        return formData.filter(field => field.field_key === '2-2-totstandkoming-jaarplan')?.[0]?.field_value
    }

    const getAmbitionFieldValue = () => {
        return formData.filter(field => field.field_key === '2-3-ambitie-en-vooruitblik')?.[0]?.field_value
    }

    return (
        <Page
            style={{ padding: '70px 70px 70px 70px', position: 'relative' }}
            size="A4"
        >
            <TopMark />

            <PageNumberGetter getterFunction={getPrioritiesPageNumber} />

            <Text
                style={{
                    fontFamily: 'Roboto',
                    fontSize: '24px',
                    textDecoration: 'underline',
                    color: '#000'
                }}
                hyphenationCallback={e => [e]}
            >
                Prioriteiten en ambities
            </Text>


            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '25px'
                }}
            >
                <Text
                    style={{
                        fontSize: '13px',
                        lineHeight: '1.4',
                        color: '#0b8bcc',
                        fontFamily: 'ArchivoBold',
                        marginBottom: '5px'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    2.1 Lokale prioriteiten
                </Text>

                <Text
                    style={{
                        fontSize: '11px',
                        lineHeight: '1.6',
                        color: '#000',
                        fontFamily: 'Archivo'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    {getPriorityFieldValue()}
                </Text>

            </View>

            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '25px'
                }}
            >
                <Text
                    style={{
                        fontSize: '13px',
                        lineHeight: '1.4',
                        color: '#0b8bcc',
                        fontFamily: 'ArchivoBold',
                        marginBottom: '5px'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    2.2 Totstandkoming jaarplan
                </Text>

                <Text
                    style={{
                        fontSize: '11px',
                        lineHeight: '1.6',
                        color: '#000',
                        fontFamily: 'Archivo'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    {getYearPlanFieldValue()}
                </Text>

            </View>

            <View
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '25px'
                }}
            >
                <Text
                    style={{
                        fontSize: '13px',
                        lineHeight: '1.4',
                        color: '#0b8bcc',
                        fontFamily: 'ArchivoBold',
                        marginBottom: '5px'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    2.3 Ambitie en vooruitblik
                </Text>

                <Text
                    style={{
                        fontSize: '11px',
                        lineHeight: '1.6',
                        color: '#000',
                        fontFamily: 'Archivo'
                    }}
                    hyphenationCallback={e => [e]}
                >
                    {getAmbitionFieldValue()}
                </Text>

            </View>

            <Footer />
        </Page>
    )
}
