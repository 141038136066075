import { Text } from '@react-pdf/renderer'

export default function PageNumberGetter({ getterFunction }) {

    if (!getterFunction) {
        return null
    }
    return (
        <Text render={({ pageNumber }) => getterFunction(pageNumber)} />
    )
}
