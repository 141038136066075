import { useContext } from "react"
import { GlobalContext } from "../../../context/GlobalState"
import { PDFDownloadLink } from '@react-pdf/renderer'
import PDFDocument from '../../PDFDocument/PDFDocument';
import { getRandomId } from '../../../helpers/functions';
import useSaveDownloadDate from "../../../helpers/hooks/useSaveDownloadDate";
import useSubtitleData from "../../../helpers/hooks/useSubtitleData";


export default function FieldDownload({ field }) {
    const {
        field_title,
        title_blue,
        field_key
    } = field

    const { globalState } = useContext(GlobalContext)
    const { formData, inputWarnings, lastDownloaded } = globalState

    const { saveDownloadDate } = useSaveDownloadDate()
    const { getSubtitleData } = useSubtitleData()


    const hasWarnings = () => {
        return inputWarnings.length > 0
    }

    return (
        <div className="FieldDownload form-field">
            {field_title ?
                <div className={`field-title ${title_blue ? 'title-blue' : ''}`}>{field_title}</div>
                : null}

            {getSubtitleData(field_key) ?
                <div className="field-subtitle">{getSubtitleData(field_key)}</div>
                : null}

            {hasWarnings() ?
                <div className="field-warning">
                    Let op: Enkele velden zijn niet ingevuld. Weet je zeker dat je het document wilt downloaden?
                </div>
                : null}

            <div className="download-action">
                <PDFDownloadLink onClick={() => saveDownloadDate()} document={<PDFDocument formData={formData} />} fileName={`pvo-aanvraag-${getRandomId()}.pdf`}>
                    {({ loading }) =>
                        loading ?
                            <div
                                className="btn-main blue download download-document"
                            >Generating...</div>
                            :
                            <div
                                className="btn-main blue download download-document"
                            >Download document</div>
                    }

                </PDFDownloadLink>
                {lastDownloaded ?
                    <div className="last-download">
                        {`Laatst gedownload: ${lastDownloaded}`}
                    </div>
                    : null}
            </div>


        </div>
    )
}
