import { useContext, useEffect } from "react"
import { GlobalContext } from "../../context/GlobalState"
import formConfig from '../../helpers/form-config.json'
import FormStepView from "./FormStepView"
import ProjectStepView from '../Form/ProjectStepView'
import { checkInputsValidation } from '../../helpers/form-validator'

export default function FormContent() {

    const { globalState } = useContext(GlobalContext)
    const { currentSection, currentStep, currentProjectID, currentProjectStep } = globalState

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0
        })

    }, [currentProjectID, currentStep, currentSection, currentProjectStep])



    const getProjectSteps = () => {
        const projectsSection = formConfig?.sections?.filter(section => section.projects_section)?.[0]

        const projectSteps = projectsSection.steps?.map(step => step.fields?.filter(field => field.field_type === 'projects')[0])[0]?.inner_steps

        return !!projectSteps && projectSteps

    }


    if (!formConfig.sections?.[currentSection]?.steps?.[currentStep]) {
        return null
    }

    return (
        <div className="FormContent">

            {currentProjectID ?

                <ProjectStepView
                    projectSteps={getProjectSteps()}
                />

                :

                <FormStepView
                    section={formConfig.sections[currentSection]}
                    step={formConfig.sections[currentSection].steps[currentStep]}
                    stepIndex={currentStep}
                    sectionIndex={currentSection}
                />
            }
        </div>
    )
}
