import FormMain from './Form/FormMain';
import Sidebar from './Sidebar/Sidebar';
import { useEffect, useContext } from 'react';
import { GlobalContext } from '../context/GlobalState';
import { generateFormData } from '../helpers/form-data-generator';
import { getRemoteFormData, getRemoteVisitedSections, getRemoteInputWarnings, getRemoteLastDownload, getRemoteHandleSetInputInstructions, getRemoteHandleSetUserRole } from '../helpers/functions'

export default function Main() {

    const { globalState, setFormData, setVisitedSections, setInputWarnings, setLastDownloaded, setInputInstructions, setUserRole } = useContext(GlobalContext)
    const { formData } = globalState

    useEffect(() => {

        handleSetFormData()
        handleSetVisitedSections()
        handleSetInputWarnings()
        handleSetLastDownload()
        handleSetInputInstructions()
        handleSetUserRole()
        initCloseTabWarning()

        // eslint-disable-next-line
    }, [])

    const handleSetFormData = () => {
        const remoteData = getRemoteFormData()

        if (Array.isArray(remoteData)) {
            setFormData(remoteData)
        } else if (remoteData === 'empty data') {
            const generatedFormData = generateFormData()
            setFormData(generatedFormData)
        } else {
            setFormData(null)
        }
    }

    const handleSetVisitedSections = () => {
        const remoteData = getRemoteVisitedSections()

        if (remoteData) {
            setVisitedSections(remoteData)
        }
    }

    const handleSetInputWarnings = () => {
        const remoteData = getRemoteInputWarnings()

        if (remoteData) {
            setInputWarnings(remoteData)
        }
    }

    const handleSetLastDownload = () => {
        const remoteData = getRemoteLastDownload()

        if (remoteData) {
            setLastDownloaded(remoteData)
        }
    }

    const handleSetInputInstructions = () => {
        const remoteData = getRemoteHandleSetInputInstructions()

        if (remoteData) {
            setInputInstructions(remoteData)
        }
    }

    const handleSetUserRole = () => {
        const remoteData = getRemoteHandleSetUserRole()

        if (remoteData) {
            setUserRole(remoteData)
        }
    }

    const initCloseTabWarning = () => {
        window.addEventListener('beforeunload', function (e) {
            e.preventDefault();
            e.returnValue = '';
        });
    }


    if (!formData) {
        return null
    }

    return (
        <div className="Main">
            <div className="container">
                <Sidebar />
                <FormMain />
            </div>
        </div>
    )
}
