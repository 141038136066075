import { useContext } from "react"
import formConfig from '../helpers/form-config.json'
import { GlobalContext } from "../context/GlobalState"
import HeaderLogo from './HeaderLogo'
import useSaveData from "../helpers/hooks/useSaveData"
import useUserRole from "../helpers/hooks/useUserRole"


export default function Header() {

    const { globalState, setMenuOpen } = useContext(GlobalContext)

    const { inputWarnings, visitedSections, currentSection, isDataSaving, isMenuOpen, savingStatus, lastUpdated, lastUpdatedTime } = globalState

    const { saveData } = useSaveData()
    const { userCanEdit } = useUserRole()


    const hasSectionWarning = (index) => {
        return inputWarnings?.length > 0 && inputWarnings.filter(i => i.includes(`sectionIndex${index}`)).length > 0
    }

    const hasAlreadyVisited = (sectionIndex, stepIndex) => {
        return visitedSections.filter(section => section === `${sectionIndex}-${stepIndex}`).length > 0
    }

    const getStepClass = (index) => {
        if (index === currentSection) {
            return 'current'
        }

        if (hasSectionWarning(index)) {
            return 'warning'
        }

        if (hasAlreadyVisited(index, 0)) {
            return 'visited'
        }

        return ''
    }


    return (
        <>
        <div className="Header">
            <div className="container">
                <div className="logo">
                    <HeaderLogo />
                </div>

                <div className="progress">
                    {formConfig.sections.map((section, sectionIndex) => {
                        if (section.skip_header_nav) {
                            return null
                        }
                        return (
                            <div
                                key={sectionIndex}
                                className={`progress-section ${getStepClass(sectionIndex)}`}
                            >
                                <div className="secton-index">
                                    {sectionIndex}
                                </div>
                                <div className="section-title">
                                    {section.section_title.split(' ').slice(1).join(' ')}
                                </div>
                            </div>
                        )
                    })}
                </div>


                {isDataSaving ?
                    <div className="saving-status">
                        <div className="saving-icon progress"></div>
                    </div>
                    : null}


                {!isDataSaving && savingStatus === 'successful' ?
                    <div className="saving-status">
                        <div className="saving-icon success"></div>
                    </div>
                    : null}


                {!isDataSaving && savingStatus === 'error' ?
                    <div className="saving-status">
                        <div className="saving-icon error"></div>
                    </div>
                    : null}

                <div
                    className={`btn-main save-data ${isDataSaving ? 'save-data-loading' : ''} ${!userCanEdit() ? 'disabled' : ''}`}
                    onClick={() => saveData()}
                >
                    {isDataSaving ? '' : 'Opslaan'}
                </div>

                <div
                    className={`menu-toggle ${isMenuOpen ? 'active' : ''}`}
                    onClick={() => setMenuOpen(!isMenuOpen)}
                >
                    {isMenuOpen ? 'Sluiten' : 'Menu'}
                </div>
            </div>
        </div>

        <div className="mobile-only status">
            {isDataSaving ?
                    <div className="saving-status">
                        <div className="error-message progress">
                            <div className="status">
                                <p className="title">Gegevens opslaan</p>
                                <div className="saving-icon progress"></div>
                            </div>
                            <p className="message">De gegevens worden bijgewerkt.</p>
                        </div>
                    </div>
                   
                : null}


                {!isDataSaving && savingStatus === 'successful' ?
                    <div className="saving-status">
                        <div className="error-message success">
                            <div className="status">
                                <p className="title">Gegevens opgeslagen </p>
                                <div className="saving-icon success"></div>
                            </div>
                            <p className="message">Laatst bijgewerkt op: { lastUpdated } om { lastUpdatedTime }.</p>
                        </div>
                    </div>
                : null}


                {!isDataSaving && savingStatus === 'error' ?
                    <div className="saving-status">
                        <div className="error-message error">
                            <div className="status">
                                <p className="title">Fout bij het opslaan</p>
                                <div className="saving-icon error"></div>
                            </div>
                            <p className="message">Het (automatisch) opslaan van het jaarplan is mislukt. Stop met invullen om dataverlies te voorkomen. Neem contact op met Sabine Voors.</p>
                        </div>
                    </div>
                : null}
        </div>
        </>
    )
}
