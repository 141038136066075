import { Text, View } from '@react-pdf/renderer'
import parsePrice from 'parse-price'
import { formateEuroString, getTotalIncome } from '../../../../helpers/functions'

export default function FinancialIncome({ formData }) {

    const getIncomeData = () => {
        return formData.filter(field => field.field_key === '4-1-inkomsten')?.[0]?.field_value
    }

    return (
        <View
            wrap={false}
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '25px'
            }}
        >

            <Text
                style={{
                    fontSize: '13px',
                    lineHeight: '1.4',
                    color: '#0b8bcc',
                    fontFamily: 'ArchivoBold'
                }}
                hyphenationCallback={e => [e]}
            >
                4.1 Inkomsten
            </Text>

            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '5px',
                    backgroundColor: '#e4f5fd',
                    borderRadius: '3px',
                    marginTop: '5px'
                }}
            >
                <Text
                    style={{
                        width: '83%',
                        fontSize: '11px',
                        color: '#000',
                        fontFamily: 'ArchivoBold',
                    }}
                    hyphenationCallback={e => [e]}
                >
                    Inkomsten
                </Text>

                <Text
                    style={{
                        width: '17%',
                        fontSize: '11px',
                        color: '#000',
                        fontFamily: 'ArchivoBold',
                    }}
                    hyphenationCallback={e => [e]}
                >
                    Bedrag
                </Text>
            </View>


            {getIncomeData().map((incomeRow, incomeRowIndex) => {
                return (
                    <View
                        key={incomeRowIndex}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '5px',
                            borderBottom: incomeRowIndex !== getIncomeData().length - 1 ? '1px solid #e6e7e8' : ''
                        }}>

                        <Text
                            style={{
                                width: '83%',
                                fontSize: '11px',
                                color: '#000',
                                fontFamily: 'Archivo',
                            }}
                            hyphenationCallback={e => [e]}
                        >
                            {incomeRow[0]}
                        </Text>

                        <Text
                            style={{
                                width: '17%',
                                fontSize: '11px',
                                color: '#000',
                                fontFamily: 'Archivo',
                            }}
                            hyphenationCallback={e => [e]}
                        >
                            {formateEuroString(parsePrice(incomeRow[1]))}
                        </Text>

                    </View>
                )
            })}

            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '5px',
                    backgroundColor: '#e4f5fd',
                    borderRadius: '3px',
                }}
            >
                <Text
                    style={{
                        width: '83%',
                        fontSize: '11px',
                        color: '#000',
                        fontFamily: 'ArchivoBold',
                    }}
                    hyphenationCallback={e => [e]}
                >
                    Totaal inkomen
                </Text>

                <Text
                    style={{
                        width: '17%',
                        fontSize: '11px',
                        color: '#000',
                        fontFamily: 'ArchivoBold',
                    }}
                    hyphenationCallback={e => [e]}
                >
                    {getTotalIncome(formData)}
                </Text>
            </View>

        </View>
    )
}
