import AutocompleteInput from '../../ExternalComponents/AutocompleteInput'
import { useContext, useState, useEffect } from "react"
import { GlobalContext } from "../../../context/GlobalState"
import { hasFieldWarning, constructInputWarning, formateEuroString, escapeStr } from '../../../helpers/functions'
import { checkInputsValidation } from '../../../helpers/form-validator'
import parsePrice from 'parse-price'
import ClickAwayListener from '@mui/base/ClickAwayListener';
import useSubtitleData from '../../../helpers/hooks/useSubtitleData'
import useUserRole from '../../../helpers/hooks/useUserRole'


export default function FieldRepeaterMultipleItems({ field }) {

    const {
        field_title,
        inputs,
        title_blue,
        rewrite_inputs,
        skip_labels,
        allow_empty,
        additional_fields,
        field_heading,
        field_subheading,
        hide_row_index,
        disable_add_remove,
        disabled_inputs,
        total_sum_label,
        field_key
    } = field

    const { globalState, setFormData, setInputWarnings } = useContext(GlobalContext)
    const { formData, inputWarnings, currentSection, currentStep, currentProjectID } = globalState

    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [positionToRemove, setPositionToRemove] = useState(null)
    const [removeAll, setRemoveAll] = useState(false)
    const [zeroCosts, setZeroCosts] = useState(false)

    const { getSubtitleData } = useSubtitleData()
    const { userCanEdit } = useUserRole()

    const currentFieldIndex = formData.map(i => i.field_key).indexOf(`${field_key}${currentProjectID ? '-' + currentProjectID : ''}`)

    useEffect(() => {
        setZeroCosts(isZeroCostsSelected())
        // eslint-disable-next-line
    }, [])


    const isZeroCostsSelected = () => {

        if (!(additional_fields && additional_fields.includes('zero_costs_toggle'))) {
            return false
        }

        let inputs = formData?.[currentFieldIndex]?.['field_value']

        if (Array.isArray(inputs) && inputs.length > 0) {
            if (inputs[0]?.[0] === 'Geen kosten' && inputs[0]?.[1] === '€0,00') {
                return true
            }
        }

        return false
    }

    const getInputList = () => {
        let inputs = formData?.[currentFieldIndex]?.['field_value']

        if (Array.isArray(inputs)) {
            return inputs
        }

        return null
    }


    const getInputLabel = (row, index) => {

        if (rewrite_inputs) {

            const rewtireRow = rewrite_inputs?.map(i => i.position).indexOf(row)

            if (rewtireRow !== 'undefined' && rewtireRow >= 0) {

                const rewrite = rewrite_inputs?.[rewtireRow]?.inputs?.[index]

                if (rewrite) {
                    return rewrite['input_label']
                }
            }
        }


        const input = inputs?.[index]

        if (input?.['input_label']) {
            return input['input_label']
        }

        return null
    }


    const getInputPlaceholder = (row, index) => {

        const rewtireRow = rewrite_inputs?.map(i => i.position).indexOf(row)

        if (rewtireRow !== 'undefined' && rewtireRow >= 0) {

            const rewrite = rewrite_inputs?.[rewtireRow]?.inputs?.[index]

            if (rewrite) {
                return rewrite['input_placeholder']
            }
        }


        const input = inputs?.[index]

        if (input?.['input_placeholder']) {
            return input['input_placeholder']
        }

        return null
    }


    const getInputType = (index) => {
        const input = inputs?.[index]

        if (input?.['input_type']) {
            return input['input_type']
        }

        return null
    }

    const getInputConfig = (index) => {
        return inputs?.[index]
    }


    const getInputValue = (row, index) => {
        let value = formData?.[currentFieldIndex]?.['field_value']?.[row]?.[index]

        if (value) {

            if (
                (getInputLabel(row, index) === 'Kosten' || getInputLabel(row, index) === 'Bedrag') &&
                value !== '' &&
                !value.startsWith('€')
            ) {
                return `€${value}`
            }

            return value
        }

        return ''
    }

    const getSelectOptions = (index) => {
        const input = inputs?.[index]

        if (input?.['options']) {
            return input['options']
        }

        return null
    }

    const checkIfDisabled = (row, cell) => {
        return disabled_inputs?.filter(input => input.row === row && input.cell === cell).length > 0
    }



    const setInputValue = (row, index, value) => {
        const currentFormData = formData
        let currentRowValues = [...currentFormData?.[currentFieldIndex]?.['field_value']?.[row]]

        if (currentRowValues?.[index] !== undefined) {
            currentRowValues[index] = value
            currentFormData[currentFieldIndex]['field_value'][row] = currentRowValues
        }

        return setFormData(currentFormData)
    }


    const addNewRow = () => {
        const currentFormData = formData
        let currentValues = [...currentFormData?.[currentFieldIndex]?.['field_value']]

        if (Array.isArray(currentValues)) {
            currentValues.push(inputs.map(input => {

                if (input.input_type === 'radio' && input.choices?.length > 0) {
                    return input.choices[0]
                }

                return ''
            }))
            currentFormData[currentFieldIndex]['field_value'] = currentValues
        }

        return setFormData(currentFormData)
    }


    const removeRow = () => {
        const currentFormData = formData
        let currentValues = [...currentFormData?.[currentFieldIndex]?.['field_value']]
        let currentInputWarnings = inputWarnings

        if (Array.isArray(currentValues)) {

            [...Array(currentValues[positionToRemove].length)].forEach((_, cellIndex) => {
                const inputWarningKey = constructInputWarning(currentSection, currentStep, field_key, `${positionToRemove}-${cellIndex}`)
                currentInputWarnings = currentInputWarnings.filter(warning => warning !== inputWarningKey)
            })

            currentValues.splice(positionToRemove, 1)
            currentFormData[currentFieldIndex]['field_value'] = currentValues
        }

        setFormData(currentFormData)
        // setInputWarnings(currentInputWarnings)
    }


    const removeOldWarnings = () => {
        const currentFormData = formData
        let currentValues = [...currentFormData?.[currentFieldIndex]?.['field_value']]
        let currentInputWarnings = inputWarnings

        const currentFieldWarnings = currentInputWarnings.filter(warning => warning.includes(field_key))

        currentFieldWarnings.forEach(warning => {
            const warningRowIndex = warning.split(':')[warning.split(':').length - 1]?.split('-')?.[0]
            if (+warningRowIndex + 1 > currentValues.length) {
                currentInputWarnings = currentInputWarnings.filter(warningItem => warningItem !== warning)
            }
        })

        setInputWarnings(currentInputWarnings)
    }


    const checkIfValuePresent = () => {
        const currentFormData = formData
        let currentValues = [...currentFormData?.[currentFieldIndex]?.['field_value']]

        return Array.isArray(currentValues) && currentValues.length > 0
    }

    const clearCurrentValues = () => {
        const currentFormData = formData
        let currentValues = [...currentFormData?.[currentFieldIndex]?.['field_value']]

        if (Array.isArray(currentValues) && currentValues.length > 0) {
            currentValues = []
            currentFormData[currentFieldIndex]['field_value'] = currentValues
        }

        return setFormData(currentFormData)
    }


    const hasInputWarning = (row, cell) => {

        if (currentProjectID) {
            return inputWarnings.filter(i => i.includes(`${field_key}:${row}-${cell}`) && i.includes(`${currentProjectID}`)).length > 0
        }

        return inputWarnings.filter(i => i.includes(`${field_key}:${row}-${cell}`)).length > 0
    }

    const calculateTotalCost = () => {
        const currentFormData = formData
        let totalCost = 0

        let currentValues = [...currentFormData?.[currentFieldIndex]?.['field_value']]

        currentValues?.forEach(row => {

            const rowCostValue = row[1]

            if (rowCostValue) {
                const rowCostNum = parsePrice(rowCostValue)

                if (rowCostNum) {
                    totalCost += rowCostNum
                }
            }
        })

        return formateEuroString(totalCost)
    }


    const calculateTotalHours = () => {
        const currentFormData = formData
        let totalHours = 0

        let currentValues = [...currentFormData?.[currentFieldIndex]?.['field_value']]

        currentValues?.forEach(row => {
            const rowHoursValue = row[1]

            if (rowHoursValue) {
                const rowHoursNum = parsePrice(rowHoursValue)

                if (rowHoursNum) {
                    totalHours += rowHoursNum
                }
            }
        })

        return `${totalHours} uur`

    }

    const handleZeroClick = () => {
        const currentFormData = formData
        let currentInputWarnings = [...inputWarnings]
        let currentValues = [...currentFormData?.[currentFieldIndex]?.['field_value']]

        const inputWarningKeyFirst = constructInputWarning(currentSection, currentStep, field_key, `${0}-${0}`, `projectID-${currentProjectID}`)
        const inputWarningKeySecond = constructInputWarning(currentSection, currentStep, field_key, `${0}-${1}`, `projectID-${currentProjectID}`)

        if (!zeroCosts) {
            currentValues = currentValues.slice(0, 1)
            currentValues = currentValues.map(i => {
                i[0] = 'Geen kosten'
                i[1] = '€0,00'
                return i
            })

            currentInputWarnings = currentInputWarnings.filter(warning => !(warning === inputWarningKeyFirst || warning === inputWarningKeySecond))

        } else {
            currentValues = currentValues.map(i => i.fill(''))
            // currentInputWarnings.push(inputWarningKeyFirst, inputWarningKeySecond)
        }

        currentFormData[currentFieldIndex]['field_value'] = currentValues
        setInputWarnings(currentInputWarnings)
        setFormData(currentFormData)


    }


    return (
        <div className='FieldRepeaterMultipleItems form-field'>

            {field_heading ?
                <div className="field-heading">{field_heading}</div>
                : null}

            {field_subheading ?
                <div className="field-subheading">{field_subheading}</div>
                : null}

            {field_title ?
                <div className={`field-title ${title_blue ? 'title-blue' : ''}`}>
                    {field_title}

                    {allow_empty && userCanEdit() ?
                        <div
                            className={`field-toggle ${checkIfValuePresent() ? 'open' : ''}`}
                            onClick={() => {
                                if (checkIfValuePresent()) {
                                    setRemoveAll(true)
                                    setIsPopupOpen(true)
                                    document.body.classList.remove("no-scroll")
                                } else {
                                    addNewRow()
                                }
                            }}
                        ></div>
                        : null}
                </div>
                : null}

            {getSubtitleData(field_key) && checkIfValuePresent() ?
                <div className="field-subtitle">{getSubtitleData(field_key)}</div>
                : null}


            {hasFieldWarning(inputWarnings, field_key) ?

                <div className="field-warning">
                    Vul a.u.b alle verplichte velden in
                </div>

                : null}


            <div className="form-inputs">

                {(() => {
                    const inputsList = getInputList()

                    return (
                        inputsList && inputsList.length > 0 ?

                            inputsList?.map((inputRow, i) => {
                                return (
                                    <div key={i} className="inputs-row">

                                        {!hide_row_index ?
                                            <div className="row-number">{i + 1}</div>
                                            : null}

                                        {inputRow?.length > 0 ?

                                            inputRow.map((_, j) => {

                                                const input_type = getInputType(j)

                                                if (input_type === 'text' || input_type === 'number' || input_type === 'price') {

                                                    const input_label = getInputLabel(i, j)
                                                    const input_placeholder = getInputPlaceholder(i, j)
                                                    const input_value = getInputValue(i, j)
                                                    const inputWarningKey = constructInputWarning(currentSection, currentStep, field_key, `${i}-${j}`, currentProjectID ? `projectID-${currentProjectID}` : '')

                                                    return (
                                                        <TextInputType
                                                            key={`${i}-${j}`}
                                                            skipLabels={skip_labels}
                                                            row={i}
                                                            index={j}
                                                            disabled={checkIfDisabled(i, j) || zeroCosts || !userCanEdit()}
                                                            inputLabel={input_label}
                                                            inputPlaceholder={input_placeholder}
                                                            inputValue={input_value}
                                                            setInputValue={value => {

                                                                if (input_type === 'number') {
                                                                    value = parseFloat(value.replace(/\D/g, ''));
                                                                    value = isNaN(value) ? '' : `${value}`;
                                                                }

                                                                if (input_type === 'price') {
                                                                    value = value.split('').filter(char => {
                                                                        return (
                                                                            char === '€' ||
                                                                            char === ',' ||
                                                                            char === '.' ||
                                                                            !isNaN(parseInt(char))
                                                                        )
                                                                    }).join('')
                                                                }

                                                                setInputValue(i, j, value)
                                                                checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings, inputWarningKey)
                                                            }}
                                                            hasInputWarning={hasInputWarning(i, j)}
                                                        />
                                                    )
                                                }

                                                if (input_type === 'autocomplete') {

                                                    const input_label = getInputLabel(i, j)
                                                    const input_placeholder = getInputPlaceholder(i, j)
                                                    const input_config = getInputConfig(j)
                                                    const input_value = getInputValue(i, j)
                                                    const inputWarningKey = constructInputWarning(currentSection, currentStep, field_key, `${i}-${j}`)

                                                    return (
                                                        <AutocompleteInputType
                                                            key={`${i}-${j}`}
                                                            skipLabels={skip_labels}
                                                            row={i}
                                                            index={j}
                                                            inputLabel={input_label}
                                                            inputPlaceholder={input_placeholder}
                                                            inputValue={input_value}
                                                            inputSuggestions={input_config?.input_suggestions}
                                                            setInputValue={value => {
                                                                setInputValue(i, j, value)
                                                                checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings, inputWarningKey)
                                                            }}
                                                            hasInputWarning={hasInputWarning(i, j)}
                                                            disabled={!userCanEdit()}
                                                        />
                                                    )
                                                }


                                                if (input_type === 'radio') {

                                                    const input_value = getInputValue(i, j)
                                                    const input_config = getInputConfig(j)

                                                    return (
                                                        <RadioInputType
                                                            key={`${i}-${j}`}
                                                            skipLabels={skip_labels}
                                                            row={i}
                                                            index={j}
                                                            inputValue={input_value}
                                                            radioChoices={input_config?.choices}
                                                            setInputValue={value => setInputValue(i, j, value)}
                                                            hasInputWarning={hasInputWarning(i, j)}
                                                            disabled={!userCanEdit()}
                                                        />
                                                    )
                                                }

                                                if (input_type === 'select') {

                                                    const input_label = getInputLabel(i, j)
                                                    const input_placeholder = getInputPlaceholder(i, j)
                                                    const input_value = getInputValue(i, j)
                                                    const options = getSelectOptions(j)
                                                    const inputWarningKey = constructInputWarning(currentSection, currentStep, field_key, `${i}-${j}`, currentProjectID ? `projectID-${currentProjectID}` : '')

                                                    return (
                                                        <SelectInputType
                                                            key={`${i}-${j}`}
                                                            skipLabels={skip_labels}
                                                            row={i}
                                                            index={j}
                                                            inputLabel={input_label}
                                                            inputPlaceholder={input_placeholder}
                                                            inputValue={input_value}
                                                            setInputValue={value => {
                                                                setInputValue(i, j, value)
                                                                checkInputsValidation(currentSection, currentStep, globalState, setInputWarnings, inputWarningKey)
                                                            }}
                                                            hasInputWarning={hasInputWarning(i, j)}
                                                            options={options}
                                                            disabled={!userCanEdit()}
                                                        />
                                                    )
                                                }

                                                return null


                                            })

                                            : null}

                                        {!disable_add_remove && userCanEdit() ?
                                            <div
                                                className={`remove-row ${i === 0 ? 'hidden' : ''}`}
                                                onClick={() => {
                                                    if (i > 0) {
                                                        setIsPopupOpen(true)
                                                        setPositionToRemove(i)
                                                        document.body.classList.remove("no-scroll")
                                                    }
                                                }}
                                            ></div>
                                            : null}
                                    </div>
                                )

                            })
                            : null
                    )

                })()}
            </div>

            {additional_fields && additional_fields.includes('zero_costs_toggle') ?
                <div
                    className='form-zero-costs-toggle input-item'
                    onClick={() => {
                        if (!userCanEdit()) {
                            return
                        }
                        setZeroCosts(!zeroCosts)
                        handleZeroClick()
                    }}
                >
                    <div
                        className={`input-checkbox ${zeroCosts ? 'checked' : ''}`}
                    ></div>
                    Dit project wordt betaald vanuit een additionele subsidie
                </div>
                : null}


            {checkIfValuePresent() && !disable_add_remove && !zeroCosts && userCanEdit() ?
                <div
                    className="btn-main blue plus add-row"
                    onClick={() => addNewRow()}
                >Rij toevoegen</div>
                : null}


            {additional_fields?.map((additional_field, i) => {
                switch (additional_field) {
                    case 'total_sum':
                        return (
                            <div key={`${additional_field}-${i}`} className="form-text-row">
                                <div className="row-label">
                                    {total_sum_label}
                                </div>
                                <div className="row-value">
                                    {calculateTotalCost()}
                                </div>
                            </div>
                        )

                    case 'total_hours':
                        return (
                            <div key={`${additional_field}-${i}`} className="form-text-row">
                                <div className="row-label">
                                    {total_sum_label}
                                </div>
                                <div className="row-value">
                                    {calculateTotalHours()}
                                </div>
                            </div>
                        )

                    default:
                        return null;
                }
            })}



            {
                isPopupOpen ?
                    <div className="popup-main">
                        <form className="popup-form">

                            <div className="popup-title">
                                Weet je zeker dat je dit veld wilt verwijderen?
                            </div>

                            <div className="popup-subtitle">
                                Hiermee verwijder je de ingevulde informatie. Deze actie kan je niet ongedaan maken.
                            </div>


                            <div className="button-row right">
                                <button
                                    className="btn-sec popup-btn"
                                    onClick={e => {
                                        e.preventDefault()
                                        setIsPopupOpen(false)
                                        setPositionToRemove(null)
                                        setRemoveAll(false)
                                    }}
                                >Annuleren</button>
                                <button
                                    className="btn-main popup-btn"
                                    onClick={e => {
                                        e.preventDefault()

                                        if (removeAll) {
                                            clearCurrentValues()
                                        } else {
                                            removeRow()
                                        }

                                        setPositionToRemove(null)
                                        setRemoveAll(false)
                                        setIsPopupOpen(false)
                                        removeOldWarnings()
                                    }}
                                >Verwijderen</button>
                            </div>

                        </form>
                    </div>
                    : null
            }

        </div>
    )
}


function TextInputType({ skipLabels, inputLabel, inputPlaceholder, row, setInputValue, inputValue, hasInputWarning, disabled = false }) {

    return (
        <div className="input-item spread">

            {skipLabels && row > 0 ? null :
                <div className="input-label">
                    <div className="label-text">{inputLabel}</div>
                </div>
            }

            <input
                type="text"
                className={`field-input ${hasInputWarning ? 'warning' : ''}`}
                placeholder={inputPlaceholder}
                value={inputValue ? inputValue : ''}
                onChange={e => {
                    setInputValue(escapeStr(e.target.value))
                }}
                disabled={disabled}
            />
        </div>
    )
}

function AutocompleteInputType({ skipLabels, inputLabel, inputPlaceholder, row, setInputValue, inputValue, inputSuggestions, hasInputWarning, disabled }) {
    return (
        <div className="input-item spread">

            {skipLabels && row > 0 ? null :
                <div className="input-label">
                    <div className="label-text">{inputLabel}</div>
                </div>
            }

            <AutocompleteInput
                className={`field-input ${hasInputWarning ? 'warning' : ''}`}
                placeholder={inputPlaceholder}
                setInputValue={(value) => {
                    setInputValue(value)
                }}
                value={inputValue ? inputValue : ''}
                suggestions={inputSuggestions}
                skipLabels={skipLabels && row > 0}
                disabled={disabled}
            />

        </div>
    )
}

function RadioInputType({ setInputValue, inputValue, radioChoices, disabled }) {
    return (
        <div className="input-item radio">

            <div
                className='radio-input-list'
            >

                {radioChoices?.length > 0 && radioChoices.map((choice, i) => {
                    return (
                        <div key={i} className="radio-row">
                            <input
                                className='radio-input'
                                type="radio"
                                checked={choice === inputValue}
                                value={choice}
                                onChange={e => {
                                    setInputValue(escapeStr(e.target.value))
                                }}
                                disabled={disabled}
                            />
                            {choice}
                        </div>
                    )
                })}

            </div>
        </div>
    )
}


function SelectInputType({ skipLabels, inputLabel, inputPlaceholder, row, setInputValue, inputValue, hasInputWarning, options, disabled }) {

    const [showOptions, setShowOptions] = useState(false)
    const [optionsOpacity, setOptionsOpacity] = useState(true)


    return (
        <div className="input-item spread">

            {skipLabels && row > 0 ? null :
                <div className="input-label">
                    <div className="label-text">{inputLabel}</div>
                </div>
            }

            <ClickAwayListener
                onClickAway={() => {
                    setOptionsOpacity(true)
                    setTimeout(() => {
                        setShowOptions(false)
                    }, 300)
                }}
            >
                <div className="input-item">
                    <input
                        type="text"
                        className={`field-input multiselect ${hasInputWarning ? 'warning' : ''}`}
                        placeholder={inputPlaceholder}
                        value={inputValue ? inputValue : ''}
                        onClick={() => {
                            if (disabled) {
                                return
                            }
                            setShowOptions(true)
                            setOptionsOpacity(false)
                        }}
                        onChange={() => ''}
                        disabled={disabled}
                    />

                    {showOptions && options?.length > 0 ?
                        <ul
                            className="suggestions no-labels multiselect"
                            style={{ "opacity": optionsOpacity ? '0' : '1' }}
                        >
                            {options.map(option => {
                                return (
                                    <li
                                        className={`${option === inputValue ? 'selected' : ''}`}
                                        key={option}
                                        onClick={e => {
                                            setInputValue(e.currentTarget.innerText)
                                            setOptionsOpacity(true)
                                            setTimeout(() => {
                                                setShowOptions(false)
                                            }, 300)
                                        }}
                                    >
                                        {option}
                                    </li>
                                );
                            })}
                        </ul>
                        : null}
                </div>
            </ClickAwayListener>
        </div>
    )
}