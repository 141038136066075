import { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';

const useUserRole = () => {
    const { globalState } = useContext(GlobalContext)
    const { userRole } = globalState

    const userCanEdit = () => {
        return userRole && Array.isArray(userRole) && (userRole.includes('aanvraag_schrijver') || userRole.includes('administrator'))
    };

    return { userCanEdit };
};

export default useUserRole;